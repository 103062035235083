.section--request
  position: relative
    
  overflow: hidden

  background-color: rgba(243, 243, 243, .9)

  @include screen('sm')
    padding: 70px 0

  .request
    display: flex
    flex-direction: column

    > *
      z-index: 2

    img
      position: absolute

      bottom: 0
      left: 50%

      z-index: 1

      transform: translateX(-45%)

      @include screen('xl')
        right: 0
        left: auto

        width: 300px

      @include screen('md')
        right: -72px

        width: 260px

      @include screen('sm')
        z-index: 3

        width: 200px

        right: -160px

    &__content
      display: flex
      flex-direction: row
      justify-content: space-between

      @include screen('md')
        flex-direction: column

        margin-bottom: 30px

      .request__info
        display: flex
        flex-direction: column

        margin-right: 20px
        margin-bottom: 35px

        min-width: max-content

        @include screen('md')
          margin-bottom: 30px

        .request__title
          display: flex
          flex-direction: column

          font-size: 60px
          font-weight: 500
          line-height: 75px
          text-transform: uppercase

          margin-bottom: 25px

          color: map-get($COLORS, 'secondary')

          @include screen('lg')
            font-size: 50px
            line-height: 56px
          
          @include screen('md')
            font-size: 40px
            line-height: 45px

          @include screen('sm')
            font-size: 35px
            line-height: 40px

          span
            font-weight: 600

            color: map-get($COLORS, 'primary-dark')

        .request__description
          font-size: 12px
          font-weight: 400
          line-height: 19px

          max-width: 320px

          margin-left: 50px

          color: map-get($COLORS, 'black')

          @include screen('lg')
            margin-left: 0

          @include screen('md')
            max-width: 500px

      .request__advantages
        display: flex
        flex-direction: column

        max-width: 360px

        margin-top: 40px

        @include screen('md')
          margin-top: 0

          max-width: 500px

        .advantage
          position: relative

          display: flex
          flex-direction: column

          &:not(:last-child)
            margin-bottom: 30px

            @include screen('md')
              margin-bottom: 25px

          &::before
            content: '+'

            font-size: 24px
            font-weight: 600

            margin-bottom: 20px

            color: map-get($COLORS, 'primary')

          p
            font-size: 12px
            font-weight: 400
            line-height: 19px

            color: map-get($COLORS, 'black')

    &__form
      form
        display: flex
        flex-direction: column

        max-width: 300px

        .input
          &:not(:last-of-type)
            margin-bottom: 10px

        .button
          margin-top: 25px
          margin-bottom: 20px

        .agreement
          display: flex
          flex-direction: row
          align-items: center

          margin: 0 auto

          opacity: .75

          .checkbox
            margin-right: 10px

          label
            cursor: pointer

            max-width: 195px

            font-size: 10px
            font-weight: 400
            line-height: 12px

            color: map-get($COLORS, 'secondary')

            a
              text-decoration: underline
