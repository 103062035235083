*,
*::before,
*::after
  box-sizing: border-box

  min-width: 0
  min-height: 0

body
  margin: 0
  padding: 0

  font-size: 14px
  font-weight: 400
  line-height: 21px
  font-style: normal
  font-family: map-get($FONTS, 'montserrat'), sans-serif

  color: map-get($COLORS, 'black')
  background-color: map-get($COLORS, 'white')

  &.disabled
    overflow: hidden !important

.icons
  display: none

.section
  padding: 75px 0

  @include screen('lg')
    padding: 50px 0

  @include screen('sm')
    padding: 30px 0

  .section__heading
    display: flex
    flex-direction: row
    align-items: flex-end

    margin-bottom: 45px

    color: map-get($COLORS, 'secondary')

    @include screen('md')
      > *:not(.section__title)
        display: none

    @include screen('sm')
      margin-bottom: 30px

    .section__title
      font-size: 45px
      font-weight: 500
      line-height: 55px

      @include screen('sm')
        font-size: 40px
        line-height: 48px

      > a
        text-decoration: underline

        color: map-get($COLORS, 'primary')

    .section__heading-hint
      font-size: 16px
      font-weight: 400
      line-height: 20px

      margin-left: 30px
      margin-bottom: 8px

      &--primary
        color: map-get($COLORS, 'primary')

.slider__items
  display: flex
  
  cursor: grab

.icon
  display: inline

  width: 25px
  height: 25px