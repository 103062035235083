.button
  position: relative

  display: flex
  align-items: center
  justify-content: center

  font-size: 10px
  font-weight: 600
  line-height: 12px
  letter-spacing: 0.75px

  padding: 0

  cursor: pointer

  height: 60px
  max-width: 300px

  border: none
  background: none

  .icon
    width: 22px
    height: 22px

    @include screen('sm')
      width: 12px
      height: 12px

    &--before
      margin-right: 15px

    &--after
      margin-left: 15px

    &--arrow-right,
    &--arrow-left
      width: 8px

    &--arrow-alt-right,
    &--arrow-alt-left
      width: 40px
      height: 40px

  &--uppercase
    text-transform: uppercase

  &--transform
    margin-right: 7px
    margin-bottom: 7px

    &::before
      position: absolute

      content: ''

      width: 100%
      height: 100%

      right: -7px
      bottom: -7px

      border-width: 1px
      border-style: solid
      border-color: map-get($COLORS, 'primary')
      
    &:hover
      .button__inner
        transform: translate(7px, 7px)

    &:active
      .button__inner
        transform: translate(3px, 3px)

  &--primary
    .button__inner
      color: map-get($COLORS, 'white')
      background-color: map-get($COLORS, 'primary')

    &:hover
      .button__inner
        background-color: map-get($COLORS, 'primary-dark')

    &:active
      .button__inner
        background-color: map-get($COLORS, 'primary')

  &--gray
    height: 50px
    
    font-size: 16px
    font-weight: 500
    line-height: 20px

    &::before
      border-color: #FAFAFA

    .button__inner
      background-color: #FAFAFA
      color: map-get($COLORS, 'secondary')

    &:hover
      .button__inner
        color: map-get($COLORS, 'white')
        background-color: map-get($COLORS, 'primary-dark')

    &:active
      .button__inner
        color: map-get($COLORS, 'white')
        background-color: map-get($COLORS, 'primary')

  &--disabled
    &::before
      border-color: #F3F3F3 

    .button__inner
      background-color: #F3F3F3
      color: rgba(map-get($COLORS, 'secondary'), .5)

      &:hover
        color: auto
        background-color: #F3F3F3
  
      &:active
        color: auto
        background-color: #F3F3F3

  &--outline
    font-weight: 500

    border-width: 1px
    border-style: solid
    border-color: #959494

    &:hover
      .button__inner
        background-color: #959494 
        color: map-get($COLORS, 'white')

    &:active
      .button__inner
        background-color: darken(#959494, 10%) 

    .button__inner
      color: #959494
      background-color: transparent

  &--space-between
    .button__inner
      justify-content: space-between

  &__inner
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center

    min-width: 100%
    min-height: 100%

    padding: 0 25px
    
    transition-duration: .3s
    transition-property: color, transform, background-color