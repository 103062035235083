.sort
  display: flex
  align-items: center
  flex-direction: row

  font-size: 12px
  font-weight: 500
  line-height: 14px
  white-space: nowrap

  width: 100%

  overflow-x: auto

  color: map-get($COLORS, 'secondary')

  > span
    margin-right: 10px

    min-width: max-content

  &__variants
    display: flex
    flex-direction: row
    align-items: center

    .sort__variant
      opacity: .5

      cursor: pointer

      min-width: max-content

      transition-duration: .2s
      transition-property: color, opacity

      &:not(:last-child)
        margin-right: 15px

      &:hover
        opacity: 1

      &.active
        opacity: 1

        color: map-get($COLORS, 'primary')