.modal--signin
  display: flex
  align-items: center
  flex-direction: column

  width: 100%
  max-width: 470px

  @include screen('sm')
    max-width: 300px

  form
    width: 100%

  .input
    width: 100%

    &:not(:last-of-type)
      margin-bottom: 15px
  
  .button
    width: 100%
    max-width: 100%

    margin-top: 25px
    margin-bottom: 20px

    @include screen('sm')
      font-size: 8px

  .hint
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between

    font-size: 10px
    font-weight: 400
    line-height: 12px

    @include screen('sm')
      flex-direction: column
      align-items: flex-start

    a
      cursor: pointer

    > span 
      @include screen('sm')
        margin-bottom: 10px

      a
        font-weight: 500
        
        color: map-get($COLORS, 'primary')