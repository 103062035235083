.modals
  display: none

  &.show
    display: block
  
  &.active
    .modals__inner
      opacity: 1
  
  &__inner
    position: fixed
    
    z-index: 9988
    
    top: 0
    left: 0
    right: 0
    bottom: 0
    
    opacity: 0

    padding: 0 15px

    transition-duration: .3s
    transition-property: opacity
    transition-timing-function: ease-in

    background-color: rgba(map-get($COLORS, 'black'), .6)

  .modal
    display: flex
    align-items: center
    flex-direction: column

    position: absolute

    top: 50%
    left: 50%

    z-index: 9990

    transform: translate(-50%, -50%)

    opacity: 0
    
    padding: 50px
    
    border-radius: 4px
    
    transition-duration: .3s
    transition-property: opacity
    transition-timing-function: ease-in
    
    color: map-get($COLORS, 'secondary')
    background-color: map-get($COLORS, 'white')
    box-shadow: 0px 25px 45px rgba(map-get($COLORS, 'black'), .1)

    @include screen('md')
      padding: 40px

    @include screen('sm')
      padding: 30px

    &.active
      opacity: 1

      z-index: 9999

    &__close
      position: absolute

      top: 20px
      right: 20px

      opacity: .5

      cursor: pointer

      color: map-get($COLORS, 'secondary')

      transition-duration: .2s
      transition-property: opacity

      .icon
        width: 12px
        height: 12px

      &:hover
        opacity: 1

    &__title
      font-size: 30px
      font-weight: 500
      line-height: 36px
      text-align: center

      margin-bottom: 40px

      @include screen('md')
        font-size: 25px

        margin-bottom: 30px

      @include screen('sm')
        margin-bottom: 20px