.section--grid
  .grid
    display: grid
    grid-gap: 30px
    grid-template-columns: 1fr 1fr 1fr

    @include screen('lg')
      grid-template-columns: 1fr

  .slick-track
    height: 100%

  .slick-slide
    margin: 0 15px

  .slick-list
    margin: 0 -15px

  .slider__arrows
    display: none
    align-items: center
    flex-direction: row
    justify-content: center

    margin-top: 20px

    @include screen('lg')
      display: flex

    .slider__arrow
      display: flex
      align-items: center
      justify-content: center

      width: 60px
      height: 60px

      cursor: pointer

      margin: 0 50px

      transition-duration: .2s
      transition-property: color

      @include screen('lg')
        width: 50px
        height: 50px

        margin: 0 30px

      @include screen('md')
        width: 40px
        height: 40px

        margin: 0 20px

      @include screen('sm')
        width: 30px
        height: 30px

        margin: 0 10px

      &[aria-disabled="false"]
        color: map-get($COLORS, 'primary-dark')

      .icon
        width: 33px
        height: 60px

        @include screen('lg')
          width: 40px
          height: 30px

        @include screen('md')
          width: 30px
          height: 20px

        @include screen('sm')
          width: 25px
          height: 15px

  &.section--categories
    .grid
      display: grid
      grid-gap: 30px
      grid-template-rows: 255px 255px
      grid-template-columns: 1fr 1fr 1fr
      grid-template-areas: "a b c" "a b d"

      @include screen('lg')
        display: flex
        flex-direction: row

        height: 540px

      @include screen('md')
        height: 400px

      .category
        @include screen('lg')
          grid-area: auto !important

        &:nth-child(1)
          grid-area: a

        &:nth-child(2)
          grid-area: b

        &:nth-child(3)
          grid-area: c

        &:nth-child(4)
          grid-area: d

  &.section--popular-products
    .grid
      @include screen('lg')
        display: flex
        flex-direction: row

  &.section--news
    .grid
      @include screen('lg')
        display: flex
        flex-direction: row