.section--article
  padding-top: 0

  .section__heading
    margin-bottom: 20px

  article
    font-size: 14px
    font-weight: 400
    line-height: 25px

    color: rgba(map-get($COLORS, 'secondary'), .7)

    b
      font-weight: 700

    ul
      padding-left: 30px

      list-style: initial

    .block
      margin: 30px 0

      &--stats
        display: flex
        align-items: center
        flex-direction: row

        @include screen('md')
          flex-direction: column
          align-items: flex-start

        .stat
          display: flex
          align-items: center
          flex-direction: row

          &:not(:last-child)
            margin-right: 50px

            @include screen('md')
              margin-right: 0
              margin-bottom: 20px

          &__icon
            display: flex
            align-items: center
            justify-content: center

            margin-right: 20px

            .icon
              width: 60px
              height: 60px

              color: map-get($COLORS, 'black')

          &__info
            display: flex
            flex-direction: column

            font-size: 14px
            font-weight: 400

            color: map-get($COLORS, 'secondary')

            .stat__count
              font-weight: 700
              
              margin-bottom: 5px

      &--requisites
        display: grid
        grid-gap: 40px
        grid-template-columns: 380px auto

        @include screen('md')
          grid-template-columns: 1fr
          grid-template-rows: 360px auto

        .requisites__image
          overflow: hidden

          img
            width: 100%
            height: 100%

            object-fit: cover
            object-position: center

        .requisites__info
          display: flex
          flex-direction: column
          justify-content: space-between

          .requisites__title
            font-size: 18px
            font-weight: 700
            line-height: 25px

            margin-bottom: 10px

      &--facts
        display: grid
        grid-gap: 40px 25px
        grid-template-columns: repeat(4, 1fr)

        @include screen('lg')
          grid-template-columns: repeat(3, 1fr)

        @include screen('md')
          grid-template-columns: repeat(2, 1fr)

        @include screen('sm')
          grid-template-columns: repeat(1, 1fr)

        .fact
          display: flex
          flex-direction: row
          align-items: center

          &__icon
            display: flex
            align-items: center
            justify-content: center

            margin-right: 10px

            .icon
              width: 60px
              height: 60px

              color: map-get($COLORS, 'black')

          &__name
            font-size: 12px
            font-weight: 400
            line-height: 18px
            
            max-width: 185px

            color: map-get($COLORS, 'black')