.modal--review
  form
    display: flex
    align-items: center
    flex-direction: column

    width: 100%
    max-width: 300px

    .rating
      display: flex
      align-items: center
      flex-direction: row

      margin-bottom: 15px

      span
        font-size: 14px
        font-weight: 400
        line-height: 17px

        margin-right: 15px

      &__gradations
        display: flex
        flex-direction: row
        align-items: center

        .star
          display: flex
          align-items: center
          justify-content: center

          opacity: .5

          &:not(:last-child)
            margin-right: 8px

          &.active
            opacity: 1

            color: map-get($COLORS, 'primary')

          .icon
            width: 12px
            height: 12px

    .input
      width: 100%

      &:not(:last-of-type)
        margin-bottom: 15px

    .button
      width: 100%
      max-width: 100%

      margin-top: 25px
      margin-bottom: 20px