.section--catalog
  margin: 0 auto

  padding: 30px 0 

  max-width: 1300px

  transform: translateY(-60px)
  
  background-color: map-get($COLORS, 'white')

  @include screen('lg')
    transform: translateY(0)

  .title
    display: none

    font-size: 32px
    font-weight: 500
    line-height: 39px

    margin-bottom: 25px

    @include screen('sm')
      display: block

  .section--breadcrumbs
    margin-bottom: 15px

    .container
      max-width: 100%

      padding-left: 0
      padding-right: 0

      .breadcrumbs
        margin-left: 60px

        @include screen('sm')
          margin-left: 0

  .catalog
    display: flex
    flex-direction: column

    &__categories
      display: flex
      flex-direction: row

      width: 100%
      height: 70px

      overflow-x: auto

      margin-bottom: 40px

      .catalog__category
        flex: 1
        display: flex
        align-items: center
        justify-content: center

        opacity: .5

        height: 100%
        
        cursor: pointer

        padding: 0 25px

        min-width: auto

        font-size: 16px
        font-weight: 500
        line-height: 20px
        white-space: nowrap

        transition-duration: .2s
        transition-property: opacity

        color: map-get($COLORS, 'secondary')

        @include screen('sm')
          font-size: 10px
          font-weight: 700
          line-height: 12px

        &:nth-child(1)
          min-width: 260px

          @include screen('sm')
            min-width: 200px

        &:hover
          opacity: 1

        &.active
          opacity: 1

          color: map-get($COLORS, 'white')
          background-color: map-get($COLORS, 'primary')

    &__inner
      display: grid
      grid-gap: 60px
      grid-template-columns: 300px 1fr

      @include screen('md')
        grid-template-columns: 1fr

      .catalog__filters
        display: flex
        flex-direction: column

        padding-right: 25px

        border-right-width: 2px
        border-right-style: solid
        border-right-color: rgba(map-get($COLORS, 'black'), .1)

        @include screen('md')
          border-right: none

          padding-right: 0

        > .button
          display: none

          @include screen('md')
            display: flex

        &-inner
          > .button
            width: 100%
            max-width: 100%

          @include screen('md')
            display: none

            &.active
              display: block

              max-width: 300px

              background-color: map-get($COLORS, 'white')
              box-shadow: 0px 15px 45px rgba(map-get($COLORS, 'black'), .1)

        &-list
          display: flex
          flex-direction: column

          padding: 30px 15px

        .filter
          display: flex
          flex-direction: column

          &:not(:first-child)
            padding-top: 30px

          &:not(:last-child)
            padding-bottom: 30px

          &:not(:last-child)
            border-bottom-width: 2px
            border-bottom-style: solid
            border-bottom-color: rgba(map-get($COLORS, 'black'), .1)

          &__heading
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between

            margin-bottom: 25px

            .filter__title
              font-size: 18px
              font-weight: 500
              line-height: 22px

              color: map-get($COLORS, 'black')

            .filter__reset
              display: flex
              align-items: center
              flex-direction: row

              opacity: .5

              cursor: pointer

              transition-duration: .2s
              transition-property: opacity, color

              color: map-get($COLORS, 'secondary')

              &:hover
                opacity: 1

                color: map-get($COLORS, 'primary')

              .icon
                width: 8px
                height: 8px

                margin-right: 8px

              span
                font-size: 12px
                font-weight: 500
                line-height: 14px

          &__inner
            .checkbox-list
              display: flex
              flex-direction: column

              .item
                display: flex
                flex-direction: row
                align-items: center

                &:not(:last-child)
                  margin-bottom: 10px

                .checkbox
                  margin-right: 10px

                  &.active ~ label
                    opacity: 1

                    color: map-get($COLORS, 'primary')

                > label
                  cursor: pointer

                  opacity: .7

                  font-size: 14px
                  font-weight: 500
                  line-height: 17px

                  color: map-get($COLORS, 'secondary')
          
          &--selected
            .selections
              display: flex
              flex-wrap: wrap
              flex-direction: row

              .selection
                cursor: pointer

                display: flex
                align-items: center
                flex-direction: row

                padding: 10px 15px

                margin-right: 12px
                margin-bottom: 12px

                background-color: #F3F3F3
                color: map-get($COLORS, 'secondary')

                span
                  font-size: 12px
                  font-weight: 400
                  line-height: 14px

                  margin-right: 15px

                .icon
                  width: 8px
                  height: 8px

                  opacity: .5

            .button
              width: 100%
              height: 40px
              max-width: 100%

          &--price
            #price-slider
              height: 2px

              border: none

              background-color: rgba(#C4C4C4, .5)

              .ui-slider-range
                background-color: map-get($COLORS, 'primary')

              .ui-slider-handle
                width: 10px
                height: 10px

                border: none
                outline: none
                border-radius: 50%

                background-color: map-get($COLORS, 'primary')

                &::after
                  content: ''

                  position: absolute

                  top: 50%
                  left: 50%

                  width: 4px
                  height: 4px

                  border-radius: 50%

                  transform: translate(-50%, -50%)

                  background-color: map-get($COLORS, 'white')

            .values
              display: grid
              grid-gap: 10px
              grid-template-columns: 1fr 1fr

              margin-top: 20px

              .value
                display: flex
                align-items: center
                flex-direction: row

                padding: 5px 10px

                border-width: 1px
                border-style: solid
                border-color: rgba(map-get($COLORS, 'secondary'), .2)

                > span
                  opacity: .5

                  font-size: 12px
                  font-weight: 400
                  line-height: 14px

                  margin-right: 10px

                  color: map-get($COLORS, 'secondary')

                > input
                  padding: 0

                  width: 100%

                  opacity: .5

                  border: none
                  outline: none
                  background: none

                  font-size: 14px
                  font-weight: 400
                  line-height: 17px

                  transition-duration: .2s
                  transition-property: color, opacity

                  color: map-get($COLORS, 'secondary')

                  &:focus
                    opacity: 1

                    color: map-get($COLORS, 'primary')

                &:not(:last-child)
                  margin-right: 10px

      .catalog__content
        > a
          display: block

          font-size: 24px
          font-weight: 500
          line-height: 30px

          margin-bottom: 45px

          text-decoration: underline

          color: map-get($COLORS, 'primary')

          @include screen('md')
            font-size: 20px
            line-height: 24px

            margin-bottom: 35px

          @include screen('sm')
            font-size: 18px
            line-height: 22px

            margin-bottom: 30px