h1, h2, h3,
h4, h5, h6
  margin: 0

p
  margin: 0

ul
  margin: 0
  padding: 0

  list-style: none

a
  color: inherit
 
  text-decoration: none

a,
input,
button
  font-family: map-get($FONTS, 'montserrat')
