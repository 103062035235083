.section--news-single
  padding-top: 0
  
  article
    img
      float: left

      max-width: 100%

      margin: 0 20px 20px 0

  .date
    display: flex

    font-size: 14px
    font-weight: 500
    line-height: 17px

    margin-top: 15px
    margin-left: auto

    max-width: max-content

    color: map-get($COLORS, 'primary')

  .links
    display: grid
    grid-gap: 40px
    grid-template-rows: 70px
    grid-template-columns: 1fr 1fr

    margin-top: 15px

    @include screen('sm')
      grid-gap: 10px
      grid-template-columns: 1fr
      grid-template-rows: 70px 70px

    .button
      width: 100%
      height: 100%
      max-width: 100%