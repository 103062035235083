.section--popular-products
  padding-bottom: 160px

  @include screen('lg')
    padding-bottom: 100px

  @include screen('sm')
    padding-bottom: 85px

  @include screen('sm')
    padding-bottom: 70px