.section--cart
  padding-top: 0

  .cart
    display: grid
    grid-gap: 40px
    grid-template-columns: 1fr 370px

    @include screen('xl')
      grid-template-columns: 1fr

    &__inner
      .cart__items
        display: flex
        flex-direction: column

        .additional
          opacity: .5

          font-size: 14px
          font-weight: 400
          line-height: 17px

          margin: 15px 0

          max-width: 560px

          @include screen('sm')
            font-size: 12px
            line-height: 14px

          a
            text-decoration: underline

            color: map-get($COLORS, 'primary')

        .item
          display: flex
          flex-wrap: wrap
          flex-direction: row
          align-items: center

          padding: 45px 0

          border-bottom-width: 1px
          border-bottom-style: solid
          border-bottom-color: rgba(map-get($COLORS, 'secondary'), .2)

          color: map-get($COLORS, 'secondary')

          &:first-child
            padding-top: 0 

          &__image
            display: flex

            width: 160px
            height: 160px

            margin-right: 30px

            @include screen('md')
              width: 120px
              height: 120px

              margin-right: 20px
              margin-bottom: 10px

            @include screen('sm')
              width: 80px
              height: 80px

            img
              width: 100%
              height: 100%

              object-fit: cover
              object-position: center
          
          &__info
            display: flex
            flex-direction: column

            max-width: 250px

            margin-right: 40px

            @include screen('md')
              margin-right: 10px

              max-width: 200px

            @include screen('sm')
              margin-right: 0

              max-width: 180px

            .item__name
              font-size: 14px
              font-weight: 500
              line-height: 21px

              margin-bottom: 15px

            .item__model
              display: flex
              flex-direction: row
              align-items: center

              font-size: 14px
              font-weight: 400
              line-height: 17px

              @include screen('sm')
                font-size: 10px
                line-height: 12px

              span:nth-child(1)
                opacity: .5

                margin-right: 10px

              span:nth-child(2)
                font-weight: 500

          &__counter
            margin-right: 30px

            .missing
              opacity: .5

              font-size: 14px
              font-weight: 400
              line-height: 17px

          &__price
            font-size: 14px
            font-weight: 500
            line-height: 17px

          &__remove
            cursor: pointer

            margin-left: auto

            color: map-get($COLORS, 'fail')

            .icon
              width: 15px
              height: 15px

    &__cheque
      padding: 30px

      background-color: #FAFAFA
      color: map-get($COLORS, 'secondary')

      @include screen('sm')
        padding: 30px 15px

      .cheque__title
        font-size: 25px
        font-weight: 500
        line-height: 30px

        margin-bottom: 15px

      .cheque__info
        font-size: 12px
        line-height: 18px

        margin-bottom: 5px

        span
          font-weight: 500

          color: map-get($COLORS, 'primary')
      
      .cheque__details
        display: flex
        flex-direction: column

        margin-bottom: 5px

        .detail
          display: flex
          flex-direction: row
          align-items: center
          justify-content: space-between

          padding: 25px 0

          &:not(:last-child)
            border-bottom-width: 1px
            border-bottom-style: solid
            border-bottom-color: rgba(map-get($COLORS, 'secondary'), .1)
          
          &--result
            .detail__key
              font-size: 16px
              font-weight: 500
              line-height: 20px
            
            .detail__value
              font-size: 25px
              font-weight: 600
              line-height: 30px

              color: map-get($COLORS, 'secondary')

          &__key
            font-size: 14px
            font-weight: 400
            line-height: 17px

          &__value
            font-size: 16px
            font-weight: 600
            line-height: 20px

            color: map-get($COLORS, 'primary')

      .button
        width: 100%
        max-width: 100%

        margin-bottom: 20px

      .cheque__agreement
        font-size: 10px
        font-weight: 400
        line-height: 15px
        text-align: center

        a
          text-decoration: underline