.section--contact-page
  padding-top: 0

  .contact
    display: grid
    grid-gap: 60px
    grid-template-columns: 370px 1fr

    @include screen('lg')
      grid-gap: 30px
      grid-template-columns: 340px 1fr

    @include screen('md')
      grid-gap: 30px
      grid-template-columns: 1fr
      grid-template-rows: auto auto

    &__info
      display: flex
      flex-direction: column

      font-size: 18px
      font-weight: 500
      line-height: 28px

      color: map-get($COLORS, 'secondary')

      .contact__location
        display: flex
        flex-direction: column

        margin-top: 20px
        margin-bottom: 25px

        @include screen('md')
          margin-top: 0

      .contact__contacts
        display: flex
        flex-direction: column

        > span
          margin-bottom: 15px

        .contact__items
          display: flex
          flex-direction: column

          margin-bottom: 15px
          padding-bottom: 15px

          border-bottom-width: 2px
          border-bottom-style: solid
          border-bottom-color: rgba(map-get($COLORS, 'black'), .1)

          .item
            display: flex
            flex-direction: row

            font-size: 14px
            font-weight: 500
            line-height: 22px

            &:hover
              opacity: 1

              .item__icon
                color: map-get($COLORS, 'primary')

            &:not(:last-child)
              margin-bottom: 20px

            &__icon
              opacity: .5

              transition-duration: .2s
              transition-property: color, opacity

              .icon
                width: 25px
                height: 25px

                margin-right: 10px

            &__list
              display: flex
              flex-direction: column

              a
                opacity: .5

                transition-duration: .2s
                transition-property: color, opacity

                &:hover
                  opacity: 1

                  color: map-get($COLORS, 'primary')

                &:not(:last-child)
                  margin-bottom: 5px

      .contact__socials
        display: flex
        flex-direction: column

        span
          margin-bottom: 15px
        
        .socials
          display: flex
          align-items: center
          flex-direction: row

          a
            &:not(:last-child)
              margin-right: 20px

    &__map
      position: relative

      max-width: 700px
      max-height: 400px

      overflow: hidden

      #map
        width: 100%
        height: 100%

            
