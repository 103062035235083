.news
  display: flex
  flex-direction: column

  will-change: transform
  transition-duration: .3s
  transition-property: transform

  &--soh
    &:hover
      @media screen and (min-width: map-get($SCREENS, 'lg'))
        transform: scale(1.05)

  &--large
    display: flex
    flex-direction: row

    @include screen('md')
      flex-direction: column

    .news__thumbnail
      height: 260px

      margin-right: 30px

    .news__date
      margin-bottom: 10px

  &__thumbnail
    display: flex
    align-items: center
    justify-content: center

    width: 100%
    height: 210px

    overflow: hidden

    margin-bottom: 10px

    background-color: #F3F3F3

    img
      width: 100%
      height: 100%

      object-fit: cover
      object-position: center

  &__date
    display: block

    font-size: 14px
    font-weight: 500
    line-height: 17px

    margin-bottom: 15px

    color: map-get($COLORS, 'primary-dark')

  &__name
    display: block

    font-size: 25px
    font-weight: 500
    line-height: 35px

    margin-bottom: 20px

    color: map-get($COLORS, 'secondary')
    
    @include screen('md')
      font-size: 20px
      line-height: 25px

  &__excerpt
    font-size: 14px
    font-weight: 400
    line-height: 21px

    margin-bottom: 25px

    color: map-get($COLORS, 'secondary')

  .button
    width: 100%
    max-width: 225px