.section--contact
  .contact
    display: grid
    grid-gap: 50px
    grid-template-columns: 1fr 1fr

    @include screen('lg')
      grid-gap: 30px
      grid-template-columns: auto 1fr

    @include screen('md')
      grid-template-columns: 1fr
      grid-template-rows: auto 400px

    &__info
      display: flex
      flex-direction: column

      .contact__call
        display: flex
        flex-direction: row

        margin-bottom: 40px

        @include screen('lg')
          flex-direction: column

        @include screen('md')
          flex-direction: row

        @include screen('sm')
          flex-direction: column

        .contact__assistant
          display: flex

          position: relative

          margin-right: 35px

          border-radius: 0 0 10% 10%

          overflow: hidden

          max-width: 220px
          
          &::before
            content: ''

            position: absolute

            top: 12px
            left: 0
            right: 0
            bottom: 0

            background-color: #F3F3F3

          .contact__call-status
            position: absolute

            z-index: 2

            right: 3px
            bottom: 3px

            width: 24px
            height: 24px

            border-width: 5px
            border-radius: 50%
            border-style: solid
            border-color: map-get($COLORS, 'white')

            &--fail
              background-color: map-get($COLORS, 'fail')

            &--success
              background-color: map-get($COLORS, 'success')

          img
            position: relative

            z-index: 2

        .contact__question
          display: flex
          flex-direction: column

          .contact__title
            font-size: 25px
            font-weight: 500
            line-height: 30px

            margin-top: 30px

            color: map-get($COLORS, 'secondary')

          a:not(.button)
            font-size: 14px
            font-weight: 400
            line-height: 17px
            text-decoration: underline

            color: map-get($COLORS, 'primary')

          .contact__hint
            font-size: 12px
            font-weight: 400
            line-height: 22px

            opacity: .8

            color: map-get($COLORS, 'secondary')

          .button
            width: 100%
            max-width: 230px

            margin-top: 25px

      .contact__connect
        display: flex
        flex-direction: column

        span
          font-size: 20px
          font-weight: 400
          line-height: 24px

          max-width: 400px

          margin-bottom: 35px

          color: map-get($COLORS, 'secondary')

          @include screen('lg')
            max-width: 300px

          @include screen('sm')
            font-size: 18px
            line-height: 25px

        a
          font-size: 45px
          font-weight: 400
          line-height: 55px

          color: map-get($COLORS, 'primary')

          @include screen('lg')
            font-size: 30px
            line-height: 36px

          @include screen('sm')
            font-size: 25px
            line-height: 30px

            color: map-get($COLORS, 'secondary')

          &:not(:last-of-type)
            margin-bottom: 30px

            @include screen('md')
              margin-bottom: 20px

            @include screen('sm')
              margin-bottom: 15px

          .icon
            width: 31px
            height: 31px

            margin-right: 35px

            @include screen('md')
              margin-right: 20px

            @include screen('sm')
              width: 20px
              height: 20px

              margin-right: 15px

    &__location
      position: relative
        
      max-width: 550px
      max-height: 550px

      overflow: hidden

      @include screen('lg')
        max-height: 100%

      #map
        width: 100%
        height: 100%

      &-info
        position: absolute

        left: 40px
        bottom: 40px

        display: flex
        flex-direction: row

        padding: 20px

        background-color: map-get($COLORS, 'white')

        @include screen('xl')
          left: 20px
          bottom: 20px

        @include screen('lg')
          flex-direction: column

        @include screen('sm')
          left: 0
          right: 0
          bottom: 0

        .column
          display: flex
          flex-direction: column

          font-size: 14px
          font-weight: 400
          line-height: 21px

          max-width: max-content

          color: map-get($COLORS, 'black')

          &:not(:last-child)
            margin-right: 50px

            @include screen('lg')
              margin-right: 0
              margin-bottom: 20px

          .contact__location-title
            font-size: 20px
            font-weight: 500
            line-height: 24px

            margin-bottom: 15px

            color: map-get($COLORS, 'primary')

          .bold
            font-weight: 500