.counter
  display: flex
  flex-direction: row
  align-items: center

  color: map-get($COLORS, 'secondary')

  &__count
    display: flex
    align-items: center
    justify-content: center

    border: none
    outline: none

    width: 40px
    height: 40px

    margin: 0 8px

    font-size: 14px
    font-weight: 500
    line-height: 17px
    text-align: center

    background-color: #FAFAFA

  &__control
    cursor: pointer

    &:not(.disabled)
      color: map-get($COLORS, 'primary')

    .icon
      width: 7px
      height: 12px
