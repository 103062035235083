.section--product
  padding-top: 0
  padding-bottom: 0

  .item
    display: grid
    grid-gap: 45px
    grid-template-columns: 640px auto

    margin-bottom: 55px

    @include screen('xl')
      grid-template-columns: 500px auto

    @include screen('lg')
      grid-template-columns: 1fr

    &__gallery
      @include screen('md')
        grid-gap: 10px
        grid-template-rows: 300px 100px

      @include screen('sm')
        grid-template-rows: 250px 90px

      .gallery__selected
        display: flex
        align-items: center
        justify-content: center

        height: 400px

        overflow: hidden

        margin-bottom: 20px

        @include screen('md')
          height: 300px

          margin-bottom: 10px

        @include screen('sm')
          height: 250px

        &.video
          iframe
            display: block

            width: 100%
            height: 100%

          > img
            display: none

        iframe
          display: none

        img
          width: 100%
          height: 100%

          object-fit: cover
          object-position: center

      .slick-track
        display: flex !important

      .slick-list
        margin-left: -17.5px

        min-width: calc(100% + 35px)

        @include screen('md')
          margin-left: -10px

          min-width: calc(100% + 20px)
      
      .slick-slide
        display: flex

        margin: 0 17.5px

        @include screen('md')
          margin: 0 10px

      .gallery__images
        .slider
          display: flex

          max-width: 100%

          &__items
            width: 100%

            display: flex
            
            cursor: default

        .gallery__image
          display: flex
          align-items: center
          justify-content: center

          cursor: pointer

          overflow: hidden

          width: 100%
          height: 110px

          &.video
            position: relative

            .overlay
              position: absolute

              top: 0
              left: 0
              right: 0
              bottom: 0

              display: flex
              align-items: center
              justify-content: center

              background-color: rgba(#050404, .4)

              img
                width: 55px
                height: 55px

          &.active
            border-width: 2px
            border-style: solid
            border-color: map-get($COLORS, 'primary')

          @include screen('md')
            grid-gap: 10px
            
            height: 100px

          @include screen('sm')
            height: 90px

          img
            width: 100%
            height: 100%

            object-fit: cover
            object-position: center
        
    &__info
      display: flex
      flex-direction: column

      color: map-get($COLORS, 'secondary')

      .item__title
        font-size: 45px
        font-weight: 500
        line-height: 54px

        margin-bottom: 30px

        @include screen('lg')
          font-size: 40px
          line-height: 46px

        @include screen('md')
          font-size: 36px
          line-height: 42px

        @include screen('sm')
          font-size: 32px
          line-height: 39px

      .item__specifications
        display: flex
        flex-direction: column

        margin-bottom: 40px

        > span
          font-size: 18px
          font-weight: 500
          line-height: 22px

          color: map-get($COLORS, 'primary')

        .item__specification
          display: flex
          align-items: center
          flex-direction: row

          font-size: 14px
          font-weight: 500
          line-height: 17px

          margin-top: 20px

          .key
            opacity: .6

            margin-right: 5px

          .value
            font-weight: 600

      .item__price
        display: flex
        flex-direction: row
        align-items: flex-end

        font-size: 30px
        font-weight: 500
        line-height: 36px

        margin-bottom: 15px

        color: map-get($COLORS, 'secondary')

        > span
          &:not(:last-child)
            margin-right: 15px
        
        .old
          opacity: .5

          font-size: 14px
          line-height: 17px

          text-decoration: line-through

      .item__order
        display: flex
        flex-direction: row
        align-items: center

        margin-bottom: 35px

        @include screen('sm')
          max-width: 225px

          flex-direction: column

        .button
          width: 100%
          max-width: 225px

          margin-right: 20px

          @media screen and (max-width: 400px)
            max-width: 100%
        
        > span
          font-size: 14px
          font-weight: 500
          line-height: 17px

          @include screen('sm')
            margin-top: 15px

      .item__advantages
        display: grid
        grid-gap: 50px
        grid-template-columns: 1fr 1fr

        @include screen('sm')
          grid-gap: 25px

        .item__advantage
          display: flex
          align-items: center
          flex-direction: row

          @include screen('sm')
            flex-direction: column
            align-items: flex-start

          .icon
            width: 60px
            height: 30px

            margin-right: 15px

            color: map-get($COLORS, 'primary')

            @include screen('sm')
              width: 40px
              height: 40px

              margin-right: 0
              margin-bottom: 10px

          span
            font-size: 14px
            font-weight: 500
            line-height: 21px

            @include screen('sm')
              font-size: 12px

  .info
    display: flex
    flex-direction: column

    &__tabs
      display: flex
      flex-direction: row

      .tab
        display: flex
        align-items: center
        justify-content: center

        font-size: 10px
        font-weight: 700
        line-height: 12px
        letter-spacing: 0.75px
        text-transform: uppercase

        width: 100%
        height: 70px
        max-width: 260px

        cursor: pointer

        background-color: #F3F3F3
        color: rgba(map-get($COLORS, 'secondary'), .5)

        transition-duration: .2s
        transition-property: color, background-color, opacity

        &.active
          color: map-get($COLORS, 'white')
          background-color: map-get($COLORS, 'primary')

    &__inner
      grid-gap: 50px
      display: grid !important
      grid-template-columns: auto 370px

      border-width: 2px
      border-style: solid
      border-color: #F3F3F3

      padding: 35px 60px

      @include screen('lg')
        padding: 25px 40px

        grid-template-columns: auto 250px

      @include screen('md')
        padding: 25px 20px

        grid-gap: 25px
        grid-template-columns: 1fr

        &--review
          .info__sidebar
            grid-row: 1

            padding-top: 0 !important
            border-top: none !important

            padding-bottom: 25px

            border-bottom-style: solid
            border-bottom-width: 2px
            border-bottom-color: rgba(map-get($COLORS, 'black'), .1) 

      .info__content
        font-size: 14px
        font-weight: 400
        line-height: 23px

        .paginator
          padding-top: 0

          border-top: none

        .sort
          display: flex
          align-items: center
          flex-direction: row

          font-size: 12px
          font-weight: 500
          line-height: 14px

          margin-bottom: 20px

          color: map-get($COLORS, 'secondary')

          > span
            margin-right: 12px

          &__item
            opacity: .5

            cursor: pointer

            transition-duration: .2s
            transition-property: color, opacity

            &:not(:last-child)
              margin-right: 10px

            &:hover
              opacity: 1

            &.active
              opacity: 1

              color: map-get($COLORS, 'primary')

        .reviews
          display: flex
          flex-direction: column

          .review
            display: flex
            flex-direction: column

            &:not(:last-child)
              margin-bottom: 45px

            &__header
              display: flex
              flex-direction: column

              margin-bottom: 15px

              > div
                display: flex
                flex-direction: row
                align-items: center

                width: 100%

                .review__author
                  font-size: 14px
                  font-weight: 500
                  line-height: 23px
                
                .review__rating
                  display: flex
                  flex-direction: row
                  align-items: center

                  margin-left: 15px

                  .star
                    display: flex
                    align-items: center
                    justify-content: center

                    opacity: .5

                    &:not(:last-child)
                      margin-right: 8px

                    &.active
                      opacity: 1

                      color: map-get($COLORS, 'primary')

                    .icon
                      width: 12px
                      height: 12px

                .review__date
                  opacity: .5

                  font-size: 10px
                  font-weight: 400
                  line-height: 12px

            &__blocks
              display: flex
              flex-direction: column

              .block
                display: flex
                flex-direction: column

                &:not(:last-child)
                  margin-bottom: 25px

                &__title
                  display: flex
                  flex-direction: row
                  align-items: center

                  font-size: 14px
                  font-weight: 500

                  margin-bottom: 10px

                  &--success
                    color: map-get($COLORS, 'success')
                  
                  &--fail
                    color: map-get($COLORS, 'fail')

                  .icon
                    width: 12px
                    height: 12px

                    margin-right: 8px

                &__content
                  font-size: 14px
                  font-weight: 400
                  line-height: 22px

                  padding-left: 20px

      .info__sidebar
        padding-left: 50px

        border-left-style: solid
        border-left-width: 2px
        border-left-color: rgba(map-get($COLORS, 'black'), .1) 

        @include screen('lg')
          padding-left: 25px

        @include screen('md')
          border-left: none

          padding-left: 0
          padding-top: 25px

          border-top-style: solid
          border-top-width: 2px
          border-top-color: rgba(map-get($COLORS, 'black'), .1) 

        .sidebar__title
          font-size: 16px
          font-weight: 600
          line-height: 20px

          margin-bottom: 25px

          color: map-get($COLORS, 'primary')

        .sidebar__documentation
          display: flex
          flex-direction: column

          > a
            display: flex
            align-items: center
            flex-direction: row

            font-size: 14px
            font-weight: 500
            line-height: 17px

            transition-duration: .2s
            transition-property: color

            color: map-get($COLORS, 'secondary')

            &:hover
              color: map-get($COLORS, 'primary')

            &:not(:last-child)
              margin-bottom: 15px
            
            .icon
              min-width: 18px
              min-height: 22px
              max-width: 18px
              max-height: 22px

              margin-right: 13px

        .sidebar__rating
          display: flex
          flex-direction: column

          .ratings
            display: flex
            flex-direction: column

            margin-bottom: 20px

            color: map-get($COLORS, 'secondary')

            .rating
              display: flex
              align-items: center
              flex-direction: row

              font-size: 14px
              font-weight: 500
              line-height: 17px

              &:not(:last-child)
                margin-bottom: 12px

              > a, span
                opacity: .5

              > a
                text-decoration: underline

              &__gradations
                display: flex
                flex-direction: row
                align-items: center

                margin-right: 15px

                .star
                  display: flex
                  align-items: center
                  justify-content: center

                  opacity: .5

                  &:not(:last-child)
                    margin-right: 8px

                  &.active
                    opacity: 1

                    color: map-get($COLORS, 'primary')

                  .icon
                    width: 12px
                    height: 12px
