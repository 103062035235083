.header
  display: flex
  flex-direction: column

  padding: 60px 0

  color: map-get($COLORS, 'secondary')

  @include screen('md')
    padding: 30px 0

  &__top
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between

    margin-bottom: 50px

    @include screen('md')
      display: none

    .header__location
      display: flex
      flex-direction: row
      align-items: center

      line-height: 1
      font-size: 16px
      font-weight: 500

      .icon
        width: 22px
        height: 22px

        margin-right: 7px

    .header__call
      display: flex
      flex-direction: row
      align-items: center

      font-size: 16px
      font-weight: 500
      line-height: 20px

      &-order
        opacity: .5

        cursor: pointer

        transition-duration: .2s
        transition-property: opacity, color

        &:hover
          opacity: 1

          color: map-get($COLORS, 'primary')
      
      &-divider
        width: 1px
        height: 16px

        opacity: .5

        margin: 0 12px

        background-color: map-get($COLORS, 'secondary')
      
      &-phone
        position: relative

        span:nth-child(1)
          position: absolute

          right: 0
          top: -4px

          transform: translateY(-100%)

          font-size: 12px
          font-weight: 500
          line-height: 14px

          height: min-content

          opacity: .5

          color: map-get($COLORS, 'black')
        
        &-number
          display: flex
          flex-direction: row
          align-items: center

          font-weight: 600

          .header__call-status
            width: 6px
            height: 6px

            margin-right: 10px

            border-radius: 50%

            &--fail
              background-color: map-get($COLORS, 'fail')

            &--success
              background-color: map-get($COLORS, 'success')

  &__middle
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between

    margin-bottom: 40px

    @include screen('xl')
      margin-bottom: 0

    @include screen('md')
      justify-content: flex-start

    .header__logo
      display: flex

      min-width: min-content

      img
        @include screen('xl')
          width: 180px

        @include screen('sm')
          width: 130px

    .header__search
      width: 100%
      max-width: 525px

      margin: 0 20px

      @include screen('md')
        display: none

      .input
        height: 50px

    .header__login
      width: 100%
      max-width: 165px
      margin-right: 10px

      @include screen('md')
        display: none

      .button__inner
        height: 50px

    .header__menu-mobile
      margin-left: auto

    .header__menu-mobile,
    .header__search-mobile
      display: none

      cursor: pointer

      margin-right: 25px
      
      transition-duration: .2s
      transition-property: color

      @include screen('md')
        display: block

      &:hover
        color: map-get($COLORS, 'primary')

      .icon
        width: 24px
        height: 24px

    .header__cart
      display: flex
      flex-direction: row
      align-items: center

      font-size: 16px
      font-weight: 500
      line-height: 20px

      min-width: max-content

      color: map-get($COLORS, 'secondary')

      transition-duration: .2s
      transition-property: color

      &:hover
        color: map-get($COLORS, 'primary')

      span
        @include screen('md')
          display: none

      &-icon
        position: relative

        width: 24px
        height: 24px

        margin-right: 15px

        @include screen('md')
          margin-right: 0

        .header__cart-count
          display: flex
          align-items: center
          justify-content: center

          position: absolute

          top: -5px
          right: -5px
          
          font-size: 8px
          line-height: 1
          font-weight: 500

          width: 16px
          height: 16px

          border-radius: 50%

          color: map-get($COLORS, 'white')
          background-color: map-get($COLORS, 'primary-dark')

  &__bottom
    .header__categories
      display: flex
      flex-direction: column

      position: relative

      @include screen('xl')
        display: none

      > *
        z-index: 11

      &.active
        &::before
          content: ''

          position: absolute

          z-index: 10

          top: -20px
          left: -40px

          min-height: 840px
          width: calc(100% + (2 * 40px))

          background-color: map-get($COLORS, 'white')

          box-shadow: 0px 12px 45px rgba(map-get($COLORS, 'black'), .1)
        
        .row
          border-bottom-width: 1px
          border-bottom-style: solid
          border-bottom-color: map-get($COLORS, 'primary-dark')

        .dropdown
          display: flex

      .row
        justify-content: space-between

      .header__category
        flex: auto
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center

        font-size: 16px
        font-weight: 500
        line-height: 20px
        white-space: nowrap

        cursor: pointer

        height: 70px

        padding: 0 22px

        transition-duration: .2s
        transition-property: color, background-color

        color: map-get($COLORS, 'secondary')

        &:hover,
        &.active
          color: map-get($COLORS, 'white')
          background-color: map-get($COLORS, 'primary')

          .category__icon
            color: map-get($COLORS, 'white')

        .category__icon
          position: relative

          display: flex
          align-items: center
          justify-content: center

          width: 50px
          height: 50px

          margin-right: 12px

          border-radius: 50%

          color: map-get($COLORS, 'white')
          background-color: map-get($COLORS, 'primary')

          transition-duration: .2s
          transition-property: color

          &::before
            content: ''

            position: absolute

            right: -3px
            bottom: -3px

            width: 100%
            height: 100%

            border-width: 1px
            border-radius: 50%
            border-style: solid
            border-color: map-get($COLORS, 'primary')

          .icon
            width: 37px
            height: 37px
      
      .dropdown
        display: none
        flex-direction: row
        justify-content: space-between

        position: absolute

        top: 100px

        width: 100%

        &__column
          display: flex
          flex-direction: column

          max-width: max-content

          .block
            display: flex
            flex-direction: column

            &:not(:last-child)
              margin-bottom: 50px

            &__title
              display: block

              font-size: 20px
              font-weight: 500
              line-height: 24px

              margin-bottom: 25px

              color: map-get($COLORS, 'secondary')

            &__items
              .block__item
                &:not(:last-child)
                  margin-bottom: 15px

                a
                  display: block

                  line-height: 1
                  font-size: 14px
                  font-weight: 400

                  color: map-get($COLORS, 'secondary')

                  transition-duration: .2s
                  transition-property: color, transform

                  &:hover
                    transform: translateX(5px)

                    color: map-get($COLORS, 'primary-dark')

    