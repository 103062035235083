.menu
  display: flex
  flex-direction: row
  align-items: center

  @include screen('lg')
    display: none

  .menu__item
    font-size: 16px
    font-weight: 500
    line-height: 20px

    opacity: .5

    cursor: pointer

    transition-duration: .2s
    transition-property: opacity, color

    @include screen('xl')
      font-size: 14px

    &:hover
      opacity: 1

      color: map-get($COLORS, 'primary')

    &:not(:last-child)
      margin-right: 22px