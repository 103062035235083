.section--slider
  padding: 0

  overflow-x: hidden

  .slider
    max-width: 100%

    display: flex
    flex-direction: column

    .slick-track
      display: flex !important

    .slick-slide
      margin: 0 25px

      @include screen('sm')
        margin: 0 12.5px

    .slick-list
      margin: 0 -25px

      @include screen('sm')
        margin: 0 -12.5px

    &__items
      display: flex

      max-width: 100%

      margin-bottom: 70px

      @include screen('md')
        margin-bottom: 40px

      @include screen('sm')
        margin-bottom: 30px

    &__arrows
      display: flex
      align-items: center
      flex-direction: row
      justify-content: center

      .slider__arrow
        display: flex
        align-items: center
        justify-content: center

        width: 60px
        height: 60px

        cursor: pointer

        margin: 0 25px

        transition-duration: .2s
        transition-property: color

        @include screen('lg')
          width: 50px
          height: 50px

          margin: 0 30px

        @include screen('md')
          width: 40px
          height: 40px

          margin: 0 20px

        @include screen('sm')
          width: 30px
          height: 30px

          margin: 0 10px

        &[aria-disabled="false"]
          color: map-get($COLORS, 'primary-dark')

        .icon
          width: 33px
          height: 60px

          @include screen('lg')
            width: 40px
            height: 30px

          @include screen('md')
            width: 30px
            height: 20px

          @include screen('sm')
            width: 25px
            height: 15px