.checkbox
  display: flex
  flex-direction: row
  align-items: center

  &.active
    label
      border-color: map-get($COLORS, 'primary') !important

    .icon
      opacity: 1 !important

      color: map-get($COLORS, 'primary') !important

  &__virtual
    position: relative

    input[type="checkbox"]
      cursor: pointer

      position: absolute

      z-index: 2

      margin: 0
      padding: 0

      width: 14px
      height: 14px

      opacity: 0

      &:checked
        & + label > .check
          display: flex

    label
      display: block

      width: 14px
      height: 14px

      border-width: 1px
      border-style: solid
      border-color: rgba(#2B2A29, .2)

      z-index: -2

      .check
        display: none
        align-items: center
        justify-content: center

        width: 100%
        height: 100%

        .icon
          width: 12px
          height: 8px

          opacity: .5

          color: map-get($COLORS, 'secondary')
