.hero
  display: flex

  width: 100%

  overflow: hidden

  background-size: cover
  background-position: center
  background-repeat: no-repeat

  &--home
    @include screen('xl')
      height: 400px !important

    @include screen('lg')
      height: 350px !important

    @include screen('md')
      height: 300px !important

    @include screen('sm')
      height: 270px !important

  &--catalog
    @include screen('md')
      height: 280px !important

    @include screen('sm')
      display: none

  .container
    display: flex
    align-items: flex-start 

    height: 100%

    .hero__inner
      width: 100%

      margin-top: 50px

      display: flex
      flex-direction: row
      align-items: flex-end
      justify-content: space-between

      color: map-get($COLORS, 'white')

      @include screen('lg')
        flex-direction: column
        align-items: flex-start
        justify-content: center

        height: 100%

        margin-top: 0

      .hero__title
        font-size: 65px
        font-weight: 500
        line-height: 80px

        max-width: 550px

        @include screen('lg')
          margin-bottom: 20px

          font-size: 50px
          line-height: 60px

      .hero__description
        font-size: 14px
        font-weight: 500
        line-height: 21px

        max-width: 360px