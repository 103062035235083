.product
  will-change: transform
  transition-duration: .3s
  transition-property: transform

  &--soh
    &:hover
      @media screen and (min-width: map-get($SCREENS, 'lg'))
        transform: scale(1.05)

  &__thumbnail
    display: flex
    align-items: center
    justify-content: center

    width: 100%
    height: 320px

    overflow: hidden

    margin-bottom: 10px

    background-color: #F3F3F3

    img
      width: initial
      height: initial

      max-width: 100%
      max-height: 100%

      object-fit: cover
      object-position: center

  &__inner
    display: flex
    flex-direction: column

  &__category
    font-size: 16px
    line-height: 500
    line-height: 20px
    
    opacity: .5

    margin-bottom: 12px

    color: map-get($COLORS, 'secondary')

  &__name
    font-size: 25px
    font-weight: 500
    line-height: 35px

    margin-bottom: 20px

    color: map-get($COLORS, 'secondary')

    @include screen('md')
      font-size: 20px
      line-height: 25px

  &__footer
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between

    @include screen('md')
      flex-direction: column
      align-items: flex-start
    
    @include screen('sm')
      flex-direction: row
      align-items: center

  &__price
    font-size: 25px
    font-weight: 600
    line-height: 35px

    color: map-get($COLORS, 'secondary')

    @include screen('md')
      margin-bottom: 10px

    @include screen('sm')
      margin-bottom: 0

  .button
    width: 100%
    max-width: 225px

    @include screen('xl')
      max-width: 175px

    @include screen('sm')
      max-width: 155px