.section--breadcrumbs
  padding: 0

  margin: 0 0 30px 0

  .breadcrumbs
    display: flex
    flex-wrap: wrap
    align-items: center
    flex-direction: row

    color: #959494

    font-size: 12px
    font-weight: 400
    line-height: 14px

    .item
      &.active
        font-weight: 500

        color: map-get($COLORS, 'primary')

    .separator
      margin: 0 3px