.orders
  .sort
    margin-bottom: 30px

  .orders-list
    display: grid
    grid-gap: 20px
    grid-template-columns: 1fr

    .order
      display: grid
      grid-template-columns: 1fr
      grid-template-rows: 115px auto

      @include screen('md')
        grid-template-rows: auto auto

      &__header
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between

        padding: 20px 50px

        color: map-get($COLORS, 'white')
        background-color: map-get($COLORS, 'secondary')

        @include screen('md')
          flex-direction: column
          align-items: flex-start

        @include screen('md')
          padding: 20px

      &__info
        @include screen('md')
          margin-bottom: 20px

        > div
          display: flex
          flex-direction: row
          align-items: flex-end

          @include screen('sm')
            flex-direction: column
            align-items: flex-start

          &:not(:last-child)
            margin-bottom: 5px

      &__name
        line-height: 1
        font-size: 25px
        font-weight: 400

        margin-right: 20px

        @include screen('sm')
          margin-right: 0
          margin-bottom: 20px

      &__status
        font-size: 14px
        font-weight: 500
        line-height: 17px

        color: map-get($COLORS, 'primary')
      
      &__date
        font-size: 12px
        font-weight: 400
        line-height: 21px

      &__price
        display: flex
        flex-direction: row
        align-items: center

        > div
          display: flex
          flex-direction: row

          &:not(:last-child)
            margin-bottom: 5px
        
        > div:nth-child(1)
          span
            font-size: 18px
            font-weight: 400
            line-height: 21px

            margin-right: 15px

        > div:nth-child(2)
          display: flex
          flex-direction: column
          
        &-value
          line-height: 1
          font-size: 25px
          font-weight: 500

      &__count
        font-size: 12px
        font-weight: 400
        line-height: 21px 

      &__items
        display: grid
        grid-gap: 20px
        grid-auto-rows: 190px
        grid-template-columns: 1fr

        padding: 15px 0

        background-color: map-get($COLORS, 'white')

        @include screen('md')
          grid-auto-rows: auto

        .item
          display: grid
          grid-gap: 25px
          grid-template-columns: 190px 450px auto auto auto

          font-size: 14px
          font-weight: 500
          line-height: 17px

          color: map-get($COLORS, 'secondary')

          @include screen('xl')
            grid-template-columns: 190px auto auto auto auto

          @include screen('md')
            grid-template-columns: 150px auto auto

          @include screen('sm')
            grid-gap: 15px
            grid-template-columns: 80px auto

            font-size: 10px
            line-height: 12px

          &__image
            overflow: hidden

            img
              width: 100%
              height: 100%

              object-fit: cover
              object-position: center
          
          &__info
            display: flex
            flex-direction: column
            justify-content: center

          &__name
            font-size: 16px
            line-height: 27px

            max-width: 410px

            margin-bottom: 15px

            @include screen('sm')
              font-size: 14px
              line-height: 24px

              margin-bottom: 10px

          &__model
            span:nth-child(1)
              opacity: .5

              margin-right: 10px

          &__count
            display: flex
            align-items: center
            justify-content: center

            @include screen('sm')
              display: none

            span
              display: flex
              align-items: center
              justify-content: center

              width: 40px
              height: 40px

              line-height: 1

              background-color: #FAFAFA
          
          &__price
            display: flex
            align-items: center
            justify-content: center

          &__status
            display: flex
            align-items: center
            justify-content: center

            &--fail
              color: map-get($COLORS, 'fail')

            &--success
              color: map-get($COLORS, 'success')