.section--intro
  margin: 0 auto

  max-width: 1350px

  padding: 50px 0 0 0 

  transform: translateY(-100px)
  
  background-color: map-get($COLORS, 'white')

  .intro
    &__info
      display: flex
      flex-direction: row
      justify-content: space-between

      margin-bottom: 80px

      @include screen('lg')
        margin-bottom: 0

      @include screen('md')
        margin-bottom: 0
        flex-direction: column
    
      h1
        display: flex
        flex-direction: column

        font-size: 70px
        font-weight: 500
        line-height: 85px

        @include screen('xl')
          font-size: 60px
          line-height: 75px
        
        @include screen('lg')
          font-size: 55px
          line-height: 70px

          margin-bottom: 40px

        @include screen('md')
          font-size: 50px
          line-height: 65px

          text-align: center
        
        @include screen('sm')
          font-size: 45px
          line-height: 60px

          text-align: left

        span
          color: map-get($COLORS, 'primary-dark')
      
      .intro__content
        @include screen('lg')
          display: flex
          align-items: center
          flex-direction: column

      .intro__slider
        position: relative

        max-width: 240px

        margin-bottom: 20px

        @include screen('sm')
          width: 100%
          max-width: 300px

        .slider__arrows
          position: absolute

          right: 0
          top: 42px

          display: flex
          flex-direction: row

          .slider__arrow
            display: flex
            align-items: center
            justify-content: center

            width: 20px
            height: 20px

            cursor: pointer

            margin: 0 2.5px

            transition-duration: .2s
            transition-property: color

            &[aria-disabled="false"]
              color: map-get($COLORS, 'primary-dark')

            .icon
              width: 10px

        .slider__items
          display: flex

          .slide
            display: flex
            flex-direction: column

            &__index
              font-size: 65px
              font-weight: 300
              line-height: 80px

              margin-bottom: 15px

              color: map-get($COLORS, 'primary-dark')

            &__description
              font-size: 14px
              font-weight: 400
              line-height: 21px

              color: map-get($COLORS, 'secondary')

        .button
          margin-top: 25px

    &__survey
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between

      @include screen('lg')
        flex-direction: column

      .survey
        display: flex
        flex-direction: column

        margin-right: 85px

        @include screen('lg')
          margin-right: 0

        &__discount
          font-size: 200px
          font-weight: 600
          line-height: 243px

          color: map-get($COLORS, 'primary')

          @include screen('lg')
            font-size: 180px
            line-height: 210px
          
          @include screen('lg')
            font-size: 170px
            line-height: 190px
          
          @include screen('sm')
            font-size: 155px
            line-height: 175px

        &__info
          display: flex
          flex-direction: column

          font-size: 30px
          font-weight: 400
          line-height: 36px

          max-width: 370px

          padding: 10px

          transform: translate(-30px, -85px)

          background-color: map-get($COLORS, 'white')

          @include screen('lg')
            font-size: 26px
            line-height: 32px
          
          @include screen('lg')
            font-size: 24px
            line-height: 28px
          
          @include screen('sm')
            font-size: 20px
            line-height: 24px

            max-width: 280px

            transform: translate(-10px, -65px)

          span
            font-weight: 600

            color: map-get($COLORS, 'primary-dark')

      .survey-start
        flex: 1
        display: flex
        flex-direction: column

        .steps
          display: flex
          flex-direction: row
          justify-content: space-between

          margin-bottom: 30px

          @include screen('sm')
            flex-direction: column

          .step
            display: flex
            flex-direction: column

            max-width: 180px

            @include screen('sm')
              flex-direction: row

              max-width: 300px

            &:not(:last-child)
              @include screen('lg')
                margin-right: 20px

              @include screen('sm')
                margin-right: 0
                margin-bottom: 40px

            &__index
              font-size: 35px
              font-weight: 400
              line-height: 42px

              margin-bottom: 30px

              color: map-get($COLORS, 'primary-dark')

              @include screen('sm')
                margin-bottom: 0
                margin-right: 30px

            &__description
              font-size: 14px
              font-weight: 400
              line-height: 20px

              color: map-get($COLORS, 'secondary')

        .button
          max-width: 175px
        

    