.container
  width: 100%
  max-width: calc(1150px + (30px * 2))
  
  margin: 0 auto
  padding-left: 30px
  padding-right: 30px

  @include screen('sm')
    padding-left: 15px
    padding-right: 15px

    max-width: calc(1150px + (15px * 2))

.row
  display: flex
  flex-direction: row

.column
  display: flex
  flex-direction: column
