.section--order
  padding-top: 0

  .order
    display: grid
    grid-gap: 40px
    grid-template-columns: 1fr 390px

    @include screen('lg')
      grid-template-columns: 1fr

    &__inner
      display: flex
      flex-direction: column

      .block
        display: flex
        flex-direction: column

        &:not(:last-of-type)
          margin-bottom: 50px

        .block__title
          font-size: 35px
          font-weight: 500
          line-height: 42px

          margin-bottom: 25px

          @include screen('lg')
            font-size: 30px
            line-height: 35px

          @include screen('md')
            font-size: 24px
            line-height: 28px

          @include screen('sm')
            font-size: 20px
            line-height: 24px

        .block__inner
          display: flex
          flex-direction: column

        &--delivery
          .deliveries
            display: grid
            grid-gap: 20px
            grid-auto-rows: 100px
            grid-template-columns: 1fr 1fr

            margin-bottom: 25px

            @include screen('xl')
              grid-auto-rows: auto

            @include screen('lg')
              grid-template-columns: 1fr

            .delivery
              cursor: pointer

              display: flex
              flex-direction: row

              padding: 25px 30px

              border-width: 1px
              border-style: solid
              border-color: #E3E3E3

              color: map-get($COLORS, 'secondary')

              @include screen('sm')
                padding: 25px 20px

              &.active
                border-color: map-get($COLORS, 'primary')

              &__check
                margin-right: 15px

              &__info
                flex: 1
                display: flex
                flex-direction: column

                > div
                  &:nth-child(1)
                    display: flex
                    flex-direction: row
                    align-items: center
                    justify-content: space-between

                    @include screen('xl')
                      flex-direction: column
                      align-items: flex-start

                    @include screen('lg')
                      flex-direction: row
                      align-items: center

                  &:not(:last-child)
                    margin-bottom: 8px

                .delivery__name
                  line-height: 1
                  font-size: 18px
                  font-weight: 600

                  @include screen('xl')
                    margin-bottom: 10px

                  @include screen('lg')
                    margin-bottom: 0

                  @include screen('md')
                    font-size: 16px

                  @include screen('sm')
                    font-size: 14px

                .delivery__price
                  font-size: 14px
                  font-weight: 600
                  line-height: 17px

                  color: map-get($COLORS, 'primary')

                .delivery__date
                  opacity: .5

                  line-height: 1
                  font-size: 12px
                  font-weight: 500

          .location
            display: flex
            flex-direction: row

            overflow: hidden

            height: 250px

            @include screen('lg')
              height: auto

            &__info
              display: flex
              flex-direction: column

              padding: 10px 0

              margin-right: 40px

              min-width: max-content

              background-color: map-get($COLORS, 'white')

              .column
                display: flex
                flex-direction: column

                font-size: 14px
                font-weight: 400
                line-height: 21px

                max-width: max-content

                color: map-get($COLORS, 'black')

                &:not(:last-child)
                  margin-bottom: 40px

                &__title
                  font-size: 20px
                  font-weight: 500
                  line-height: 24px

                  margin-bottom: 15px

                  color: map-get($COLORS, 'primary')

                .bold
                  font-weight: 500

            #map
              width: 100%
              height: 100%

              @include screen('lg')
                display: none
        
        &--receive
          .receives
            display: grid
            grid-gap: 40px
            grid-auto-rows: 60px
            grid-template-columns: 1fr 1fr

            @include screen('md')
              grid-gap: 20px
              grid-template-columns: 1fr

            .receive
              cursor: pointer

              display: flex
              flex-direction: row
              align-items: center

              padding: 15px 30px

              border-width: 1px
              border-style: solid
              border-color: #E3E3E3

              color: map-get($COLORS, 'secondary')

              &.active
                border-color: map-get($COLORS, 'primary')

              &__check
                margin-right: 15px

              &__name
                font-size: 18px
                font-weight: 400
                line-height: 22px

                @include screen('xl')
                  font-size: 14px
                  line-height: 18px
        
        &--details
          .details
            display: grid
            grid-gap: 30px
            grid-template-columns: 1fr

            @include screen('sm')
              grid-gap: 25px

            .detail
              display: grid
              grid-gap: 20px
              grid-template-columns: 100px 1fr

              @include screen('sm')
                grid-gap: 10px
                grid-template-columns: 1fr

              &__key
                display: flex
                flex-direction: row
                align-items: center

                font-size: 18px
                font-weight: 500
                line-height: 22px

              &__value
                display: flex
                flex-direction: column

                .detail__hint
                  font-size: 14px
                  font-weight: 500
                  line-height: 17px

                  margin-top: 15px
                  margin-left: 30px

                  @include screen('sm')
                    font-size: 12px
                    line-height: 16px

                    margin-left: 0

                  span
                    color: map-get($COLORS, 'primary')

                .input
                  width: 100%

                  height: 60px

                  padding: 10px 30px

                  background-color: transparent

                  border-width: 1px
                  border-style: solid
                  border-color: rgba(map-get($COLORS, 'secondary'), .2)

                  input
                    font-size: 18px
                    font-weight: 500
                    line-height: 22px

                    opacity: 1

                    height: 100%
    
    &__cheque
      display: flex
      flex-direction: column
      
      height: max-content

      padding: 15px 20px 35px 20px

      background-color: #FAFAFA
      color: map-get($COLORS, 'secondary')

      .cheque__list
        display: flex
        flex-direction: column

        margin-bottom: 25px

        .item
          position: relative

          display: flex
          flex-direction: row
          align-items: center

          padding: 25px 0

          border-bottom-width: 1px
          border-bottom-style: solid
          border-bottom-color: #E5E5E5

          &__remove
            position: absolute

            right: 0
            top: 20px

            cursor: pointer

            color: map-get($COLORS, 'fail')

            .icon
              width: 10px
              height: 10px 

          &__image
            width: 75px
            height: 75px

            margin-right: 25px

            @include screen('sm')
              display: none
    
            img
              width: 100%
              height: 100%

              object-fit: cover
              object-position: center

          &__info
            display: flex
            flex-direction: column

            max-width: 215px

            .item__name
              font-size: 12px
              font-weight: 500
              line-height: 18px

              margin-bottom: 15px

            > div
              display: flex
              flex-direction: row
              align-items: center
              justify-content: space-between

              .item__price
                font-size: 16px
                font-weight: 600
                line-height: 20px

              .item__count
                font-size: 14px
                font-weight: 500
                line-height: 17px

                color: map-get($COLORS, 'primary')

      .cheque__details
        display: flex
        flex-direction: column

        margin-bottom: 25px

        .detail
          display: flex
          flex-direction: row
          align-items: center
          justify-content: space-between

          &:not(:last-child)
            margin-bottom: 20px

          &__key
            font-size: 16px
            font-weight: 500
            line-height: 20px

          &__value
            font-size: 18px
            font-weight: 600
            line-height: 22px

            color: map-get($COLORS, 'primary')

      .button
        width: 100%
        max-width: 100%

        margin-bottom: 20px

      .cheque__agreement
        font-size: 10px
        font-weight: 400
        line-height: 15px
        text-align: center

        a
          text-decoration: underline