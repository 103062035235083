.radio
  display: flex
  flex-direction: row
  align-items: center

  &.active
    label
      opacity: 1

      display: flex

  &__virtual
    position: relative

    width: 14px
    height: 14px

    border-radius: 50%

    background-color: #F3F3F3

    input[type="radio"]
      cursor: pointer

      position: absolute

      z-index: 2

      margin: 0
      padding: 0

      width: 14px
      height: 14px

      opacity: 0

      &:checked
        & + label
          display: flex

    label
      position: relative

      display: none

      z-index: 1

      width: 14px
      height: 14px

      border-radius: 50%

      &::after
        content: ''

        position: absolute

        top: 50%
        left: 50%

        width: 8px
        height: 8px

        border-radius: 50%

        transform: translate(-50%, -50%)

        background-color: map-get($COLORS, 'primary')

  &--big
    .radio__virtual,
    input[type="radio"],
    label
      width: 28px
      height: 28px

    label::after
      width: 20px
      height: 20px

      
