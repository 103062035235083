.footer
  font-size: 14px
  font-weight: 400
  line-height: 17px

  border-top-width: 1px
  border-top-style: solid
  border-top-color: rgba(map-get($COLORS, 'secondary'), .2)

  padding: 80px 0 40px 0

  color: map-get($COLORS, 'secondary')

  @include screen('md')
    padding: 50px 0 

  &__title
    font-size: 25px
    font-weight: 500
    line-height: 30px

    margin-bottom: 30px

    color: map-get($COLORS, 'primary')

  &__row
    display: grid
    grid-gap: 10px
    grid-template-columns: repeat(4, auto)

    margin: 0 auto

    @include screen('lg')
      max-width: 600px

      grid-gap: 50px
      grid-template-columns: repeat(2, auto)

    @include screen('md')
      grid-gap: 30px

    @include screen('sm')
      max-width: 300px
      
      grid-template-columns: repeat(1, auto)

    .footer__column
      display: flex

      &-inner
        display: flex
        flex-direction: column        

      &--main
        .footer__logo
          display: flex

          margin-bottom: 30px

        .footer__call
          display: flex
          flex-direction: column

          font-size: 16px
          font-weight: 500
          line-height: 20px

          margin-bottom: 30px

          max-width: max-content

          &-order
            opacity: .5

            cursor: pointer

            max-width: max-content

            transition-duration: .2s
            transition-property: opacity, color

            &:hover
              opacity: 1

              color: map-get($COLORS, 'primary')
          
          &-phone
            &-number
              display: flex
              flex-direction: row
              align-items: center

              font-weight: 600

              margin-bottom: 5px

              .footer__call-status
                width: 6px
                height: 6px

                margin-right: 10px

                border-radius: 50%

                &--fail
                  background-color: map-get($COLORS, 'fail')

                &--success
                  background-color: map-get($COLORS, 'success')

        .footer__policy
          opacity: .7

          max-width: max-content

          transition-duration: .2s
          transition-property: color, opacity

          &:hover
            opacity: 1

            color: map-get($COLORS, 'primary')

      &--navigation
        .footer__column-inner
          display: flex
          flex-direction: column

          max-width: 260px

          .navigation__items
            display: flex
            flex-direction: column

            a
              opacity: .7

              transition-duration: .2s
              transition-property: color, opacity

              &:not(:last-child)
                margin-bottom: 20px

              &:hover
                opacity: 1

                color: map-get($COLORS, 'primary')

      &--media
        .footer__medias
          display: flex
          flex-direction: row
          align-items: center

          .media
            opacity: .7

            cursor: pointer

            transition-duration: .2s
            transition-property: color, opacity

            color: map-get($COLORS, 'secondary')

            &:hover
              opacity: 1

              color: map-get($COLORS, 'primary')

            &:not(:last-child)
              margin-right: 25px

            .icon
              width: 25px

        .footer__copyright
          opacity: .7

          margin-top: auto

          @include screen('sm')
            margin-top: 35px