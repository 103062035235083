.category
  position: relative

  display: flex

  overflow: hidden

  will-change: transform
  transition-duration: .3s
  transition-property: transform

  &--soh
    &:hover
      @media screen and (min-width: map-get($SCREENS, 'lg'))
        transform: scale(1.05)

  img
    width: 100%
    height: 100%

    object-fit: cover
    object-position: center

  &__info
    display: flex
    flex-direction: column

    position: absolute

    left: 50%
    bottom: 35px

    width: 100%
    max-width: 290px

    padding: 25px 20px

    transform: translateX(-50%)

    transition-duration: .2s
    transition-property: background-color

    background-color: map-get($COLORS, 'white')

    @include screen('xl')
      max-width: 250px

    @include screen('sm')
      left: 0
      bottom: 0

      max-width: 100%

      transform: translateX(0)

      color: map-get($COLORS, 'white')
      background-color: map-get($COLORS, 'primary')

    &:hover
      background-color: map-get($COLORS, 'primary')

      .category__name
        color: map-get($COLORS, 'white')

      .category__redirect
        color: map-get($COLORS, 'white')

    .category__name
      font-size: 20px
      font-weight: 500
      line-height: 24px

      margin-bottom: 10px

      transition-duration: .2s
      transition-property: color

      color: map-get($COLORS, 'secondary')

      @include screen('md')
        font-size: 20px
        line-height: 25px

      @include screen('sm')
        color: map-get($COLORS, 'white')

    .category__redirect
      display: flex
      flex-direction: row
      align-items: center

      line-height: 1
      font-size: 16px
      font-weight: 500

      transition-duration: .2s
      transition-property: color

      color: map-get($COLORS, 'primary')

      @include screen('sm')
        color: map-get($COLORS, 'white')

      .icon
        width: 10px

        margin-left: 13px