.section--about
  .section__title
    max-width: 440px

  .about
    display: grid
    grid-gap: 100px
    grid-template-columns: 1fr 1fr

    @include screen('xl')
      grid-gap: 50px

    @include screen('lg')
      grid-template-columns: 1fr

    &__image
      display: flex

      @include screen('lg')
        display: none

      img
        width: 100%

    &__content
      display: flex
      flex-direction: column

      margin-top: -90px

      @include screen('lg')
        margin-top: 0

      .about__heading
        display: flex
        flex-direction: row
        align-items: flex-end

        margin-bottom: 30px

        @include screen('sm')
          margin-bottom: 20px

        .about__title
          font-size: 25px
          font-weight: 500
          line-height: 30px

          max-width: 160px

          color: map-get($COLORS, 'primary')

          @include screen('sm')
            max-width: 100%

            br
              display: none

        .about__subtitle
          font-size: 16px
          font-weight: 400
          line-height: 20px

          opacity: .5

          margin-left: 30px
          
          color: map-get($COLORS, 'secondary')

          @include screen('sm')
            display: none

      .about__description
        font-size: 14px
        font-weight: 400
        line-height: 21px

        margin-bottom: 30px

        color: map-get($COLORS, 'black')

      .about__stats
        display: flex
        flex-direction: row
        justify-content: space-between

        margin-bottom: 30px

        @include screen('lg')
          justify-content: flex-start

        .stat
          display: flex
          flex-direction: column

          max-width: min-content

          &:not(:last-child)
            @include screen('lg')
              margin-right: 40px

            @include screen('sm')
              margin-right: 25px

          &__count
            font-size: 35px
            font-weight: 400
            line-height: 43px
            white-space: nowrap

            margin-bottom: 20px

            color: map-get($COLORS, 'primary')

            @include screen('lg')
              font-size: 30px
              line-height: 35px

            @include screen('md')
              font-size: 25px
              line-height: 30px

            @include screen('sm')
              font-size: 20px
              line-height: 24px

          &__name
            font-size: 14px
            font-weight: 400
            line-height: 21px

            color: map-get($COLORS, 'black')

            @include screen('md')
              font-size: 12px
              line-height: 18px

      .button
        width: 100%
        max-width: 230px

        @include screen('sm')
          max-width: 100%
