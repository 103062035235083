.section--all-products
  .section__heading
    justify-content: space-between

    .variants
      display: flex
      align-items: center
      flex-direction: row

      font-size: 12px
      font-weight: 500
      line-height: 14px

      margin-bottom: 7.5px

      color: map-get($COLORS, 'secondary')

      @include screen('xl')
        display: none

      > span
        margin-right: 12px

      .variant
        opacity: .5

        cursor: pointer

        transition-duration: .2s
        transition-property: color, opacity

        &:not(:last-child)
          margin-right: 10px

        &:hover
          opacity: 1

        &.active
          opacity: 1

          color: map-get($COLORS, 'primary')

        .icon
          width: 20px
          height: 20px

  .section__inner
    .brands
      padding: 20px 25px

      margin-bottom: 45px

      background-color: #F3F3F3

      &__title
        display: block

        font-size: 18px
        font-weight: 600
        line-height: 21px

        margin-bottom: 20px

        color: map-get($COLORS, 'secondary')

      &__list
        display: flex
        flex-direction: row
        justify-content: space-between

        width: 100%

        overflow-x: auto

        > img
          min-width: initial

          &:not(:last-child)
            margin-right: 25px

    .products
      display: grid
      grid-gap: 50px

      &--grid
        grid-template-columns: 1fr 1fr

        @include screen('xl')
          grid-template-columns: 1fr

      &--list
        grid-template-columns: 1fr