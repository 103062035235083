@import ./areas/details/style
@import ./areas/orders/style
  
.section--account
  padding-top: 0

  .section__heading
    align-items: center

    @include screen('xl')
      flex-direction: column
      align-items: flex-start

      .section__title
        margin-bottom: 20px

        @include screen('sm')
          font-size: 32px
          line-height: 40px

    .tabs
      display: flex
      flex-direction: row
      align-items: center

      margin-left: 50px

      @include screen('xl')
        margin-left: 0

      @include screen('sm')
        flex-direction: column

        width: 100%

      .tab
        position: relative

        cursor: pointer

        display: flex
        align-items: center
        justify-content: center

        font-size: 20px
        font-weight: 500
        line-height: 24px

        height: 80px
        max-width: 330px

        @include screen('md')
          font-size: 16px
          line-height: 20px

          height: 60px

        @include screen('sm')
          font-size: 14px
          line-height: 17px

          width: 100%
          max-width: 300px

        &.active
          font-weight: 600
          
          margin-right: 7px
          margin-bottom: 7px

          &:hover
            .tab__inner
              transform: translate(7px, 7px)

              background-color: map-get($COLORS, 'primary-dark')

          &:active
            .tab__inner
              transform: translate(3px, 3px)
              
              background-color: map-get($COLORS, 'primary')

          &::before
            display: block

          .tab__inner
            color: map-get($COLORS, 'white')
            background-color: map-get($COLORS, 'primary')

          .tab__text
            padding: 0

            border: none

        &__inner
          display: flex
          flex-direction: row
          align-items: center
          justify-content: center

          width: 100%
          height: 100%

          padding: 0 25px
          
          color: #959594

          transition-duration: .3s
          transition-property: color, transform, background-color

          @include screen('md')
            padding: 0 15px

          .icon
            width: 22px
            height: 22px

            margin-right: 15px

            @include screen('sm')
              width: 12px
              height: 12px

          .tab__text
            display: flex
            flex-direction: row
            align-items: flex-end

            white-space: nowrap
            
            padding-bottom: 5px

            border-bottom-width: 1px
            border-bottom-style: solid
            border-bottom-color: #959594

          .tab__hint
            font-size: 14px
            font-weight: 500
            line-height: 17px

            margin-left: 35px

        &::before
          display: none

          position: absolute

          content: ''

          width: 100%
          height: 100%

          right: -7px
          bottom: -7px

          border-width: 1px
          border-style: solid
          border-color: map-get($COLORS, 'primary')

        &:not(:last-child)
          margin-right: 30px

          @include screen('md')
            margin-right: 15px

          @include screen('sm')
            margin-right: 0
            margin-bottom: 20px