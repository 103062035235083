.input
  position: relative

  display: flex
  align-items: center
  flex-direction: row

  padding: 15px 25px

  background-color: #FAFAFA
  color: map-get($COLORS, 'secondary')

  label.error
    position: absolute

    top: 40px
    left: 60px

    z-index: 5

    padding: 15px

    font-size: 12px
    font-weight: 400
    line-height: 14px
    text-align: center

    max-width: 180px

    color: map-get($COLORS, 'secondary') !important
    background-color: map-get($COLORS, 'white')
    box-shadow: 0px 15px 45px rgba(map-get($COLORS, 'black'), .1)

  &__icon
    order: 1

    opacity: .5

    width: 25px
    height: 25px

    margin-right: 20px

    transition-duration: .2s
    transition-property: opacity, color

  &__toggle
    position: absolute

    cursor: pointer

    top: 50%
    right: 20px

    transform: translateY(-50%)

    .icon
      width: 20px
      height: 20px

      @include screen('sm')
        width: 14px
        height: 14px

  &__status
    display: none
    align-items: center
    justify-content: center

    position: absolute

    right: 0
    bottom: 30px

    .icon
      width: 12px
      height: 12px

    &--success
      color: map-get($COLORS, 'primary')

    &--fail
      color: map-get($COLORS, 'fail')

  input
    flex: 1
    order: 2

    padding: 0

    opacity: .5

    border: none

    outline: none

    font-size: 14px
    font-weight: 400
    line-height: 17px

    background: none

    transition-duration: .2s
    transition-property: opacity, color

    &::placeholder
      color: map-get($COLORS, 'secondary')

      transition-duration: .2s
      transition-property: color

    &:focus
      color: map-get($COLORS, 'primary')

      opacity: 1

      & ~ *
        opacity: 1

        color: map-get($COLORS, 'primary')

      &::placeholder
        color: map-get($COLORS, 'primary')

      & ~ .input__outline
        background-color: map-get($COLORS, 'primary')

    &.error
      color: map-get($COLORS, 'fail')

      & ~ *
        color: map-get($COLORS, 'fail')

      & ~ .input__outline
        background-color: map-get($COLORS, 'fail')

      & ~ .input__status--fail
        display: flex
    
    &.valid
      & ~ .input__status--success
        display: flex
  
  &--outline
    padding-left: 0
    padding-bottom: 20px

    background-color: transparent

    .input__outline
      position: absolute

      left: 0
      right: 0
      bottom: 0

      height: 1px

      opacity: .2

      transition-duration: .2s
      transition-property: opacity, background-color

      background-color: map-get($COLORS, 'secondary')