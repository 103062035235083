.paginator
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between

  margin-top: 40px
  padding-top: 45px
  
  border-top-width: 2px
  border-top-style: solid
  border-top-color: rgba(map-get($COLORS, 'black'), .1)

  @include screen('sm')
    flex-direction: column
    align-items: flex-start

  &__info
    opacity: .5

    font-size: 14px
    font-weight: 400
    line-height: 17px

    color: map-get($COLORS, 'secondary')

    @include screen('sm')
      margin-bottom: 10px
  
  &__links
    display: flex
    flex-direction: row
    align-items: center

    .paginator__item
      font-size: 14px
      font-weight: 500
      line-height: 17px

      margin: 0 15px

      color: map-get($COLORS, 'secondary')

      &--dots
        margin: 0px

      &.active
        color: map-get($COLORS, 'primary')