.test-wrapper
  display: flex
  align-items: center
  justify-content: center

  width: 100vw
  height: 100vh

  padding: 0 20px

  @include screen('sm')
    padding: 0
    
    align-items: flex-start
  
.test
  position: relative

  overflow: hidden

  width: 100%
  max-width: 1000px

  padding: 10px

  box-shadow: 0px 4px 45px rgba(map-get($COLORS, 'black'), .1)

  &.final
    .test__inner
      padding: 20px

      height: 550px

      @include screen('sm')
        padding: 10px

    .test__steps,
    .test__header,
    .test__content,
    .test__navigation
      display: none

    .final
      display: flex

  &__inner
    position: relative

    z-index: 1

    height: 550px

    display: flex
    flex-direction: column
    justify-content: space-between

    border-width: 1px
    border-style: solid
    border-color: rgba(#2B2A29, .2)

    padding: 60px 20px 60px 350px

    @include screen('lg')
      height: 650px

      padding: 200px 20px 60px

    @include screen('sm')
      height: 600px

      padding: 60px 20px 30px

      justify-content: flex-start

    .test__progress
      display: block

      opacity: .5

      font-size: 14px
      font-weight: 400
      line-height: 17px

      margin-bottom: 20px

      color: map-get($COLORS, 'secondary')

    .test__question
      font-size: 25px
      font-weight: 400
      line-height: 30px

      max-width: 380px

      margin-bottom: 40px
      
      color: map-get($COLORS, 'secondary')

      @include screen('sm')
        font-size: 20px
        line-height: 24px

        margin-bottom: 20px

    .test__content
      position: relative

      margin-bottom: 50px

      @include screen('sm')
        margin-bottom: 25px

      .variants
        display: flex

        &--vertical
          flex-direction: column
        
        &--horizontal
          flex-direction: row

          @include screen('sm')
            flex-direction: column

          .variant
            display: flex
            align-items: center
            flex-direction: column

            color: map-get($COLORS, 'secondary')

            @include screen('sm')
              align-items: flex-start

            > span
              font-size: 14px
              font-weight: 400
              line-height: 17px

              margin-bottom: 10px

            .input
              padding: 10px

              border-width: 1px
              border-style: solid
              border-color: #F3F3F3

              background-color: transparent

            input
              width: 100%

              font-size: 14px
              font-weight: 400
              line-height: 17px

            &:not(:last-child)
              margin-right: 35px

          .image-radio
            &:not(:last-child)
              margin-right: 25px

        .variant
          &--input
            @include screen('sm')
              margin-bottom: 10px

            .input
              max-width: 90px

              @include screen('sm')
                max-width: 150px

              input
                opacity: 1

                padding-right: 15px

              label.error
                display: none !important

              &__status
                top: 50%
                right: 5px
                bottom: auto

                transform: translateY(-50%)

                .icon
                  width: 8px
                  height: 8px

          &--radio
            display: flex
            flex-direction: row
            align-items: center

            .radio
              margin-right: 10px

            > label
              cursor: pointer

              font-size: 14px
              font-weight: 500
              line-height: 17px

            &:not(:last-child)
              margin-bottom: 25px

    .test__navigation
      display: flex
      flex-direction: row
      align-items: center

      @include screen('sm')
        flex-direction: column
        align-items: flex-start

        margin-top: auto

      .back
        opacity: .5

        cursor: pointer

        font-size: 14px
        font-weight: 400
        line-height: 17px
        text-decoration: underline

        margin-left: 30px

        transition-duration: .2s
        transition-property: opacity, color

        @include screen('sm')
          margin-top: 20px

        &:hover
          opacity: 1

          color: map-get($COLORS, 'primary')

    .final
      display: none
      flex-direction: row

      margin-top: 50px

      @include screen('md')
        flex-direction: column

        margin-top: 0

      &.show
        display: flex

      > img
        position: absolute

        right: 65px
        bottom: -10px

        z-index: 3

        width: 325px 

        @include screen('lg')
          display: none

      .discount
        position: absolute

        bottom: 130px
        right: -160px

        z-index: 2

        line-height: 1
        font-size: 200px
        font-weight: 600

        transform: rotate(-90deg)

        color: map-get($COLORS, 'primary')
        background-color: map-get($COLORS, 'white')

        @include screen('lg')
          right: -120px

          font-size: 150px

        @include screen('md')
          display: none

      &__result
        display: flex
        flex-direction: column

        @include screen('md')
          flex-direction: row

          margin-bottom: 40px

        .result__title
          font-size: 30px
          font-weight: 600
          line-height: 36px

          margin-top: 70px
          margin-bottom: 5px

          color: map-get($COLORS, 'primary')

          @include screen('md')
            margin-top: 0

            font-size: 24px
            line-height: 30px

          @include screen('sm')
            font-size: 20px
            line-height: 25px

        .result__subtitle
          font-size: 16px
          font-weight: 500
          line-height: 24px

          max-width: 150px

          @include screen('sm')
            font-size: 12px
            line-height: 14px

            max-width: 100px

        .result__answers
          display: flex
          flex-direction: column

          margin-top: 50px

          @include screen('md')
            margin-top: 0

            margin-left: 30px

          @include screen('sm')
            margin-left: 10px

          > span
            font-size: 16px
            font-weight: 400
            line-height: 24px

            margin-bottom: 20px

            @include screen('md')
              display: none

          .answers
            display: flex
            flex-direction: column

            .answer
              display: flex
              flex-direction: row
              align-items: center

              &:not(:last-child)
                margin-bottom: 15px

              &__index
                font-size: 20px
                font-weight: 500
                line-height: 24px

                margin-right: 10px

                color: map-get($COLORS, 'primary')

                @include screen('sm')
                  font-size: 16px
                  line-height: 20px

              &__value
                font-size: 14px
                font-weight: 500
                line-height: 24px

                @include screen('sm')
                  font-size: 12px
                  line-height: 16px

      &__contact
        display: flex
        align-items: center
        flex-direction: column

        .final__title
          font-size: 25px
          font-weight: 400
          line-height: 30px

          margin-bottom: 80px

          @include screen('md')
            font-size: 20px
            line-height: 25px

            margin-bottom: 40px

          @include screen('sm')
            font-size: 14px
            line-height: 18px

            margin-bottom: 20px
        
        .final__form
          display: flex
          justify-content: center

          width: 100%

          form
            display: flex
            flex-direction: column

            width: 100%
            max-width: 300px

            .input
              &:not(:last-of-type)
                margin-bottom: 10px

            .button
              margin-top: 25px
              margin-bottom: 20px

              @include screen('sm')
                margin-top: 12.5px

            .agreement
              display: flex
              flex-direction: row
              align-items: center

              margin: 0 auto

              opacity: .75

              .checkbox
                margin-right: 10px

              label
                cursor: pointer

                max-width: 195px

                font-size: 10px
                font-weight: 400
                line-height: 12px

                color: map-get($COLORS, 'secondary')

                a
                  text-decoration: underline

  &__steps
    position: absolute

    display: flex
    flex-direction: column
    justify-content: space-around
    
    top: 0
    left: 0
    bottom: 0
    z-index: 2

    padding: 0 40px

    background-color: map-get($COLORS, 'white')
    box-shadow: 15px 0px 45px rgba(map-get($COLORS, 'black'), .1)

    @include screen('lg')
      top: 0
      left: 0
      right: 0
      bottom: auto

      padding: 30px 0

      flex-direction: row

    @include screen('md')
      padding: 15px 0
      
      .step:not(.active)
        display: none

    .step
      opacity: .5

      display: flex
      align-items: center
      flex-direction: column

      max-width: 200px

      transition-duration: .2s
      transition-property: opacity

      color: map-get($COLORS, 'secondary')

      @include screen('sm')
        max-width: 100%

        flex-direction: row

      &.active
        opacity: 1

        .step__index
          color: map-get($COLORS, 'primary')
      
      &__index
        font-size: 35px
        font-weight: 400
        line-height: 42px

        margin-bottom: 20px

        transition-duration: .2s
        transition-property: color

        @include screen('md')
          font-size: 30px
          line-height: 35px

          margin-bottom: 10px

        @include screen('sm')
          font-size: 25px
          line-height: 30px

          margin-bottom: 0
          margin-right: 10px

      &__name
        font-size: 16px
        font-weight: 400
        line-height: 22px
        text-align: center

        @include screen('sm')
          font-size: 14px
          line-height: 18px

  .image-radio
    cursor: pointer

    display: flex
    flex-direction: column

    max-width: 200px

    transition-duration: .2s
    transition-property: color

    color: map-get($COLORS, 'secondary')

    &.active
      color: map-get($COLORS, 'primary')

      .image
        outline-width: 3px
        outline-style: solid
        outline-color: map-get($COLORS, 'primary')

    .image
      width: 100%
      height: 120px

      overflow: hidden

      img
        width: 100%
        height: 100%

        object-fit: cover
        object-position: center
    
    .inner
      display: flex
      align-items: center
      flex-direction: column

      font-size: 14px
      font-weight: 500
      line-height: 17px
      text-align: center

      .trigger
        display: flex
        align-items: center
        justify-content: center

        width: 20px
        height: 20px

        margin-top: -10px

        border-radius: 50%

        background-color: map-get($COLORS, 'white')

      span
        margin-top: 5px