.modal--call
  form
    display: flex
    align-items: center
    flex-direction: column

    width: 100%
    max-width: 300px

    .input
      width: 100%

      &:not(:last-of-type)
        margin-bottom: 15px

    .button
      width: 100%
      max-width: 100%

      margin-top: 25px
      margin-bottom: 20px

    .agreement
      display: flex
      flex-direction: row
      align-items: center

      margin: 0 auto

      opacity: .75

      .checkbox
        margin-right: 10px

      label
        cursor: pointer

        max-width: 195px

        font-size: 10px
        font-weight: 400
        line-height: 12px

        color: map-get($COLORS, 'secondary')

        a
          text-decoration: underline