.details
  form
    display: grid
    grid-gap: 30px
    grid-template-columns: 1fr

    .detail
      display: grid
      grid-gap: 30px
      grid-template-columns: 200px 1fr

      @include screen('md')
        grid-gap: 20px
        grid-template-columns: 1fr
        
        &--empty
          .detail__key
            display: none

      @include screen('sm')
        grid-gap: 15px

      &__key
        display: flex
        flex-direction: row
        align-items: center

        font-size: 18px
        font-weight: 500
        line-height: 22px

      &__value
        display: flex
        flex-direction: row
        align-items: center

        @include screen('sm')
          flex-direction: column
          align-items: flex-start

        .radio-wrapper
          display: flex
          flex-direction: row
          align-items: center

          font-size: 18px
          font-weight: 500
          line-height: 22px

          color: map-get($COLORS, 'secondary')

          &:not(:last-of-type)
            margin-right: 50px

            @include screen('lg')
              margin-right: 20px

            @include screen('sm')
              margin-right: 0
              margin-bottom: 20px

          .radio
            margin-right: 25px

            @include screen('lg')
              margin-right: 20px

          label
            cursor: pointer

        .input
          width: 100%

          height: 60px

          padding: 10px 30px

          background-color: transparent

          border-width: 1px
          border-style: solid
          border-color: rgba(map-get($COLORS, 'secondary'), .2)

          input
            font-size: 18px
            font-weight: 500
            line-height: 22px

            opacity: 1

            height: 100%

        .mailing
          display: flex
          flex-direction: row
          align-items: center

          .checkbox
            margin-right: 15px

          label
            cursor: pointer

            font-size: 14px
            font-weight: 600
            line-height: 17px

            > a
              color: map-get($COLORS, 'primary')