h1, h2, h3,
h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

a,
input,
button {
  font-family: "Montserrat";
}

*,
*::before,
*::after {
  box-sizing: border-box;
  min-width: 0;
  min-height: 0;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  font-style: normal;
  font-family: "Montserrat", sans-serif;
  color: #000000;
  background-color: #FFFFFF;
}
body.disabled {
  overflow: hidden !important;
}

.icons {
  display: none;
}

.section {
  padding: 75px 0;
}
@media screen and (max-width: 992px) {
  .section {
    padding: 50px 0;
  }
}
@media screen and (max-width: 576px) {
  .section {
    padding: 30px 0;
  }
}
.section .section__heading {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 45px;
  color: #2B2A29;
}
@media screen and (max-width: 768px) {
  .section .section__heading > *:not(.section__title) {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .section .section__heading {
    margin-bottom: 30px;
  }
}
.section .section__heading .section__title {
  font-size: 45px;
  font-weight: 500;
  line-height: 55px;
}
@media screen and (max-width: 576px) {
  .section .section__heading .section__title {
    font-size: 40px;
    line-height: 48px;
  }
}
.section .section__heading .section__title > a {
  text-decoration: underline;
  color: #EC692C;
}
.section .section__heading .section__heading-hint {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 30px;
  margin-bottom: 8px;
}
.section .section__heading .section__heading-hint--primary {
  color: #EC692C;
}

.slider__items {
  display: flex;
  cursor: grab;
}

.icon {
  display: inline;
  width: 25px;
  height: 25px;
}

.container {
  width: 100%;
  max-width: 1210px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}
@media screen and (max-width: 576px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1180px;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.75px;
  padding: 0;
  cursor: pointer;
  height: 60px;
  max-width: 300px;
  border: none;
  background: none;
}
.button .icon {
  width: 22px;
  height: 22px;
}
@media screen and (max-width: 576px) {
  .button .icon {
    width: 12px;
    height: 12px;
  }
}
.button .icon--before {
  margin-right: 15px;
}
.button .icon--after {
  margin-left: 15px;
}
.button .icon--arrow-right, .button .icon--arrow-left {
  width: 8px;
}
.button .icon--arrow-alt-right, .button .icon--arrow-alt-left {
  width: 40px;
  height: 40px;
}
.button--uppercase {
  text-transform: uppercase;
}
.button--transform {
  margin-right: 7px;
  margin-bottom: 7px;
}
.button--transform::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  right: -7px;
  bottom: -7px;
  border-width: 1px;
  border-style: solid;
  border-color: #EC692C;
}
.button--transform:hover .button__inner {
  transform: translate(7px, 7px);
}
.button--transform:active .button__inner {
  transform: translate(3px, 3px);
}
.button--primary .button__inner {
  color: #FFFFFF;
  background-color: #EC692C;
}
.button--primary:hover .button__inner {
  background-color: #EC5A2C;
}
.button--primary:active .button__inner {
  background-color: #EC692C;
}
.button--gray {
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.button--gray::before {
  border-color: #FAFAFA;
}
.button--gray .button__inner {
  background-color: #FAFAFA;
  color: #2B2A29;
}
.button--gray:hover .button__inner {
  color: #FFFFFF;
  background-color: #EC5A2C;
}
.button--gray:active .button__inner {
  color: #FFFFFF;
  background-color: #EC692C;
}
.button--disabled::before {
  border-color: #F3F3F3;
}
.button--disabled .button__inner {
  background-color: #F3F3F3;
  color: rgba(43, 42, 41, 0.5);
}
.button--disabled .button__inner:hover {
  color: auto;
  background-color: #F3F3F3;
}
.button--disabled .button__inner:active {
  color: auto;
  background-color: #F3F3F3;
}
.button--outline {
  font-weight: 500;
  border-width: 1px;
  border-style: solid;
  border-color: #959494;
}
.button--outline:hover .button__inner {
  background-color: #959494;
  color: #FFFFFF;
}
.button--outline:active .button__inner {
  background-color: #7c7a7a;
}
.button--outline .button__inner {
  color: #959494;
  background-color: transparent;
}
.button--space-between .button__inner {
  justify-content: space-between;
}
.button__inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
  padding: 0 25px;
  transition-duration: 0.3s;
  transition-property: color, transform, background-color;
}

.input {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 15px 25px;
  background-color: #FAFAFA;
  color: #2B2A29;
}
.input label.error {
  position: absolute;
  top: 40px;
  left: 60px;
  z-index: 5;
  padding: 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  max-width: 180px;
  color: #2B2A29 !important;
  background-color: #FFFFFF;
  box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.1);
}
.input__icon {
  order: 1;
  opacity: 0.5;
  width: 25px;
  height: 25px;
  margin-right: 20px;
  transition-duration: 0.2s;
  transition-property: opacity, color;
}
.input__toggle {
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.input__toggle .icon {
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 576px) {
  .input__toggle .icon {
    width: 14px;
    height: 14px;
  }
}
.input__status {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 30px;
}
.input__status .icon {
  width: 12px;
  height: 12px;
}
.input__status--success {
  color: #EC692C;
}
.input__status--fail {
  color: #DE0303;
}
.input input {
  flex: 1;
  order: 2;
  padding: 0;
  opacity: 0.5;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  background: none;
  transition-duration: 0.2s;
  transition-property: opacity, color;
}
.input input::placeholder {
  color: #2B2A29;
  transition-duration: 0.2s;
  transition-property: color;
}
.input input:focus {
  color: #EC692C;
  opacity: 1;
}
.input input:focus ~ * {
  opacity: 1;
  color: #EC692C;
}
.input input:focus::placeholder {
  color: #EC692C;
}
.input input:focus ~ .input__outline {
  background-color: #EC692C;
}
.input input.error {
  color: #DE0303;
}
.input input.error ~ * {
  color: #DE0303;
}
.input input.error ~ .input__outline {
  background-color: #DE0303;
}
.input input.error ~ .input__status--fail {
  display: flex;
}
.input input.valid ~ .input__status--success {
  display: flex;
}
.input--outline {
  padding-left: 0;
  padding-bottom: 20px;
  background-color: transparent;
}
.input--outline .input__outline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  opacity: 0.2;
  transition-duration: 0.2s;
  transition-property: opacity, background-color;
  background-color: #2B2A29;
}

.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 992px) {
  .menu {
    display: none;
  }
}
.menu .menu__item {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  opacity: 0.5;
  cursor: pointer;
  transition-duration: 0.2s;
  transition-property: opacity, color;
}
@media screen and (max-width: 1200px) {
  .menu .menu__item {
    font-size: 14px;
  }
}
.menu .menu__item:hover {
  opacity: 1;
  color: #EC692C;
}
.menu .menu__item:not(:last-child) {
  margin-right: 22px;
}

.hero {
  display: flex;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 1200px) {
  .hero--home {
    height: 400px !important;
  }
}
@media screen and (max-width: 992px) {
  .hero--home {
    height: 350px !important;
  }
}
@media screen and (max-width: 768px) {
  .hero--home {
    height: 300px !important;
  }
}
@media screen and (max-width: 576px) {
  .hero--home {
    height: 270px !important;
  }
}
@media screen and (max-width: 768px) {
  .hero--catalog {
    height: 280px !important;
  }
}
@media screen and (max-width: 576px) {
  .hero--catalog {
    display: none;
  }
}
.hero .container {
  display: flex;
  align-items: flex-start;
  height: 100%;
}
.hero .container .hero__inner {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  color: #FFFFFF;
}
@media screen and (max-width: 992px) {
  .hero .container .hero__inner {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    margin-top: 0;
  }
}
.hero .container .hero__inner .hero__title {
  font-size: 65px;
  font-weight: 500;
  line-height: 80px;
  max-width: 550px;
}
@media screen and (max-width: 992px) {
  .hero .container .hero__inner .hero__title {
    margin-bottom: 20px;
    font-size: 50px;
    line-height: 60px;
  }
}
.hero .container .hero__inner .hero__description {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  max-width: 360px;
}

.category {
  position: relative;
  display: flex;
  overflow: hidden;
  will-change: transform;
  transition-duration: 0.3s;
  transition-property: transform;
}
@media screen and (min-width: 992px) {
  .category--soh:hover {
    transform: scale(1.05);
  }
}
.category img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.category__info {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  bottom: 35px;
  width: 100%;
  max-width: 290px;
  padding: 25px 20px;
  transform: translateX(-50%);
  transition-duration: 0.2s;
  transition-property: background-color;
  background-color: #FFFFFF;
}
@media screen and (max-width: 1200px) {
  .category__info {
    max-width: 250px;
  }
}
@media screen and (max-width: 576px) {
  .category__info {
    left: 0;
    bottom: 0;
    max-width: 100%;
    transform: translateX(0);
    color: #FFFFFF;
    background-color: #EC692C;
  }
}
.category__info:hover {
  background-color: #EC692C;
}
.category__info:hover .category__name {
  color: #FFFFFF;
}
.category__info:hover .category__redirect {
  color: #FFFFFF;
}
.category__info .category__name {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
  transition-duration: 0.2s;
  transition-property: color;
  color: #2B2A29;
}
@media screen and (max-width: 768px) {
  .category__info .category__name {
    font-size: 20px;
    line-height: 25px;
  }
}
@media screen and (max-width: 576px) {
  .category__info .category__name {
    color: #FFFFFF;
  }
}
.category__info .category__redirect {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
  transition-duration: 0.2s;
  transition-property: color;
  color: #EC692C;
}
@media screen and (max-width: 576px) {
  .category__info .category__redirect {
    color: #FFFFFF;
  }
}
.category__info .category__redirect .icon {
  width: 10px;
  margin-left: 13px;
}

.product {
  will-change: transform;
  transition-duration: 0.3s;
  transition-property: transform;
}
@media screen and (min-width: 992px) {
  .product--soh:hover {
    transform: scale(1.05);
  }
}
.product__thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 320px;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: #F3F3F3;
}
.product__thumbnail img {
  width: initial;
  height: initial;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
}
.product__inner {
  display: flex;
  flex-direction: column;
}
.product__category {
  font-size: 16px;
  line-height: 500;
  line-height: 20px;
  opacity: 0.5;
  margin-bottom: 12px;
  color: #2B2A29;
}
.product__name {
  font-size: 25px;
  font-weight: 500;
  line-height: 35px;
  margin-bottom: 20px;
  color: #2B2A29;
}
@media screen and (max-width: 768px) {
  .product__name {
    font-size: 20px;
    line-height: 25px;
  }
}
.product__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .product__footer {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 576px) {
  .product__footer {
    flex-direction: row;
    align-items: center;
  }
}
.product__price {
  font-size: 25px;
  font-weight: 600;
  line-height: 35px;
  color: #2B2A29;
}
@media screen and (max-width: 768px) {
  .product__price {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 576px) {
  .product__price {
    margin-bottom: 0;
  }
}
.product .button {
  width: 100%;
  max-width: 225px;
}
@media screen and (max-width: 1200px) {
  .product .button {
    max-width: 175px;
  }
}
@media screen and (max-width: 576px) {
  .product .button {
    max-width: 155px;
  }
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checkbox.active label {
  border-color: #EC692C !important;
}
.checkbox.active .icon {
  opacity: 1 !important;
  color: #EC692C !important;
}
.checkbox__virtual {
  position: relative;
}
.checkbox__virtual input[type=checkbox] {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  margin: 0;
  padding: 0;
  width: 14px;
  height: 14px;
  opacity: 0;
}
.checkbox__virtual input[type=checkbox]:checked + label > .check {
  display: flex;
}
.checkbox__virtual label {
  display: block;
  width: 14px;
  height: 14px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(43, 42, 41, 0.2);
  z-index: -2;
}
.checkbox__virtual label .check {
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.checkbox__virtual label .check .icon {
  width: 12px;
  height: 8px;
  opacity: 0.5;
  color: #2B2A29;
}

.news {
  display: flex;
  flex-direction: column;
  will-change: transform;
  transition-duration: 0.3s;
  transition-property: transform;
}
@media screen and (min-width: 992px) {
  .news--soh:hover {
    transform: scale(1.05);
  }
}
.news--large {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 768px) {
  .news--large {
    flex-direction: column;
  }
}
.news--large .news__thumbnail {
  height: 260px;
  margin-right: 30px;
}
.news--large .news__date {
  margin-bottom: 10px;
}
.news__thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 210px;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: #F3F3F3;
}
.news__thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.news__date {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 15px;
  color: #EC5A2C;
}
.news__name {
  display: block;
  font-size: 25px;
  font-weight: 500;
  line-height: 35px;
  margin-bottom: 20px;
  color: #2B2A29;
}
@media screen and (max-width: 768px) {
  .news__name {
    font-size: 20px;
    line-height: 25px;
  }
}
.news__excerpt {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 25px;
  color: #2B2A29;
}
.news .button {
  width: 100%;
  max-width: 225px;
}

.section--breadcrumbs {
  padding: 0;
  margin: 0 0 30px 0;
}
.section--breadcrumbs .breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  color: #959494;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}
.section--breadcrumbs .breadcrumbs .item.active {
  font-weight: 500;
  color: #EC692C;
}
.section--breadcrumbs .breadcrumbs .separator {
  margin: 0 3px;
}

.paginator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  padding-top: 45px;
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 576px) {
  .paginator {
    flex-direction: column;
    align-items: flex-start;
  }
}
.paginator__info {
  opacity: 0.5;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #2B2A29;
}
@media screen and (max-width: 576px) {
  .paginator__info {
    margin-bottom: 10px;
  }
}
.paginator__links {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.paginator__links .paginator__item {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin: 0 15px;
  color: #2B2A29;
}
.paginator__links .paginator__item--dots {
  margin: 0px;
}
.paginator__links .paginator__item.active {
  color: #EC692C;
}

.radio {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.radio.active label {
  opacity: 1;
  display: flex;
}
.radio__virtual {
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #F3F3F3;
}
.radio__virtual input[type=radio] {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  margin: 0;
  padding: 0;
  width: 14px;
  height: 14px;
  opacity: 0;
}
.radio__virtual input[type=radio]:checked + label {
  display: flex;
}
.radio__virtual label {
  position: relative;
  display: none;
  z-index: 1;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.radio__virtual label::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: #EC692C;
}
.radio--big .radio__virtual,
.radio--big input[type=radio],
.radio--big label {
  width: 28px;
  height: 28px;
}
.radio--big label::after {
  width: 20px;
  height: 20px;
}

.sort {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  white-space: nowrap;
  width: 100%;
  overflow-x: auto;
  color: #2B2A29;
}
.sort > span {
  margin-right: 10px;
  min-width: max-content;
}
.sort__variants {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sort__variants .sort__variant {
  opacity: 0.5;
  cursor: pointer;
  min-width: max-content;
  transition-duration: 0.2s;
  transition-property: color, opacity;
}
.sort__variants .sort__variant:not(:last-child) {
  margin-right: 15px;
}
.sort__variants .sort__variant:hover {
  opacity: 1;
}
.sort__variants .sort__variant.active {
  opacity: 1;
  color: #EC692C;
}

.counter {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2B2A29;
}
.counter__count {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  margin: 0 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  background-color: #FAFAFA;
}
.counter__control {
  cursor: pointer;
}
.counter__control:not(.disabled) {
  color: #EC692C;
}
.counter__control .icon {
  width: 7px;
  height: 12px;
}

.header {
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  color: #2B2A29;
}
@media screen and (max-width: 768px) {
  .header {
    padding: 30px 0;
  }
}
.header__top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .header__top {
    display: none;
  }
}
.header__top .header__location {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
}
.header__top .header__location .icon {
  width: 22px;
  height: 22px;
  margin-right: 7px;
}
.header__top .header__call {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.header__top .header__call-order {
  opacity: 0.5;
  cursor: pointer;
  transition-duration: 0.2s;
  transition-property: opacity, color;
}
.header__top .header__call-order:hover {
  opacity: 1;
  color: #EC692C;
}
.header__top .header__call-divider {
  width: 1px;
  height: 16px;
  opacity: 0.5;
  margin: 0 12px;
  background-color: #2B2A29;
}
.header__top .header__call-phone {
  position: relative;
}
.header__top .header__call-phone span:nth-child(1) {
  position: absolute;
  right: 0;
  top: -4px;
  transform: translateY(-100%);
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  height: min-content;
  opacity: 0.5;
  color: #000000;
}
.header__top .header__call-phone-number {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
}
.header__top .header__call-phone-number .header__call-status {
  width: 6px;
  height: 6px;
  margin-right: 10px;
  border-radius: 50%;
}
.header__top .header__call-phone-number .header__call-status--fail {
  background-color: #DE0303;
}
.header__top .header__call-phone-number .header__call-status--success {
  background-color: #30CC37;
}
.header__middle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
@media screen and (max-width: 1200px) {
  .header__middle {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 768px) {
  .header__middle {
    justify-content: flex-start;
  }
}
.header__middle .header__logo {
  display: flex;
  min-width: min-content;
}
@media screen and (max-width: 1200px) {
  .header__middle .header__logo img {
    width: 180px;
  }
}
@media screen and (max-width: 576px) {
  .header__middle .header__logo img {
    width: 130px;
  }
}
.header__middle .header__search {
  width: 100%;
  max-width: 525px;
  margin: 0 20px;
}
@media screen and (max-width: 768px) {
  .header__middle .header__search {
    display: none;
  }
}
.header__middle .header__search .input {
  height: 50px;
}
.header__middle .header__login {
  width: 100%;
  max-width: 165px;
  margin-right: 10px;
}
@media screen and (max-width: 768px) {
  .header__middle .header__login {
    display: none;
  }
}
.header__middle .header__login .button__inner {
  height: 50px;
}
.header__middle .header__menu-mobile {
  margin-left: auto;
}
.header__middle .header__menu-mobile,
.header__middle .header__search-mobile {
  display: none;
  cursor: pointer;
  margin-right: 25px;
  transition-duration: 0.2s;
  transition-property: color;
}
@media screen and (max-width: 768px) {
  .header__middle .header__menu-mobile,
.header__middle .header__search-mobile {
    display: block;
  }
}
.header__middle .header__menu-mobile:hover,
.header__middle .header__search-mobile:hover {
  color: #EC692C;
}
.header__middle .header__menu-mobile .icon,
.header__middle .header__search-mobile .icon {
  width: 24px;
  height: 24px;
}
.header__middle .header__cart {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  min-width: max-content;
  color: #2B2A29;
  transition-duration: 0.2s;
  transition-property: color;
}
.header__middle .header__cart:hover {
  color: #EC692C;
}
@media screen and (max-width: 768px) {
  .header__middle .header__cart span {
    display: none;
  }
}
.header__middle .header__cart-icon {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 15px;
}
@media screen and (max-width: 768px) {
  .header__middle .header__cart-icon {
    margin-right: 0;
  }
}
.header__middle .header__cart-icon .header__cart-count {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 8px;
  line-height: 1;
  font-weight: 500;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: #FFFFFF;
  background-color: #EC5A2C;
}
.header__bottom .header__categories {
  display: flex;
  flex-direction: column;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .header__bottom .header__categories {
    display: none;
  }
}
.header__bottom .header__categories > * {
  z-index: 11;
}
.header__bottom .header__categories.active::before {
  content: "";
  position: absolute;
  z-index: 10;
  top: -20px;
  left: -40px;
  min-height: 840px;
  width: calc(100% + 80px);
  background-color: #FFFFFF;
  box-shadow: 0px 12px 45px rgba(0, 0, 0, 0.1);
}
.header__bottom .header__categories.active .row {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #EC5A2C;
}
.header__bottom .header__categories.active .dropdown {
  display: flex;
}
.header__bottom .header__categories .row {
  justify-content: space-between;
}
.header__bottom .header__categories .header__category {
  flex: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
  height: 70px;
  padding: 0 22px;
  transition-duration: 0.2s;
  transition-property: color, background-color;
  color: #2B2A29;
}
.header__bottom .header__categories .header__category:hover, .header__bottom .header__categories .header__category.active {
  color: #FFFFFF;
  background-color: #EC692C;
}
.header__bottom .header__categories .header__category:hover .category__icon, .header__bottom .header__categories .header__category.active .category__icon {
  color: #FFFFFF;
}
.header__bottom .header__categories .header__category .category__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-right: 12px;
  border-radius: 50%;
  color: #FFFFFF;
  background-color: #EC692C;
  transition-duration: 0.2s;
  transition-property: color;
}
.header__bottom .header__categories .header__category .category__icon::before {
  content: "";
  position: absolute;
  right: -3px;
  bottom: -3px;
  width: 100%;
  height: 100%;
  border-width: 1px;
  border-radius: 50%;
  border-style: solid;
  border-color: #EC692C;
}
.header__bottom .header__categories .header__category .category__icon .icon {
  width: 37px;
  height: 37px;
}
.header__bottom .header__categories .dropdown {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 100px;
  width: 100%;
}
.header__bottom .header__categories .dropdown__column {
  display: flex;
  flex-direction: column;
  max-width: max-content;
}
.header__bottom .header__categories .dropdown__column .block {
  display: flex;
  flex-direction: column;
}
.header__bottom .header__categories .dropdown__column .block:not(:last-child) {
  margin-bottom: 50px;
}
.header__bottom .header__categories .dropdown__column .block__title {
  display: block;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 25px;
  color: #2B2A29;
}
.header__bottom .header__categories .dropdown__column .block__items .block__item:not(:last-child) {
  margin-bottom: 15px;
}
.header__bottom .header__categories .dropdown__column .block__items .block__item a {
  display: block;
  line-height: 1;
  font-size: 14px;
  font-weight: 400;
  color: #2B2A29;
  transition-duration: 0.2s;
  transition-property: color, transform;
}
.header__bottom .header__categories .dropdown__column .block__items .block__item a:hover {
  transform: translateX(5px);
  color: #EC5A2C;
}

.footer {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(43, 42, 41, 0.2);
  padding: 80px 0 40px 0;
  color: #2B2A29;
}
@media screen and (max-width: 768px) {
  .footer {
    padding: 50px 0;
  }
}
.footer__title {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 30px;
  color: #EC692C;
}
.footer__row {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, auto);
  margin: 0 auto;
}
@media screen and (max-width: 992px) {
  .footer__row {
    max-width: 600px;
    grid-gap: 50px;
    grid-template-columns: repeat(2, auto);
  }
}
@media screen and (max-width: 768px) {
  .footer__row {
    grid-gap: 30px;
  }
}
@media screen and (max-width: 576px) {
  .footer__row {
    max-width: 300px;
    grid-template-columns: repeat(1, auto);
  }
}
.footer__row .footer__column {
  display: flex;
}
.footer__row .footer__column-inner {
  display: flex;
  flex-direction: column;
}
.footer__row .footer__column--main .footer__logo {
  display: flex;
  margin-bottom: 30px;
}
.footer__row .footer__column--main .footer__call {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 30px;
  max-width: max-content;
}
.footer__row .footer__column--main .footer__call-order {
  opacity: 0.5;
  cursor: pointer;
  max-width: max-content;
  transition-duration: 0.2s;
  transition-property: opacity, color;
}
.footer__row .footer__column--main .footer__call-order:hover {
  opacity: 1;
  color: #EC692C;
}
.footer__row .footer__column--main .footer__call-phone-number {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  margin-bottom: 5px;
}
.footer__row .footer__column--main .footer__call-phone-number .footer__call-status {
  width: 6px;
  height: 6px;
  margin-right: 10px;
  border-radius: 50%;
}
.footer__row .footer__column--main .footer__call-phone-number .footer__call-status--fail {
  background-color: #DE0303;
}
.footer__row .footer__column--main .footer__call-phone-number .footer__call-status--success {
  background-color: #30CC37;
}
.footer__row .footer__column--main .footer__policy {
  opacity: 0.7;
  max-width: max-content;
  transition-duration: 0.2s;
  transition-property: color, opacity;
}
.footer__row .footer__column--main .footer__policy:hover {
  opacity: 1;
  color: #EC692C;
}
.footer__row .footer__column--navigation .footer__column-inner {
  display: flex;
  flex-direction: column;
  max-width: 260px;
}
.footer__row .footer__column--navigation .footer__column-inner .navigation__items {
  display: flex;
  flex-direction: column;
}
.footer__row .footer__column--navigation .footer__column-inner .navigation__items a {
  opacity: 0.7;
  transition-duration: 0.2s;
  transition-property: color, opacity;
}
.footer__row .footer__column--navigation .footer__column-inner .navigation__items a:not(:last-child) {
  margin-bottom: 20px;
}
.footer__row .footer__column--navigation .footer__column-inner .navigation__items a:hover {
  opacity: 1;
  color: #EC692C;
}
.footer__row .footer__column--media .footer__medias {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footer__row .footer__column--media .footer__medias .media {
  opacity: 0.7;
  cursor: pointer;
  transition-duration: 0.2s;
  transition-property: color, opacity;
  color: #2B2A29;
}
.footer__row .footer__column--media .footer__medias .media:hover {
  opacity: 1;
  color: #EC692C;
}
.footer__row .footer__column--media .footer__medias .media:not(:last-child) {
  margin-right: 25px;
}
.footer__row .footer__column--media .footer__medias .media .icon {
  width: 25px;
}
.footer__row .footer__column--media .footer__copyright {
  opacity: 0.7;
  margin-top: auto;
}
@media screen and (max-width: 576px) {
  .footer__row .footer__column--media .footer__copyright {
    margin-top: 35px;
  }
}

.section--intro {
  margin: 0 auto;
  max-width: 1350px;
  padding: 50px 0 0 0;
  transform: translateY(-100px);
  background-color: #FFFFFF;
}
.section--intro .intro__info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 80px;
}
@media screen and (max-width: 992px) {
  .section--intro .intro__info {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 768px) {
  .section--intro .intro__info {
    margin-bottom: 0;
    flex-direction: column;
  }
}
.section--intro .intro__info h1 {
  display: flex;
  flex-direction: column;
  font-size: 70px;
  font-weight: 500;
  line-height: 85px;
}
@media screen and (max-width: 1200px) {
  .section--intro .intro__info h1 {
    font-size: 60px;
    line-height: 75px;
  }
}
@media screen and (max-width: 992px) {
  .section--intro .intro__info h1 {
    font-size: 55px;
    line-height: 70px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .section--intro .intro__info h1 {
    font-size: 50px;
    line-height: 65px;
    text-align: center;
  }
}
@media screen and (max-width: 576px) {
  .section--intro .intro__info h1 {
    font-size: 45px;
    line-height: 60px;
    text-align: left;
  }
}
.section--intro .intro__info h1 span {
  color: #EC5A2C;
}
@media screen and (max-width: 992px) {
  .section--intro .intro__info .intro__content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
.section--intro .intro__info .intro__slider {
  position: relative;
  max-width: 240px;
  margin-bottom: 20px;
}
@media screen and (max-width: 576px) {
  .section--intro .intro__info .intro__slider {
    width: 100%;
    max-width: 300px;
  }
}
.section--intro .intro__info .intro__slider .slider__arrows {
  position: absolute;
  right: 0;
  top: 42px;
  display: flex;
  flex-direction: row;
}
.section--intro .intro__info .intro__slider .slider__arrows .slider__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 0 2.5px;
  transition-duration: 0.2s;
  transition-property: color;
}
.section--intro .intro__info .intro__slider .slider__arrows .slider__arrow[aria-disabled=false] {
  color: #EC5A2C;
}
.section--intro .intro__info .intro__slider .slider__arrows .slider__arrow .icon {
  width: 10px;
}
.section--intro .intro__info .intro__slider .slider__items {
  display: flex;
}
.section--intro .intro__info .intro__slider .slider__items .slide {
  display: flex;
  flex-direction: column;
}
.section--intro .intro__info .intro__slider .slider__items .slide__index {
  font-size: 65px;
  font-weight: 300;
  line-height: 80px;
  margin-bottom: 15px;
  color: #EC5A2C;
}
.section--intro .intro__info .intro__slider .slider__items .slide__description {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #2B2A29;
}
.section--intro .intro__info .intro__slider .button {
  margin-top: 25px;
}
.section--intro .intro__survey {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 992px) {
  .section--intro .intro__survey {
    flex-direction: column;
  }
}
.section--intro .intro__survey .survey {
  display: flex;
  flex-direction: column;
  margin-right: 85px;
}
@media screen and (max-width: 992px) {
  .section--intro .intro__survey .survey {
    margin-right: 0;
  }
}
.section--intro .intro__survey .survey__discount {
  font-size: 200px;
  font-weight: 600;
  line-height: 243px;
  color: #EC692C;
}
@media screen and (max-width: 992px) {
  .section--intro .intro__survey .survey__discount {
    font-size: 180px;
    line-height: 210px;
  }
}
@media screen and (max-width: 992px) {
  .section--intro .intro__survey .survey__discount {
    font-size: 170px;
    line-height: 190px;
  }
}
@media screen and (max-width: 576px) {
  .section--intro .intro__survey .survey__discount {
    font-size: 155px;
    line-height: 175px;
  }
}
.section--intro .intro__survey .survey__info {
  display: flex;
  flex-direction: column;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  max-width: 370px;
  padding: 10px;
  transform: translate(-30px, -85px);
  background-color: #FFFFFF;
}
@media screen and (max-width: 992px) {
  .section--intro .intro__survey .survey__info {
    font-size: 26px;
    line-height: 32px;
  }
}
@media screen and (max-width: 992px) {
  .section--intro .intro__survey .survey__info {
    font-size: 24px;
    line-height: 28px;
  }
}
@media screen and (max-width: 576px) {
  .section--intro .intro__survey .survey__info {
    font-size: 20px;
    line-height: 24px;
    max-width: 280px;
    transform: translate(-10px, -65px);
  }
}
.section--intro .intro__survey .survey__info span {
  font-weight: 600;
  color: #EC5A2C;
}
.section--intro .intro__survey .survey-start {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.section--intro .intro__survey .survey-start .steps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}
@media screen and (max-width: 576px) {
  .section--intro .intro__survey .survey-start .steps {
    flex-direction: column;
  }
}
.section--intro .intro__survey .survey-start .steps .step {
  display: flex;
  flex-direction: column;
  max-width: 180px;
}
@media screen and (max-width: 576px) {
  .section--intro .intro__survey .survey-start .steps .step {
    flex-direction: row;
    max-width: 300px;
  }
}
@media screen and (max-width: 992px) {
  .section--intro .intro__survey .survey-start .steps .step:not(:last-child) {
    margin-right: 20px;
  }
}
@media screen and (max-width: 576px) {
  .section--intro .intro__survey .survey-start .steps .step:not(:last-child) {
    margin-right: 0;
    margin-bottom: 40px;
  }
}
.section--intro .intro__survey .survey-start .steps .step__index {
  font-size: 35px;
  font-weight: 400;
  line-height: 42px;
  margin-bottom: 30px;
  color: #EC5A2C;
}
@media screen and (max-width: 576px) {
  .section--intro .intro__survey .survey-start .steps .step__index {
    margin-bottom: 0;
    margin-right: 30px;
  }
}
.section--intro .intro__survey .survey-start .steps .step__description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2B2A29;
}
.section--intro .intro__survey .survey-start .button {
  max-width: 175px;
}

.section--categories {
  padding-top: 0;
}

.section--popular-products {
  padding-bottom: 160px;
}
@media screen and (max-width: 992px) {
  .section--popular-products {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 576px) {
  .section--popular-products {
    padding-bottom: 85px;
  }
}
@media screen and (max-width: 576px) {
  .section--popular-products {
    padding-bottom: 70px;
  }
}

.section--request {
  position: relative;
  overflow: hidden;
  background-color: rgba(243, 243, 243, 0.9);
}
@media screen and (max-width: 576px) {
  .section--request {
    padding: 70px 0;
  }
}
.section--request .request {
  display: flex;
  flex-direction: column;
}
.section--request .request > * {
  z-index: 2;
}
.section--request .request img {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 1;
  transform: translateX(-45%);
}
@media screen and (max-width: 1200px) {
  .section--request .request img {
    right: 0;
    left: auto;
    width: 300px;
  }
}
@media screen and (max-width: 768px) {
  .section--request .request img {
    right: -72px;
    width: 260px;
  }
}
@media screen and (max-width: 576px) {
  .section--request .request img {
    z-index: 3;
    width: 200px;
    right: -160px;
  }
}
.section--request .request__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .section--request .request__content {
    flex-direction: column;
    margin-bottom: 30px;
  }
}
.section--request .request__content .request__info {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 35px;
  min-width: max-content;
}
@media screen and (max-width: 768px) {
  .section--request .request__content .request__info {
    margin-bottom: 30px;
  }
}
.section--request .request__content .request__info .request__title {
  display: flex;
  flex-direction: column;
  font-size: 60px;
  font-weight: 500;
  line-height: 75px;
  text-transform: uppercase;
  margin-bottom: 25px;
  color: #2B2A29;
}
@media screen and (max-width: 992px) {
  .section--request .request__content .request__info .request__title {
    font-size: 50px;
    line-height: 56px;
  }
}
@media screen and (max-width: 768px) {
  .section--request .request__content .request__info .request__title {
    font-size: 40px;
    line-height: 45px;
  }
}
@media screen and (max-width: 576px) {
  .section--request .request__content .request__info .request__title {
    font-size: 35px;
    line-height: 40px;
  }
}
.section--request .request__content .request__info .request__title span {
  font-weight: 600;
  color: #EC5A2C;
}
.section--request .request__content .request__info .request__description {
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  max-width: 320px;
  margin-left: 50px;
  color: #000000;
}
@media screen and (max-width: 992px) {
  .section--request .request__content .request__info .request__description {
    margin-left: 0;
  }
}
@media screen and (max-width: 768px) {
  .section--request .request__content .request__info .request__description {
    max-width: 500px;
  }
}
.section--request .request__content .request__advantages {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .section--request .request__content .request__advantages {
    margin-top: 0;
    max-width: 500px;
  }
}
.section--request .request__content .request__advantages .advantage {
  position: relative;
  display: flex;
  flex-direction: column;
}
.section--request .request__content .request__advantages .advantage:not(:last-child) {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .section--request .request__content .request__advantages .advantage:not(:last-child) {
    margin-bottom: 25px;
  }
}
.section--request .request__content .request__advantages .advantage::before {
  content: "+";
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #EC692C;
}
.section--request .request__content .request__advantages .advantage p {
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  color: #000000;
}
.section--request .request__form form {
  display: flex;
  flex-direction: column;
  max-width: 300px;
}
.section--request .request__form form .input:not(:last-of-type) {
  margin-bottom: 10px;
}
.section--request .request__form form .button {
  margin-top: 25px;
  margin-bottom: 20px;
}
.section--request .request__form form .agreement {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  opacity: 0.75;
}
.section--request .request__form form .agreement .checkbox {
  margin-right: 10px;
}
.section--request .request__form form .agreement label {
  cursor: pointer;
  max-width: 195px;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #2B2A29;
}
.section--request .request__form form .agreement label a {
  text-decoration: underline;
}

.section--news {
  background-color: #FFFFFF;
}

.section--about .section__title {
  max-width: 440px;
}
.section--about .about {
  display: grid;
  grid-gap: 100px;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 1200px) {
  .section--about .about {
    grid-gap: 50px;
  }
}
@media screen and (max-width: 992px) {
  .section--about .about {
    grid-template-columns: 1fr;
  }
}
.section--about .about__image {
  display: flex;
}
@media screen and (max-width: 992px) {
  .section--about .about__image {
    display: none;
  }
}
.section--about .about__image img {
  width: 100%;
}
.section--about .about__content {
  display: flex;
  flex-direction: column;
  margin-top: -90px;
}
@media screen and (max-width: 992px) {
  .section--about .about__content {
    margin-top: 0;
  }
}
.section--about .about__content .about__heading {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 30px;
}
@media screen and (max-width: 576px) {
  .section--about .about__content .about__heading {
    margin-bottom: 20px;
  }
}
.section--about .about__content .about__heading .about__title {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  max-width: 160px;
  color: #EC692C;
}
@media screen and (max-width: 576px) {
  .section--about .about__content .about__heading .about__title {
    max-width: 100%;
  }
  .section--about .about__content .about__heading .about__title br {
    display: none;
  }
}
.section--about .about__content .about__heading .about__subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.5;
  margin-left: 30px;
  color: #2B2A29;
}
@media screen and (max-width: 576px) {
  .section--about .about__content .about__heading .about__subtitle {
    display: none;
  }
}
.section--about .about__content .about__description {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 30px;
  color: #000000;
}
.section--about .about__content .about__stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}
@media screen and (max-width: 992px) {
  .section--about .about__content .about__stats {
    justify-content: flex-start;
  }
}
.section--about .about__content .about__stats .stat {
  display: flex;
  flex-direction: column;
  max-width: min-content;
}
@media screen and (max-width: 992px) {
  .section--about .about__content .about__stats .stat:not(:last-child) {
    margin-right: 40px;
  }
}
@media screen and (max-width: 576px) {
  .section--about .about__content .about__stats .stat:not(:last-child) {
    margin-right: 25px;
  }
}
.section--about .about__content .about__stats .stat__count {
  font-size: 35px;
  font-weight: 400;
  line-height: 43px;
  white-space: nowrap;
  margin-bottom: 20px;
  color: #EC692C;
}
@media screen and (max-width: 992px) {
  .section--about .about__content .about__stats .stat__count {
    font-size: 30px;
    line-height: 35px;
  }
}
@media screen and (max-width: 768px) {
  .section--about .about__content .about__stats .stat__count {
    font-size: 25px;
    line-height: 30px;
  }
}
@media screen and (max-width: 576px) {
  .section--about .about__content .about__stats .stat__count {
    font-size: 20px;
    line-height: 24px;
  }
}
.section--about .about__content .about__stats .stat__name {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #000000;
}
@media screen and (max-width: 768px) {
  .section--about .about__content .about__stats .stat__name {
    font-size: 12px;
    line-height: 18px;
  }
}
.section--about .about__content .button {
  width: 100%;
  max-width: 230px;
}
@media screen and (max-width: 576px) {
  .section--about .about__content .button {
    max-width: 100%;
  }
}

.section--contact .contact {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 992px) {
  .section--contact .contact {
    grid-gap: 30px;
    grid-template-columns: auto 1fr;
  }
}
@media screen and (max-width: 768px) {
  .section--contact .contact {
    grid-template-columns: 1fr;
    grid-template-rows: auto 400px;
  }
}
.section--contact .contact__info {
  display: flex;
  flex-direction: column;
}
.section--contact .contact__info .contact__call {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}
@media screen and (max-width: 992px) {
  .section--contact .contact__info .contact__call {
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .section--contact .contact__info .contact__call {
    flex-direction: row;
  }
}
@media screen and (max-width: 576px) {
  .section--contact .contact__info .contact__call {
    flex-direction: column;
  }
}
.section--contact .contact__info .contact__call .contact__assistant {
  display: flex;
  position: relative;
  margin-right: 35px;
  border-radius: 0 0 10% 10%;
  overflow: hidden;
  max-width: 220px;
}
.section--contact .contact__info .contact__call .contact__assistant::before {
  content: "";
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F3F3F3;
}
.section--contact .contact__info .contact__call .contact__assistant .contact__call-status {
  position: absolute;
  z-index: 2;
  right: 3px;
  bottom: 3px;
  width: 24px;
  height: 24px;
  border-width: 5px;
  border-radius: 50%;
  border-style: solid;
  border-color: #FFFFFF;
}
.section--contact .contact__info .contact__call .contact__assistant .contact__call-status--fail {
  background-color: #DE0303;
}
.section--contact .contact__info .contact__call .contact__assistant .contact__call-status--success {
  background-color: #30CC37;
}
.section--contact .contact__info .contact__call .contact__assistant img {
  position: relative;
  z-index: 2;
}
.section--contact .contact__info .contact__call .contact__question {
  display: flex;
  flex-direction: column;
}
.section--contact .contact__info .contact__call .contact__question .contact__title {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  margin-top: 30px;
  color: #2B2A29;
}
.section--contact .contact__info .contact__call .contact__question a:not(.button) {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-decoration: underline;
  color: #EC692C;
}
.section--contact .contact__info .contact__call .contact__question .contact__hint {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  opacity: 0.8;
  color: #2B2A29;
}
.section--contact .contact__info .contact__call .contact__question .button {
  width: 100%;
  max-width: 230px;
  margin-top: 25px;
}
.section--contact .contact__info .contact__connect {
  display: flex;
  flex-direction: column;
}
.section--contact .contact__info .contact__connect span {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  max-width: 400px;
  margin-bottom: 35px;
  color: #2B2A29;
}
@media screen and (max-width: 992px) {
  .section--contact .contact__info .contact__connect span {
    max-width: 300px;
  }
}
@media screen and (max-width: 576px) {
  .section--contact .contact__info .contact__connect span {
    font-size: 18px;
    line-height: 25px;
  }
}
.section--contact .contact__info .contact__connect a {
  font-size: 45px;
  font-weight: 400;
  line-height: 55px;
  color: #EC692C;
}
@media screen and (max-width: 992px) {
  .section--contact .contact__info .contact__connect a {
    font-size: 30px;
    line-height: 36px;
  }
}
@media screen and (max-width: 576px) {
  .section--contact .contact__info .contact__connect a {
    font-size: 25px;
    line-height: 30px;
    color: #2B2A29;
  }
}
.section--contact .contact__info .contact__connect a:not(:last-of-type) {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .section--contact .contact__info .contact__connect a:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 576px) {
  .section--contact .contact__info .contact__connect a:not(:last-of-type) {
    margin-bottom: 15px;
  }
}
.section--contact .contact__info .contact__connect a .icon {
  width: 31px;
  height: 31px;
  margin-right: 35px;
}
@media screen and (max-width: 768px) {
  .section--contact .contact__info .contact__connect a .icon {
    margin-right: 20px;
  }
}
@media screen and (max-width: 576px) {
  .section--contact .contact__info .contact__connect a .icon {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
}
.section--contact .contact__location {
  position: relative;
  max-width: 550px;
  max-height: 550px;
  overflow: hidden;
}
@media screen and (max-width: 992px) {
  .section--contact .contact__location {
    max-height: 100%;
  }
}
.section--contact .contact__location #map {
  width: 100%;
  height: 100%;
}
.section--contact .contact__location-info {
  position: absolute;
  left: 40px;
  bottom: 40px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  background-color: #FFFFFF;
}
@media screen and (max-width: 1200px) {
  .section--contact .contact__location-info {
    left: 20px;
    bottom: 20px;
  }
}
@media screen and (max-width: 992px) {
  .section--contact .contact__location-info {
    flex-direction: column;
  }
}
@media screen and (max-width: 576px) {
  .section--contact .contact__location-info {
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.section--contact .contact__location-info .column {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  max-width: max-content;
  color: #000000;
}
.section--contact .contact__location-info .column:not(:last-child) {
  margin-right: 50px;
}
@media screen and (max-width: 992px) {
  .section--contact .contact__location-info .column:not(:last-child) {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.section--contact .contact__location-info .column .contact__location-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 15px;
  color: #EC692C;
}
.section--contact .contact__location-info .column .bold {
  font-weight: 500;
}

.section--news-list {
  padding-top: 0;
}
.section--news-list .list {
  display: flex;
  flex-direction: column;
}
.section--news-list .list > *:not(:last-child) {
  margin-bottom: 75px;
}

.section--news-single {
  padding-top: 0;
}
.section--news-single article img {
  float: left;
  max-width: 100%;
  margin: 0 20px 20px 0;
}
.section--news-single .date {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-top: 15px;
  margin-left: auto;
  max-width: max-content;
  color: #EC692C;
}
.section--news-single .links {
  display: grid;
  grid-gap: 40px;
  grid-template-rows: 70px;
  grid-template-columns: 1fr 1fr;
  margin-top: 15px;
}
@media screen and (max-width: 576px) {
  .section--news-single .links {
    grid-gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: 70px 70px;
  }
}
.section--news-single .links .button {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.section--article {
  padding-top: 0;
}
.section--article .section__heading {
  margin-bottom: 20px;
}
.section--article article {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  color: rgba(43, 42, 41, 0.7);
}
.section--article article b {
  font-weight: 700;
}
.section--article article ul {
  padding-left: 30px;
  list-style: initial;
}
.section--article article .block {
  margin: 30px 0;
}
.section--article article .block--stats {
  display: flex;
  align-items: center;
  flex-direction: row;
}
@media screen and (max-width: 768px) {
  .section--article article .block--stats {
    flex-direction: column;
    align-items: flex-start;
  }
}
.section--article article .block--stats .stat {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.section--article article .block--stats .stat:not(:last-child) {
  margin-right: 50px;
}
@media screen and (max-width: 768px) {
  .section--article article .block--stats .stat:not(:last-child) {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.section--article article .block--stats .stat__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.section--article article .block--stats .stat__icon .icon {
  width: 60px;
  height: 60px;
  color: #000000;
}
.section--article article .block--stats .stat__info {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  color: #2B2A29;
}
.section--article article .block--stats .stat__info .stat__count {
  font-weight: 700;
  margin-bottom: 5px;
}
.section--article article .block--requisites {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 380px auto;
}
@media screen and (max-width: 768px) {
  .section--article article .block--requisites {
    grid-template-columns: 1fr;
    grid-template-rows: 360px auto;
  }
}
.section--article article .block--requisites .requisites__image {
  overflow: hidden;
}
.section--article article .block--requisites .requisites__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.section--article article .block--requisites .requisites__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.section--article article .block--requisites .requisites__info .requisites__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 10px;
}
.section--article article .block--facts {
  display: grid;
  grid-gap: 40px 25px;
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (max-width: 992px) {
  .section--article article .block--facts {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .section--article article .block--facts {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 576px) {
  .section--article article .block--facts {
    grid-template-columns: repeat(1, 1fr);
  }
}
.section--article article .block--facts .fact {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.section--article article .block--facts .fact__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.section--article article .block--facts .fact__icon .icon {
  width: 60px;
  height: 60px;
  color: #000000;
}
.section--article article .block--facts .fact__name {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  max-width: 185px;
  color: #000000;
}

.section--contact-page {
  padding-top: 0;
}
.section--contact-page .contact {
  display: grid;
  grid-gap: 60px;
  grid-template-columns: 370px 1fr;
}
@media screen and (max-width: 992px) {
  .section--contact-page .contact {
    grid-gap: 30px;
    grid-template-columns: 340px 1fr;
  }
}
@media screen and (max-width: 768px) {
  .section--contact-page .contact {
    grid-gap: 30px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
}
.section--contact-page .contact__info {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #2B2A29;
}
.section--contact-page .contact__info .contact__location {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 25px;
}
@media screen and (max-width: 768px) {
  .section--contact-page .contact__info .contact__location {
    margin-top: 0;
  }
}
.section--contact-page .contact__info .contact__contacts {
  display: flex;
  flex-direction: column;
}
.section--contact-page .contact__info .contact__contacts > span {
  margin-bottom: 15px;
}
.section--contact-page .contact__info .contact__contacts .contact__items {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.1);
}
.section--contact-page .contact__info .contact__contacts .contact__items .item {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.section--contact-page .contact__info .contact__contacts .contact__items .item:hover {
  opacity: 1;
}
.section--contact-page .contact__info .contact__contacts .contact__items .item:hover .item__icon {
  color: #EC692C;
}
.section--contact-page .contact__info .contact__contacts .contact__items .item:not(:last-child) {
  margin-bottom: 20px;
}
.section--contact-page .contact__info .contact__contacts .contact__items .item__icon {
  opacity: 0.5;
  transition-duration: 0.2s;
  transition-property: color, opacity;
}
.section--contact-page .contact__info .contact__contacts .contact__items .item__icon .icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.section--contact-page .contact__info .contact__contacts .contact__items .item__list {
  display: flex;
  flex-direction: column;
}
.section--contact-page .contact__info .contact__contacts .contact__items .item__list a {
  opacity: 0.5;
  transition-duration: 0.2s;
  transition-property: color, opacity;
}
.section--contact-page .contact__info .contact__contacts .contact__items .item__list a:hover {
  opacity: 1;
  color: #EC692C;
}
.section--contact-page .contact__info .contact__contacts .contact__items .item__list a:not(:last-child) {
  margin-bottom: 5px;
}
.section--contact-page .contact__info .contact__socials {
  display: flex;
  flex-direction: column;
}
.section--contact-page .contact__info .contact__socials span {
  margin-bottom: 15px;
}
.section--contact-page .contact__info .contact__socials .socials {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.section--contact-page .contact__info .contact__socials .socials a:not(:last-child) {
  margin-right: 20px;
}
.section--contact-page .contact__map {
  position: relative;
  max-width: 700px;
  max-height: 400px;
  overflow: hidden;
}
.section--contact-page .contact__map #map {
  width: 100%;
  height: 100%;
}

.section--team {
  padding-bottom: 0;
}
.section--team .team {
  display: flex;
  flex-direction: column;
}
.section--team .team .slider__items {
  margin-bottom: 70px;
}
@media screen and (max-width: 768px) {
  .section--team .team .slider__items {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 576px) {
  .section--team .team .slider__items {
    margin-bottom: 30px;
  }
}
.section--team .team .slider__items .slide {
  overflow: hidden;
}
.section--team .team .slider__items .slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.section--team .team .slider__arrows {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.section--team .team .slider__arrows .slider__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin: 0 50px;
  transition-duration: 0.2s;
  transition-property: color;
}
@media screen and (max-width: 992px) {
  .section--team .team .slider__arrows .slider__arrow {
    width: 50px;
    height: 50px;
    margin: 0 30px;
  }
}
@media screen and (max-width: 768px) {
  .section--team .team .slider__arrows .slider__arrow {
    width: 40px;
    height: 40px;
    margin: 0 20px;
  }
}
@media screen and (max-width: 576px) {
  .section--team .team .slider__arrows .slider__arrow {
    width: 30px;
    height: 30px;
    margin: 0 10px;
  }
}
.section--team .team .slider__arrows .slider__arrow[aria-disabled=false] {
  color: #EC5A2C;
}
.section--team .team .slider__arrows .slider__arrow .icon {
  width: 33px;
  height: 60px;
}
@media screen and (max-width: 992px) {
  .section--team .team .slider__arrows .slider__arrow .icon {
    width: 40px;
    height: 30px;
  }
}
@media screen and (max-width: 768px) {
  .section--team .team .slider__arrows .slider__arrow .icon {
    width: 30px;
    height: 20px;
  }
}
@media screen and (max-width: 576px) {
  .section--team .team .slider__arrows .slider__arrow .icon {
    width: 25px;
    height: 15px;
  }
}

.section--examples {
  padding-top: 0;
}
.section--examples .examples {
  display: flex;
  flex-direction: column;
}
.section--examples .examples .slick-slide {
  margin: 0 25px;
}
@media screen and (max-width: 768px) {
  .section--examples .examples .slick-slide {
    margin: 0 12.5px;
  }
}
.section--examples .examples .slick-list {
  margin: 0 -25px;
}
@media screen and (max-width: 576px) {
  .section--examples .examples .slick-list {
    margin: 0 -12.5px;
  }
}
.section--examples .examples .slider__items {
  margin-bottom: 70px;
}
@media screen and (max-width: 768px) {
  .section--examples .examples .slider__items {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 576px) {
  .section--examples .examples .slider__items {
    margin-bottom: 30px;
  }
}
.section--examples .examples .slider__items .slide {
  overflow: hidden;
}
.section--examples .examples .slider__items .slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.section--examples .examples .slider__arrows {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.section--examples .examples .slider__arrows .slider__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin: 0 50px;
  transition-duration: 0.2s;
  transition-property: color;
}
@media screen and (max-width: 992px) {
  .section--examples .examples .slider__arrows .slider__arrow {
    width: 50px;
    height: 50px;
    margin: 0 30px;
  }
}
@media screen and (max-width: 768px) {
  .section--examples .examples .slider__arrows .slider__arrow {
    width: 40px;
    height: 40px;
    margin: 0 20px;
  }
}
@media screen and (max-width: 576px) {
  .section--examples .examples .slider__arrows .slider__arrow {
    width: 30px;
    height: 30px;
    margin: 0 10px;
  }
}
.section--examples .examples .slider__arrows .slider__arrow[aria-disabled=false] {
  color: #EC5A2C;
}
.section--examples .examples .slider__arrows .slider__arrow .icon {
  width: 33px;
  height: 60px;
}
@media screen and (max-width: 992px) {
  .section--examples .examples .slider__arrows .slider__arrow .icon {
    width: 40px;
    height: 30px;
  }
}
@media screen and (max-width: 768px) {
  .section--examples .examples .slider__arrows .slider__arrow .icon {
    width: 30px;
    height: 20px;
  }
}
@media screen and (max-width: 576px) {
  .section--examples .examples .slider__arrows .slider__arrow .icon {
    width: 25px;
    height: 15px;
  }
}

.section--catalog {
  margin: 0 auto;
  padding: 30px 0;
  max-width: 1300px;
  transform: translateY(-60px);
  background-color: #FFFFFF;
}
@media screen and (max-width: 992px) {
  .section--catalog {
    transform: translateY(0);
  }
}
.section--catalog .title {
  display: none;
  font-size: 32px;
  font-weight: 500;
  line-height: 39px;
  margin-bottom: 25px;
}
@media screen and (max-width: 576px) {
  .section--catalog .title {
    display: block;
  }
}
.section--catalog .section--breadcrumbs {
  margin-bottom: 15px;
}
.section--catalog .section--breadcrumbs .container {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.section--catalog .section--breadcrumbs .container .breadcrumbs {
  margin-left: 60px;
}
@media screen and (max-width: 576px) {
  .section--catalog .section--breadcrumbs .container .breadcrumbs {
    margin-left: 0;
  }
}
.section--catalog .catalog {
  display: flex;
  flex-direction: column;
}
.section--catalog .catalog__categories {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  overflow-x: auto;
  margin-bottom: 40px;
}
.section--catalog .catalog__categories .catalog__category {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  height: 100%;
  cursor: pointer;
  padding: 0 25px;
  min-width: auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
  transition-duration: 0.2s;
  transition-property: opacity;
  color: #2B2A29;
}
@media screen and (max-width: 576px) {
  .section--catalog .catalog__categories .catalog__category {
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
  }
}
.section--catalog .catalog__categories .catalog__category:nth-child(1) {
  min-width: 260px;
}
@media screen and (max-width: 576px) {
  .section--catalog .catalog__categories .catalog__category:nth-child(1) {
    min-width: 200px;
  }
}
.section--catalog .catalog__categories .catalog__category:hover {
  opacity: 1;
}
.section--catalog .catalog__categories .catalog__category.active {
  opacity: 1;
  color: #FFFFFF;
  background-color: #EC692C;
}
.section--catalog .catalog__inner {
  display: grid;
  grid-gap: 60px;
  grid-template-columns: 300px 1fr;
}
@media screen and (max-width: 768px) {
  .section--catalog .catalog__inner {
    grid-template-columns: 1fr;
  }
}
.section--catalog .catalog__inner .catalog__filters {
  display: flex;
  flex-direction: column;
  padding-right: 25px;
  border-right-width: 2px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 768px) {
  .section--catalog .catalog__inner .catalog__filters {
    border-right: none;
    padding-right: 0;
  }
}
.section--catalog .catalog__inner .catalog__filters > .button {
  display: none;
}
@media screen and (max-width: 768px) {
  .section--catalog .catalog__inner .catalog__filters > .button {
    display: flex;
  }
}
.section--catalog .catalog__inner .catalog__filters-inner > .button {
  width: 100%;
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  .section--catalog .catalog__inner .catalog__filters-inner {
    display: none;
  }
  .section--catalog .catalog__inner .catalog__filters-inner.active {
    display: block;
    max-width: 300px;
    background-color: #FFFFFF;
    box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.1);
  }
}
.section--catalog .catalog__inner .catalog__filters-list {
  display: flex;
  flex-direction: column;
  padding: 30px 15px;
}
.section--catalog .catalog__inner .catalog__filters .filter {
  display: flex;
  flex-direction: column;
}
.section--catalog .catalog__inner .catalog__filters .filter:not(:first-child) {
  padding-top: 30px;
}
.section--catalog .catalog__inner .catalog__filters .filter:not(:last-child) {
  padding-bottom: 30px;
}
.section--catalog .catalog__inner .catalog__filters .filter:not(:last-child) {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.1);
}
.section--catalog .catalog__inner .catalog__filters .filter__heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.section--catalog .catalog__inner .catalog__filters .filter__heading .filter__title {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #000000;
}
.section--catalog .catalog__inner .catalog__filters .filter__heading .filter__reset {
  display: flex;
  align-items: center;
  flex-direction: row;
  opacity: 0.5;
  cursor: pointer;
  transition-duration: 0.2s;
  transition-property: opacity, color;
  color: #2B2A29;
}
.section--catalog .catalog__inner .catalog__filters .filter__heading .filter__reset:hover {
  opacity: 1;
  color: #EC692C;
}
.section--catalog .catalog__inner .catalog__filters .filter__heading .filter__reset .icon {
  width: 8px;
  height: 8px;
  margin-right: 8px;
}
.section--catalog .catalog__inner .catalog__filters .filter__heading .filter__reset span {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}
.section--catalog .catalog__inner .catalog__filters .filter__inner .checkbox-list {
  display: flex;
  flex-direction: column;
}
.section--catalog .catalog__inner .catalog__filters .filter__inner .checkbox-list .item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.section--catalog .catalog__inner .catalog__filters .filter__inner .checkbox-list .item:not(:last-child) {
  margin-bottom: 10px;
}
.section--catalog .catalog__inner .catalog__filters .filter__inner .checkbox-list .item .checkbox {
  margin-right: 10px;
}
.section--catalog .catalog__inner .catalog__filters .filter__inner .checkbox-list .item .checkbox.active ~ label {
  opacity: 1;
  color: #EC692C;
}
.section--catalog .catalog__inner .catalog__filters .filter__inner .checkbox-list .item > label {
  cursor: pointer;
  opacity: 0.7;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #2B2A29;
}
.section--catalog .catalog__inner .catalog__filters .filter--selected .selections {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.section--catalog .catalog__inner .catalog__filters .filter--selected .selections .selection {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 15px;
  margin-right: 12px;
  margin-bottom: 12px;
  background-color: #F3F3F3;
  color: #2B2A29;
}
.section--catalog .catalog__inner .catalog__filters .filter--selected .selections .selection span {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-right: 15px;
}
.section--catalog .catalog__inner .catalog__filters .filter--selected .selections .selection .icon {
  width: 8px;
  height: 8px;
  opacity: 0.5;
}
.section--catalog .catalog__inner .catalog__filters .filter--selected .button {
  width: 100%;
  height: 40px;
  max-width: 100%;
}
.section--catalog .catalog__inner .catalog__filters .filter--price #price-slider {
  height: 2px;
  border: none;
  background-color: rgba(196, 196, 196, 0.5);
}
.section--catalog .catalog__inner .catalog__filters .filter--price #price-slider .ui-slider-range {
  background-color: #EC692C;
}
.section--catalog .catalog__inner .catalog__filters .filter--price #price-slider .ui-slider-handle {
  width: 10px;
  height: 10px;
  border: none;
  outline: none;
  border-radius: 50%;
  background-color: #EC692C;
}
.section--catalog .catalog__inner .catalog__filters .filter--price #price-slider .ui-slider-handle::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
}
.section--catalog .catalog__inner .catalog__filters .filter--price .values {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
}
.section--catalog .catalog__inner .catalog__filters .filter--price .values .value {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 5px 10px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(43, 42, 41, 0.2);
}
.section--catalog .catalog__inner .catalog__filters .filter--price .values .value > span {
  opacity: 0.5;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-right: 10px;
  color: #2B2A29;
}
.section--catalog .catalog__inner .catalog__filters .filter--price .values .value > input {
  padding: 0;
  width: 100%;
  opacity: 0.5;
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  transition-duration: 0.2s;
  transition-property: color, opacity;
  color: #2B2A29;
}
.section--catalog .catalog__inner .catalog__filters .filter--price .values .value > input:focus {
  opacity: 1;
  color: #EC692C;
}
.section--catalog .catalog__inner .catalog__filters .filter--price .values .value:not(:last-child) {
  margin-right: 10px;
}
.section--catalog .catalog__inner .catalog__content > a {
  display: block;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 45px;
  text-decoration: underline;
  color: #EC692C;
}
@media screen and (max-width: 768px) {
  .section--catalog .catalog__inner .catalog__content > a {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 576px) {
  .section--catalog .catalog__inner .catalog__content > a {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 30px;
  }
}

.section--slider {
  padding: 0;
  overflow-x: hidden;
}
.section--slider .slider {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.section--slider .slider .slick-track {
  display: flex !important;
}
.section--slider .slider .slick-slide {
  margin: 0 25px;
}
@media screen and (max-width: 576px) {
  .section--slider .slider .slick-slide {
    margin: 0 12.5px;
  }
}
.section--slider .slider .slick-list {
  margin: 0 -25px;
}
@media screen and (max-width: 576px) {
  .section--slider .slider .slick-list {
    margin: 0 -12.5px;
  }
}
.section--slider .slider__items {
  display: flex;
  max-width: 100%;
  margin-bottom: 70px;
}
@media screen and (max-width: 768px) {
  .section--slider .slider__items {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 576px) {
  .section--slider .slider__items {
    margin-bottom: 30px;
  }
}
.section--slider .slider__arrows {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.section--slider .slider__arrows .slider__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin: 0 25px;
  transition-duration: 0.2s;
  transition-property: color;
}
@media screen and (max-width: 992px) {
  .section--slider .slider__arrows .slider__arrow {
    width: 50px;
    height: 50px;
    margin: 0 30px;
  }
}
@media screen and (max-width: 768px) {
  .section--slider .slider__arrows .slider__arrow {
    width: 40px;
    height: 40px;
    margin: 0 20px;
  }
}
@media screen and (max-width: 576px) {
  .section--slider .slider__arrows .slider__arrow {
    width: 30px;
    height: 30px;
    margin: 0 10px;
  }
}
.section--slider .slider__arrows .slider__arrow[aria-disabled=false] {
  color: #EC5A2C;
}
.section--slider .slider__arrows .slider__arrow .icon {
  width: 33px;
  height: 60px;
}
@media screen and (max-width: 992px) {
  .section--slider .slider__arrows .slider__arrow .icon {
    width: 40px;
    height: 30px;
  }
}
@media screen and (max-width: 768px) {
  .section--slider .slider__arrows .slider__arrow .icon {
    width: 30px;
    height: 20px;
  }
}
@media screen and (max-width: 576px) {
  .section--slider .slider__arrows .slider__arrow .icon {
    width: 25px;
    height: 15px;
  }
}

.section--all-products .section__heading {
  justify-content: space-between;
}
.section--all-products .section__heading .variants {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 7.5px;
  color: #2B2A29;
}
@media screen and (max-width: 1200px) {
  .section--all-products .section__heading .variants {
    display: none;
  }
}
.section--all-products .section__heading .variants > span {
  margin-right: 12px;
}
.section--all-products .section__heading .variants .variant {
  opacity: 0.5;
  cursor: pointer;
  transition-duration: 0.2s;
  transition-property: color, opacity;
}
.section--all-products .section__heading .variants .variant:not(:last-child) {
  margin-right: 10px;
}
.section--all-products .section__heading .variants .variant:hover {
  opacity: 1;
}
.section--all-products .section__heading .variants .variant.active {
  opacity: 1;
  color: #EC692C;
}
.section--all-products .section__heading .variants .variant .icon {
  width: 20px;
  height: 20px;
}
.section--all-products .section__inner .brands {
  padding: 20px 25px;
  margin-bottom: 45px;
  background-color: #F3F3F3;
}
.section--all-products .section__inner .brands__title {
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 20px;
  color: #2B2A29;
}
.section--all-products .section__inner .brands__list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  overflow-x: auto;
}
.section--all-products .section__inner .brands__list > img {
  min-width: initial;
}
.section--all-products .section__inner .brands__list > img:not(:last-child) {
  margin-right: 25px;
}
.section--all-products .section__inner .products {
  display: grid;
  grid-gap: 50px;
}
.section--all-products .section__inner .products--grid {
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 1200px) {
  .section--all-products .section__inner .products--grid {
    grid-template-columns: 1fr;
  }
}
.section--all-products .section__inner .products--list {
  grid-template-columns: 1fr;
}

.section--product {
  padding-top: 0;
  padding-bottom: 0;
}
.section--product .item {
  display: grid;
  grid-gap: 45px;
  grid-template-columns: 640px auto;
  margin-bottom: 55px;
}
@media screen and (max-width: 1200px) {
  .section--product .item {
    grid-template-columns: 500px auto;
  }
}
@media screen and (max-width: 992px) {
  .section--product .item {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 768px) {
  .section--product .item__gallery {
    grid-gap: 10px;
    grid-template-rows: 300px 100px;
  }
}
@media screen and (max-width: 576px) {
  .section--product .item__gallery {
    grid-template-rows: 250px 90px;
  }
}
.section--product .item__gallery .gallery__selected {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  overflow: hidden;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .section--product .item__gallery .gallery__selected {
    height: 300px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 576px) {
  .section--product .item__gallery .gallery__selected {
    height: 250px;
  }
}
.section--product .item__gallery .gallery__selected.video iframe {
  display: block;
  width: 100%;
  height: 100%;
}
.section--product .item__gallery .gallery__selected.video > img {
  display: none;
}
.section--product .item__gallery .gallery__selected iframe {
  display: none;
}
.section--product .item__gallery .gallery__selected img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.section--product .item__gallery .slick-track {
  display: flex !important;
}
.section--product .item__gallery .slick-list {
  margin-left: -17.5px;
  min-width: calc(100% + 35px);
}
@media screen and (max-width: 768px) {
  .section--product .item__gallery .slick-list {
    margin-left: -10px;
    min-width: calc(100% + 20px);
  }
}
.section--product .item__gallery .slick-slide {
  display: flex;
  margin: 0 17.5px;
}
@media screen and (max-width: 768px) {
  .section--product .item__gallery .slick-slide {
    margin: 0 10px;
  }
}
.section--product .item__gallery .gallery__images .slider {
  display: flex;
  max-width: 100%;
}
.section--product .item__gallery .gallery__images .slider__items {
  width: 100%;
  display: flex;
  cursor: default;
}
.section--product .item__gallery .gallery__images .gallery__image {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  height: 110px;
}
.section--product .item__gallery .gallery__images .gallery__image.video {
  position: relative;
}
.section--product .item__gallery .gallery__images .gallery__image.video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(5, 4, 4, 0.4);
}
.section--product .item__gallery .gallery__images .gallery__image.video .overlay img {
  width: 55px;
  height: 55px;
}
.section--product .item__gallery .gallery__images .gallery__image.active {
  border-width: 2px;
  border-style: solid;
  border-color: #EC692C;
}
@media screen and (max-width: 768px) {
  .section--product .item__gallery .gallery__images .gallery__image {
    grid-gap: 10px;
    height: 100px;
  }
}
@media screen and (max-width: 576px) {
  .section--product .item__gallery .gallery__images .gallery__image {
    height: 90px;
  }
}
.section--product .item__gallery .gallery__images .gallery__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.section--product .item__info {
  display: flex;
  flex-direction: column;
  color: #2B2A29;
}
.section--product .item__info .item__title {
  font-size: 45px;
  font-weight: 500;
  line-height: 54px;
  margin-bottom: 30px;
}
@media screen and (max-width: 992px) {
  .section--product .item__info .item__title {
    font-size: 40px;
    line-height: 46px;
  }
}
@media screen and (max-width: 768px) {
  .section--product .item__info .item__title {
    font-size: 36px;
    line-height: 42px;
  }
}
@media screen and (max-width: 576px) {
  .section--product .item__info .item__title {
    font-size: 32px;
    line-height: 39px;
  }
}
.section--product .item__info .item__specifications {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}
.section--product .item__info .item__specifications > span {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #EC692C;
}
.section--product .item__info .item__specifications .item__specification {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-top: 20px;
}
.section--product .item__info .item__specifications .item__specification .key {
  opacity: 0.6;
  margin-right: 5px;
}
.section--product .item__info .item__specifications .item__specification .value {
  font-weight: 600;
}
.section--product .item__info .item__price {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 15px;
  color: #2B2A29;
}
.section--product .item__info .item__price > span:not(:last-child) {
  margin-right: 15px;
}
.section--product .item__info .item__price .old {
  opacity: 0.5;
  font-size: 14px;
  line-height: 17px;
  text-decoration: line-through;
}
.section--product .item__info .item__order {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
}
@media screen and (max-width: 576px) {
  .section--product .item__info .item__order {
    max-width: 225px;
    flex-direction: column;
  }
}
.section--product .item__info .item__order .button {
  width: 100%;
  max-width: 225px;
  margin-right: 20px;
}
@media screen and (max-width: 400px) {
  .section--product .item__info .item__order .button {
    max-width: 100%;
  }
}
.section--product .item__info .item__order > span {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}
@media screen and (max-width: 576px) {
  .section--product .item__info .item__order > span {
    margin-top: 15px;
  }
}
.section--product .item__info .item__advantages {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 576px) {
  .section--product .item__info .item__advantages {
    grid-gap: 25px;
  }
}
.section--product .item__info .item__advantages .item__advantage {
  display: flex;
  align-items: center;
  flex-direction: row;
}
@media screen and (max-width: 576px) {
  .section--product .item__info .item__advantages .item__advantage {
    flex-direction: column;
    align-items: flex-start;
  }
}
.section--product .item__info .item__advantages .item__advantage .icon {
  width: 60px;
  height: 30px;
  margin-right: 15px;
  color: #EC692C;
}
@media screen and (max-width: 576px) {
  .section--product .item__info .item__advantages .item__advantage .icon {
    width: 40px;
    height: 40px;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.section--product .item__info .item__advantages .item__advantage span {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}
@media screen and (max-width: 576px) {
  .section--product .item__info .item__advantages .item__advantage span {
    font-size: 12px;
  }
}
.section--product .info {
  display: flex;
  flex-direction: column;
}
.section--product .info__tabs {
  display: flex;
  flex-direction: row;
}
.section--product .info__tabs .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  width: 100%;
  height: 70px;
  max-width: 260px;
  cursor: pointer;
  background-color: #F3F3F3;
  color: rgba(43, 42, 41, 0.5);
  transition-duration: 0.2s;
  transition-property: color, background-color, opacity;
}
.section--product .info__tabs .tab.active {
  color: #FFFFFF;
  background-color: #EC692C;
}
.section--product .info__inner {
  grid-gap: 50px;
  display: grid !important;
  grid-template-columns: auto 370px;
  border-width: 2px;
  border-style: solid;
  border-color: #F3F3F3;
  padding: 35px 60px;
}
@media screen and (max-width: 992px) {
  .section--product .info__inner {
    padding: 25px 40px;
    grid-template-columns: auto 250px;
  }
}
@media screen and (max-width: 768px) {
  .section--product .info__inner {
    padding: 25px 20px;
    grid-gap: 25px;
    grid-template-columns: 1fr;
  }
  .section--product .info__inner--review .info__sidebar {
    grid-row: 1;
    padding-top: 0 !important;
    border-top: none !important;
    padding-bottom: 25px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: rgba(0, 0, 0, 0.1);
  }
}
.section--product .info__inner .info__content {
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
}
.section--product .info__inner .info__content .paginator {
  padding-top: 0;
  border-top: none;
}
.section--product .info__inner .info__content .sort {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 20px;
  color: #2B2A29;
}
.section--product .info__inner .info__content .sort > span {
  margin-right: 12px;
}
.section--product .info__inner .info__content .sort__item {
  opacity: 0.5;
  cursor: pointer;
  transition-duration: 0.2s;
  transition-property: color, opacity;
}
.section--product .info__inner .info__content .sort__item:not(:last-child) {
  margin-right: 10px;
}
.section--product .info__inner .info__content .sort__item:hover {
  opacity: 1;
}
.section--product .info__inner .info__content .sort__item.active {
  opacity: 1;
  color: #EC692C;
}
.section--product .info__inner .info__content .reviews {
  display: flex;
  flex-direction: column;
}
.section--product .info__inner .info__content .reviews .review {
  display: flex;
  flex-direction: column;
}
.section--product .info__inner .info__content .reviews .review:not(:last-child) {
  margin-bottom: 45px;
}
.section--product .info__inner .info__content .reviews .review__header {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.section--product .info__inner .info__content .reviews .review__header > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.section--product .info__inner .info__content .reviews .review__header > div .review__author {
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
}
.section--product .info__inner .info__content .reviews .review__header > div .review__rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
}
.section--product .info__inner .info__content .reviews .review__header > div .review__rating .star {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}
.section--product .info__inner .info__content .reviews .review__header > div .review__rating .star:not(:last-child) {
  margin-right: 8px;
}
.section--product .info__inner .info__content .reviews .review__header > div .review__rating .star.active {
  opacity: 1;
  color: #EC692C;
}
.section--product .info__inner .info__content .reviews .review__header > div .review__rating .star .icon {
  width: 12px;
  height: 12px;
}
.section--product .info__inner .info__content .reviews .review__header > div .review__date {
  opacity: 0.5;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}
.section--product .info__inner .info__content .reviews .review__blocks {
  display: flex;
  flex-direction: column;
}
.section--product .info__inner .info__content .reviews .review__blocks .block {
  display: flex;
  flex-direction: column;
}
.section--product .info__inner .info__content .reviews .review__blocks .block:not(:last-child) {
  margin-bottom: 25px;
}
.section--product .info__inner .info__content .reviews .review__blocks .block__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}
.section--product .info__inner .info__content .reviews .review__blocks .block__title--success {
  color: #30CC37;
}
.section--product .info__inner .info__content .reviews .review__blocks .block__title--fail {
  color: #DE0303;
}
.section--product .info__inner .info__content .reviews .review__blocks .block__title .icon {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
.section--product .info__inner .info__content .reviews .review__blocks .block__content {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding-left: 20px;
}
.section--product .info__inner .info__sidebar {
  padding-left: 50px;
  border-left-style: solid;
  border-left-width: 2px;
  border-left-color: rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 992px) {
  .section--product .info__inner .info__sidebar {
    padding-left: 25px;
  }
}
@media screen and (max-width: 768px) {
  .section--product .info__inner .info__sidebar {
    border-left: none;
    padding-left: 0;
    padding-top: 25px;
    border-top-style: solid;
    border-top-width: 2px;
    border-top-color: rgba(0, 0, 0, 0.1);
  }
}
.section--product .info__inner .info__sidebar .sidebar__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 25px;
  color: #EC692C;
}
.section--product .info__inner .info__sidebar .sidebar__documentation {
  display: flex;
  flex-direction: column;
}
.section--product .info__inner .info__sidebar .sidebar__documentation > a {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  transition-duration: 0.2s;
  transition-property: color;
  color: #2B2A29;
}
.section--product .info__inner .info__sidebar .sidebar__documentation > a:hover {
  color: #EC692C;
}
.section--product .info__inner .info__sidebar .sidebar__documentation > a:not(:last-child) {
  margin-bottom: 15px;
}
.section--product .info__inner .info__sidebar .sidebar__documentation > a .icon {
  min-width: 18px;
  min-height: 22px;
  max-width: 18px;
  max-height: 22px;
  margin-right: 13px;
}
.section--product .info__inner .info__sidebar .sidebar__rating {
  display: flex;
  flex-direction: column;
}
.section--product .info__inner .info__sidebar .sidebar__rating .ratings {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  color: #2B2A29;
}
.section--product .info__inner .info__sidebar .sidebar__rating .ratings .rating {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}
.section--product .info__inner .info__sidebar .sidebar__rating .ratings .rating:not(:last-child) {
  margin-bottom: 12px;
}
.section--product .info__inner .info__sidebar .sidebar__rating .ratings .rating > a, .section--product .info__inner .info__sidebar .sidebar__rating .ratings .rating span {
  opacity: 0.5;
}
.section--product .info__inner .info__sidebar .sidebar__rating .ratings .rating > a {
  text-decoration: underline;
}
.section--product .info__inner .info__sidebar .sidebar__rating .ratings .rating__gradations {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
}
.section--product .info__inner .info__sidebar .sidebar__rating .ratings .rating__gradations .star {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}
.section--product .info__inner .info__sidebar .sidebar__rating .ratings .rating__gradations .star:not(:last-child) {
  margin-right: 8px;
}
.section--product .info__inner .info__sidebar .sidebar__rating .ratings .rating__gradations .star.active {
  opacity: 1;
  color: #EC692C;
}
.section--product .info__inner .info__sidebar .sidebar__rating .ratings .rating__gradations .star .icon {
  width: 12px;
  height: 12px;
}

.section--grid .grid {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (max-width: 992px) {
  .section--grid .grid {
    grid-template-columns: 1fr;
  }
}
.section--grid .slick-track {
  height: 100%;
}
.section--grid .slick-slide {
  margin: 0 15px;
}
.section--grid .slick-list {
  margin: 0 -15px;
}
.section--grid .slider__arrows {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
@media screen and (max-width: 992px) {
  .section--grid .slider__arrows {
    display: flex;
  }
}
.section--grid .slider__arrows .slider__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin: 0 50px;
  transition-duration: 0.2s;
  transition-property: color;
}
@media screen and (max-width: 992px) {
  .section--grid .slider__arrows .slider__arrow {
    width: 50px;
    height: 50px;
    margin: 0 30px;
  }
}
@media screen and (max-width: 768px) {
  .section--grid .slider__arrows .slider__arrow {
    width: 40px;
    height: 40px;
    margin: 0 20px;
  }
}
@media screen and (max-width: 576px) {
  .section--grid .slider__arrows .slider__arrow {
    width: 30px;
    height: 30px;
    margin: 0 10px;
  }
}
.section--grid .slider__arrows .slider__arrow[aria-disabled=false] {
  color: #EC5A2C;
}
.section--grid .slider__arrows .slider__arrow .icon {
  width: 33px;
  height: 60px;
}
@media screen and (max-width: 992px) {
  .section--grid .slider__arrows .slider__arrow .icon {
    width: 40px;
    height: 30px;
  }
}
@media screen and (max-width: 768px) {
  .section--grid .slider__arrows .slider__arrow .icon {
    width: 30px;
    height: 20px;
  }
}
@media screen and (max-width: 576px) {
  .section--grid .slider__arrows .slider__arrow .icon {
    width: 25px;
    height: 15px;
  }
}
.section--grid.section--categories .grid {
  display: grid;
  grid-gap: 30px;
  grid-template-rows: 255px 255px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "a b c" "a b d";
}
@media screen and (max-width: 992px) {
  .section--grid.section--categories .grid {
    display: flex;
    flex-direction: row;
    height: 540px;
  }
}
@media screen and (max-width: 768px) {
  .section--grid.section--categories .grid {
    height: 400px;
  }
}
@media screen and (max-width: 992px) {
  .section--grid.section--categories .grid .category {
    grid-area: auto !important;
  }
}
.section--grid.section--categories .grid .category:nth-child(1) {
  grid-area: a;
}
.section--grid.section--categories .grid .category:nth-child(2) {
  grid-area: b;
}
.section--grid.section--categories .grid .category:nth-child(3) {
  grid-area: c;
}
.section--grid.section--categories .grid .category:nth-child(4) {
  grid-area: d;
}
@media screen and (max-width: 992px) {
  .section--grid.section--popular-products .grid {
    display: flex;
    flex-direction: row;
  }
}
@media screen and (max-width: 992px) {
  .section--grid.section--news .grid {
    display: flex;
    flex-direction: row;
  }
}

.test-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 0 20px;
}
@media screen and (max-width: 576px) {
  .test-wrapper {
    padding: 0;
    align-items: flex-start;
  }
}

.test {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1000px;
  padding: 10px;
  box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.1);
}
.test.final .test__inner {
  padding: 20px;
  height: 550px;
}
@media screen and (max-width: 576px) {
  .test.final .test__inner {
    padding: 10px;
  }
}
.test.final .test__steps,
.test.final .test__header,
.test.final .test__content,
.test.final .test__navigation {
  display: none;
}
.test.final .final {
  display: flex;
}
.test__inner {
  position: relative;
  z-index: 1;
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(43, 42, 41, 0.2);
  padding: 60px 20px 60px 350px;
}
@media screen and (max-width: 992px) {
  .test__inner {
    height: 650px;
    padding: 200px 20px 60px;
  }
}
@media screen and (max-width: 576px) {
  .test__inner {
    height: 600px;
    padding: 60px 20px 30px;
    justify-content: flex-start;
  }
}
.test__inner .test__progress {
  display: block;
  opacity: 0.5;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 20px;
  color: #2B2A29;
}
.test__inner .test__question {
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
  max-width: 380px;
  margin-bottom: 40px;
  color: #2B2A29;
}
@media screen and (max-width: 576px) {
  .test__inner .test__question {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
.test__inner .test__content {
  position: relative;
  margin-bottom: 50px;
}
@media screen and (max-width: 576px) {
  .test__inner .test__content {
    margin-bottom: 25px;
  }
}
.test__inner .test__content .variants {
  display: flex;
}
.test__inner .test__content .variants--vertical {
  flex-direction: column;
}
.test__inner .test__content .variants--horizontal {
  flex-direction: row;
}
@media screen and (max-width: 576px) {
  .test__inner .test__content .variants--horizontal {
    flex-direction: column;
  }
}
.test__inner .test__content .variants--horizontal .variant {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #2B2A29;
}
@media screen and (max-width: 576px) {
  .test__inner .test__content .variants--horizontal .variant {
    align-items: flex-start;
  }
}
.test__inner .test__content .variants--horizontal .variant > span {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 10px;
}
.test__inner .test__content .variants--horizontal .variant .input {
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #F3F3F3;
  background-color: transparent;
}
.test__inner .test__content .variants--horizontal .variant input {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
.test__inner .test__content .variants--horizontal .variant:not(:last-child) {
  margin-right: 35px;
}
.test__inner .test__content .variants--horizontal .image-radio:not(:last-child) {
  margin-right: 25px;
}
@media screen and (max-width: 576px) {
  .test__inner .test__content .variants .variant--input {
    margin-bottom: 10px;
  }
}
.test__inner .test__content .variants .variant--input .input {
  max-width: 90px;
}
@media screen and (max-width: 576px) {
  .test__inner .test__content .variants .variant--input .input {
    max-width: 150px;
  }
}
.test__inner .test__content .variants .variant--input .input input {
  opacity: 1;
  padding-right: 15px;
}
.test__inner .test__content .variants .variant--input .input label.error {
  display: none !important;
}
.test__inner .test__content .variants .variant--input .input__status {
  top: 50%;
  right: 5px;
  bottom: auto;
  transform: translateY(-50%);
}
.test__inner .test__content .variants .variant--input .input__status .icon {
  width: 8px;
  height: 8px;
}
.test__inner .test__content .variants .variant--radio {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.test__inner .test__content .variants .variant--radio .radio {
  margin-right: 10px;
}
.test__inner .test__content .variants .variant--radio > label {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}
.test__inner .test__content .variants .variant--radio:not(:last-child) {
  margin-bottom: 25px;
}
.test__inner .test__navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 576px) {
  .test__inner .test__navigation {
    flex-direction: column;
    align-items: flex-start;
    margin-top: auto;
  }
}
.test__inner .test__navigation .back {
  opacity: 0.5;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-decoration: underline;
  margin-left: 30px;
  transition-duration: 0.2s;
  transition-property: opacity, color;
}
@media screen and (max-width: 576px) {
  .test__inner .test__navigation .back {
    margin-top: 20px;
  }
}
.test__inner .test__navigation .back:hover {
  opacity: 1;
  color: #EC692C;
}
.test__inner .final {
  display: none;
  flex-direction: row;
  margin-top: 50px;
}
@media screen and (max-width: 768px) {
  .test__inner .final {
    flex-direction: column;
    margin-top: 0;
  }
}
.test__inner .final.show {
  display: flex;
}
.test__inner .final > img {
  position: absolute;
  right: 65px;
  bottom: -10px;
  z-index: 3;
  width: 325px;
}
@media screen and (max-width: 992px) {
  .test__inner .final > img {
    display: none;
  }
}
.test__inner .final .discount {
  position: absolute;
  bottom: 130px;
  right: -160px;
  z-index: 2;
  line-height: 1;
  font-size: 200px;
  font-weight: 600;
  transform: rotate(-90deg);
  color: #EC692C;
  background-color: #FFFFFF;
}
@media screen and (max-width: 992px) {
  .test__inner .final .discount {
    right: -120px;
    font-size: 150px;
  }
}
@media screen and (max-width: 768px) {
  .test__inner .final .discount {
    display: none;
  }
}
.test__inner .final__result {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .test__inner .final__result {
    flex-direction: row;
    margin-bottom: 40px;
  }
}
.test__inner .final__result .result__title {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  margin-top: 70px;
  margin-bottom: 5px;
  color: #EC692C;
}
@media screen and (max-width: 768px) {
  .test__inner .final__result .result__title {
    margin-top: 0;
    font-size: 24px;
    line-height: 30px;
  }
}
@media screen and (max-width: 576px) {
  .test__inner .final__result .result__title {
    font-size: 20px;
    line-height: 25px;
  }
}
.test__inner .final__result .result__subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  max-width: 150px;
}
@media screen and (max-width: 576px) {
  .test__inner .final__result .result__subtitle {
    font-size: 12px;
    line-height: 14px;
    max-width: 100px;
  }
}
.test__inner .final__result .result__answers {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
@media screen and (max-width: 768px) {
  .test__inner .final__result .result__answers {
    margin-top: 0;
    margin-left: 30px;
  }
}
@media screen and (max-width: 576px) {
  .test__inner .final__result .result__answers {
    margin-left: 10px;
  }
}
.test__inner .final__result .result__answers > span {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .test__inner .final__result .result__answers > span {
    display: none;
  }
}
.test__inner .final__result .result__answers .answers {
  display: flex;
  flex-direction: column;
}
.test__inner .final__result .result__answers .answers .answer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.test__inner .final__result .result__answers .answers .answer:not(:last-child) {
  margin-bottom: 15px;
}
.test__inner .final__result .result__answers .answers .answer__index {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 10px;
  color: #EC692C;
}
@media screen and (max-width: 576px) {
  .test__inner .final__result .result__answers .answers .answer__index {
    font-size: 16px;
    line-height: 20px;
  }
}
.test__inner .final__result .result__answers .answers .answer__value {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}
@media screen and (max-width: 576px) {
  .test__inner .final__result .result__answers .answers .answer__value {
    font-size: 12px;
    line-height: 16px;
  }
}
.test__inner .final__contact {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.test__inner .final__contact .final__title {
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 80px;
}
@media screen and (max-width: 768px) {
  .test__inner .final__contact .final__title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 576px) {
  .test__inner .final__contact .final__title {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
  }
}
.test__inner .final__contact .final__form {
  display: flex;
  justify-content: center;
  width: 100%;
}
.test__inner .final__contact .final__form form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
}
.test__inner .final__contact .final__form form .input:not(:last-of-type) {
  margin-bottom: 10px;
}
.test__inner .final__contact .final__form form .button {
  margin-top: 25px;
  margin-bottom: 20px;
}
@media screen and (max-width: 576px) {
  .test__inner .final__contact .final__form form .button {
    margin-top: 12.5px;
  }
}
.test__inner .final__contact .final__form form .agreement {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  opacity: 0.75;
}
.test__inner .final__contact .final__form form .agreement .checkbox {
  margin-right: 10px;
}
.test__inner .final__contact .final__form form .agreement label {
  cursor: pointer;
  max-width: 195px;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #2B2A29;
}
.test__inner .final__contact .final__form form .agreement label a {
  text-decoration: underline;
}
.test__steps {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  padding: 0 40px;
  background-color: #FFFFFF;
  box-shadow: 15px 0px 45px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 992px) {
  .test__steps {
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    padding: 30px 0;
    flex-direction: row;
  }
}
@media screen and (max-width: 768px) {
  .test__steps {
    padding: 15px 0;
  }
  .test__steps .step:not(.active) {
    display: none;
  }
}
.test__steps .step {
  opacity: 0.5;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 200px;
  transition-duration: 0.2s;
  transition-property: opacity;
  color: #2B2A29;
}
@media screen and (max-width: 576px) {
  .test__steps .step {
    max-width: 100%;
    flex-direction: row;
  }
}
.test__steps .step.active {
  opacity: 1;
}
.test__steps .step.active .step__index {
  color: #EC692C;
}
.test__steps .step__index {
  font-size: 35px;
  font-weight: 400;
  line-height: 42px;
  margin-bottom: 20px;
  transition-duration: 0.2s;
  transition-property: color;
}
@media screen and (max-width: 768px) {
  .test__steps .step__index {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 576px) {
  .test__steps .step__index {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 0;
    margin-right: 10px;
  }
}
.test__steps .step__name {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}
@media screen and (max-width: 576px) {
  .test__steps .step__name {
    font-size: 14px;
    line-height: 18px;
  }
}
.test .image-radio {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 200px;
  transition-duration: 0.2s;
  transition-property: color;
  color: #2B2A29;
}
.test .image-radio.active {
  color: #EC692C;
}
.test .image-radio.active .image {
  outline-width: 3px;
  outline-style: solid;
  outline-color: #EC692C;
}
.test .image-radio .image {
  width: 100%;
  height: 120px;
  overflow: hidden;
}
.test .image-radio .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.test .image-radio .inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
}
.test .image-radio .inner .trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  border-radius: 50%;
  background-color: #FFFFFF;
}
.test .image-radio .inner span {
  margin-top: 5px;
}

.details form {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
}
.details form .detail {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 200px 1fr;
}
@media screen and (max-width: 768px) {
  .details form .detail {
    grid-gap: 20px;
    grid-template-columns: 1fr;
  }
  .details form .detail--empty .detail__key {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .details form .detail {
    grid-gap: 15px;
  }
}
.details form .detail__key {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
.details form .detail__value {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 576px) {
  .details form .detail__value {
    flex-direction: column;
    align-items: flex-start;
  }
}
.details form .detail__value .radio-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #2B2A29;
}
.details form .detail__value .radio-wrapper:not(:last-of-type) {
  margin-right: 50px;
}
@media screen and (max-width: 992px) {
  .details form .detail__value .radio-wrapper:not(:last-of-type) {
    margin-right: 20px;
  }
}
@media screen and (max-width: 576px) {
  .details form .detail__value .radio-wrapper:not(:last-of-type) {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.details form .detail__value .radio-wrapper .radio {
  margin-right: 25px;
}
@media screen and (max-width: 992px) {
  .details form .detail__value .radio-wrapper .radio {
    margin-right: 20px;
  }
}
.details form .detail__value .radio-wrapper label {
  cursor: pointer;
}
.details form .detail__value .input {
  width: 100%;
  height: 60px;
  padding: 10px 30px;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(43, 42, 41, 0.2);
}
.details form .detail__value .input input {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  opacity: 1;
  height: 100%;
}
.details form .detail__value .mailing {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.details form .detail__value .mailing .checkbox {
  margin-right: 15px;
}
.details form .detail__value .mailing label {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}
.details form .detail__value .mailing label > a {
  color: #EC692C;
}

.orders .sort {
  margin-bottom: 30px;
}
.orders .orders-list {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
}
.orders .orders-list .order {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 115px auto;
}
@media screen and (max-width: 768px) {
  .orders .orders-list .order {
    grid-template-rows: auto auto;
  }
}
.orders .orders-list .order__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 50px;
  color: #FFFFFF;
  background-color: #2B2A29;
}
@media screen and (max-width: 768px) {
  .orders .orders-list .order__header {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 768px) {
  .orders .orders-list .order__header {
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  .orders .orders-list .order__info {
    margin-bottom: 20px;
  }
}
.orders .orders-list .order__info > div {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
@media screen and (max-width: 576px) {
  .orders .orders-list .order__info > div {
    flex-direction: column;
    align-items: flex-start;
  }
}
.orders .orders-list .order__info > div:not(:last-child) {
  margin-bottom: 5px;
}
.orders .orders-list .order__name {
  line-height: 1;
  font-size: 25px;
  font-weight: 400;
  margin-right: 20px;
}
@media screen and (max-width: 576px) {
  .orders .orders-list .order__name {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.orders .orders-list .order__status {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #EC692C;
}
.orders .orders-list .order__date {
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
}
.orders .orders-list .order__price {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.orders .orders-list .order__price > div {
  display: flex;
  flex-direction: row;
}
.orders .orders-list .order__price > div:not(:last-child) {
  margin-bottom: 5px;
}
.orders .orders-list .order__price > div:nth-child(1) span {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  margin-right: 15px;
}
.orders .orders-list .order__price > div:nth-child(2) {
  display: flex;
  flex-direction: column;
}
.orders .orders-list .order__price-value {
  line-height: 1;
  font-size: 25px;
  font-weight: 500;
}
.orders .orders-list .order__count {
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
}
.orders .orders-list .order__items {
  display: grid;
  grid-gap: 20px;
  grid-auto-rows: 190px;
  grid-template-columns: 1fr;
  padding: 15px 0;
  background-color: #FFFFFF;
}
@media screen and (max-width: 768px) {
  .orders .orders-list .order__items {
    grid-auto-rows: auto;
  }
}
.orders .orders-list .order__items .item {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 190px 450px auto auto auto;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #2B2A29;
}
@media screen and (max-width: 1200px) {
  .orders .orders-list .order__items .item {
    grid-template-columns: 190px auto auto auto auto;
  }
}
@media screen and (max-width: 768px) {
  .orders .orders-list .order__items .item {
    grid-template-columns: 150px auto auto;
  }
}
@media screen and (max-width: 576px) {
  .orders .orders-list .order__items .item {
    grid-gap: 15px;
    grid-template-columns: 80px auto;
    font-size: 10px;
    line-height: 12px;
  }
}
.orders .orders-list .order__items .item__image {
  overflow: hidden;
}
.orders .orders-list .order__items .item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.orders .orders-list .order__items .item__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.orders .orders-list .order__items .item__name {
  font-size: 16px;
  line-height: 27px;
  max-width: 410px;
  margin-bottom: 15px;
}
@media screen and (max-width: 576px) {
  .orders .orders-list .order__items .item__name {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
  }
}
.orders .orders-list .order__items .item__model span:nth-child(1) {
  opacity: 0.5;
  margin-right: 10px;
}
.orders .orders-list .order__items .item__count {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 576px) {
  .orders .orders-list .order__items .item__count {
    display: none;
  }
}
.orders .orders-list .order__items .item__count span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  line-height: 1;
  background-color: #FAFAFA;
}
.orders .orders-list .order__items .item__price {
  display: flex;
  align-items: center;
  justify-content: center;
}
.orders .orders-list .order__items .item__status {
  display: flex;
  align-items: center;
  justify-content: center;
}
.orders .orders-list .order__items .item__status--fail {
  color: #DE0303;
}
.orders .orders-list .order__items .item__status--success {
  color: #30CC37;
}

.section--account {
  padding-top: 0;
}
.section--account .section__heading {
  align-items: center;
}
@media screen and (max-width: 1200px) {
  .section--account .section__heading {
    flex-direction: column;
    align-items: flex-start;
  }
  .section--account .section__heading .section__title {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1200px) and (max-width: 576px) {
  .section--account .section__heading .section__title {
    font-size: 32px;
    line-height: 40px;
  }
}
.section--account .section__heading .tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 50px;
}
@media screen and (max-width: 1200px) {
  .section--account .section__heading .tabs {
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .section--account .section__heading .tabs {
    flex-direction: column;
    width: 100%;
  }
}
.section--account .section__heading .tabs .tab {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  height: 80px;
  max-width: 330px;
}
@media screen and (max-width: 768px) {
  .section--account .section__heading .tabs .tab {
    font-size: 16px;
    line-height: 20px;
    height: 60px;
  }
}
@media screen and (max-width: 576px) {
  .section--account .section__heading .tabs .tab {
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    max-width: 300px;
  }
}
.section--account .section__heading .tabs .tab.active {
  font-weight: 600;
  margin-right: 7px;
  margin-bottom: 7px;
}
.section--account .section__heading .tabs .tab.active:hover .tab__inner {
  transform: translate(7px, 7px);
  background-color: #EC5A2C;
}
.section--account .section__heading .tabs .tab.active:active .tab__inner {
  transform: translate(3px, 3px);
  background-color: #EC692C;
}
.section--account .section__heading .tabs .tab.active::before {
  display: block;
}
.section--account .section__heading .tabs .tab.active .tab__inner {
  color: #FFFFFF;
  background-color: #EC692C;
}
.section--account .section__heading .tabs .tab.active .tab__text {
  padding: 0;
  border: none;
}
.section--account .section__heading .tabs .tab__inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 25px;
  color: #959594;
  transition-duration: 0.3s;
  transition-property: color, transform, background-color;
}
@media screen and (max-width: 768px) {
  .section--account .section__heading .tabs .tab__inner {
    padding: 0 15px;
  }
}
.section--account .section__heading .tabs .tab__inner .icon {
  width: 22px;
  height: 22px;
  margin-right: 15px;
}
@media screen and (max-width: 576px) {
  .section--account .section__heading .tabs .tab__inner .icon {
    width: 12px;
    height: 12px;
  }
}
.section--account .section__heading .tabs .tab__inner .tab__text {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  white-space: nowrap;
  padding-bottom: 5px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #959594;
}
.section--account .section__heading .tabs .tab__inner .tab__hint {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-left: 35px;
}
.section--account .section__heading .tabs .tab::before {
  display: none;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  right: -7px;
  bottom: -7px;
  border-width: 1px;
  border-style: solid;
  border-color: #EC692C;
}
.section--account .section__heading .tabs .tab:not(:last-child) {
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .section--account .section__heading .tabs .tab:not(:last-child) {
    margin-right: 15px;
  }
}
@media screen and (max-width: 576px) {
  .section--account .section__heading .tabs .tab:not(:last-child) {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.section--cart {
  padding-top: 0;
}
.section--cart .cart {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 370px;
}
@media screen and (max-width: 1200px) {
  .section--cart .cart {
    grid-template-columns: 1fr;
  }
}
.section--cart .cart__inner .cart__items {
  display: flex;
  flex-direction: column;
}
.section--cart .cart__inner .cart__items .additional {
  opacity: 0.5;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin: 15px 0;
  max-width: 560px;
}
@media screen and (max-width: 576px) {
  .section--cart .cart__inner .cart__items .additional {
    font-size: 12px;
    line-height: 14px;
  }
}
.section--cart .cart__inner .cart__items .additional a {
  text-decoration: underline;
  color: #EC692C;
}
.section--cart .cart__inner .cart__items .item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding: 45px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(43, 42, 41, 0.2);
  color: #2B2A29;
}
.section--cart .cart__inner .cart__items .item:first-child {
  padding-top: 0;
}
.section--cart .cart__inner .cart__items .item__image {
  display: flex;
  width: 160px;
  height: 160px;
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .section--cart .cart__inner .cart__items .item__image {
    width: 120px;
    height: 120px;
    margin-right: 20px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 576px) {
  .section--cart .cart__inner .cart__items .item__image {
    width: 80px;
    height: 80px;
  }
}
.section--cart .cart__inner .cart__items .item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.section--cart .cart__inner .cart__items .item__info {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  margin-right: 40px;
}
@media screen and (max-width: 768px) {
  .section--cart .cart__inner .cart__items .item__info {
    margin-right: 10px;
    max-width: 200px;
  }
}
@media screen and (max-width: 576px) {
  .section--cart .cart__inner .cart__items .item__info {
    margin-right: 0;
    max-width: 180px;
  }
}
.section--cart .cart__inner .cart__items .item__info .item__name {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 15px;
}
.section--cart .cart__inner .cart__items .item__info .item__model {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
@media screen and (max-width: 576px) {
  .section--cart .cart__inner .cart__items .item__info .item__model {
    font-size: 10px;
    line-height: 12px;
  }
}
.section--cart .cart__inner .cart__items .item__info .item__model span:nth-child(1) {
  opacity: 0.5;
  margin-right: 10px;
}
.section--cart .cart__inner .cart__items .item__info .item__model span:nth-child(2) {
  font-weight: 500;
}
.section--cart .cart__inner .cart__items .item__counter {
  margin-right: 30px;
}
.section--cart .cart__inner .cart__items .item__counter .missing {
  opacity: 0.5;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
.section--cart .cart__inner .cart__items .item__price {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}
.section--cart .cart__inner .cart__items .item__remove {
  cursor: pointer;
  margin-left: auto;
  color: #DE0303;
}
.section--cart .cart__inner .cart__items .item__remove .icon {
  width: 15px;
  height: 15px;
}
.section--cart .cart__cheque {
  padding: 30px;
  background-color: #FAFAFA;
  color: #2B2A29;
}
@media screen and (max-width: 576px) {
  .section--cart .cart__cheque {
    padding: 30px 15px;
  }
}
.section--cart .cart__cheque .cheque__title {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 15px;
}
.section--cart .cart__cheque .cheque__info {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 5px;
}
.section--cart .cart__cheque .cheque__info span {
  font-weight: 500;
  color: #EC692C;
}
.section--cart .cart__cheque .cheque__details {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.section--cart .cart__cheque .cheque__details .detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
}
.section--cart .cart__cheque .cheque__details .detail:not(:last-child) {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(43, 42, 41, 0.1);
}
.section--cart .cart__cheque .cheque__details .detail--result .detail__key {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.section--cart .cart__cheque .cheque__details .detail--result .detail__value {
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  color: #2B2A29;
}
.section--cart .cart__cheque .cheque__details .detail__key {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
.section--cart .cart__cheque .cheque__details .detail__value {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #EC692C;
}
.section--cart .cart__cheque .button {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.section--cart .cart__cheque .cheque__agreement {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
}
.section--cart .cart__cheque .cheque__agreement a {
  text-decoration: underline;
}

.section--order {
  padding-top: 0;
}
.section--order .order {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 390px;
}
@media screen and (max-width: 992px) {
  .section--order .order {
    grid-template-columns: 1fr;
  }
}
.section--order .order__inner {
  display: flex;
  flex-direction: column;
}
.section--order .order__inner .block {
  display: flex;
  flex-direction: column;
}
.section--order .order__inner .block:not(:last-of-type) {
  margin-bottom: 50px;
}
.section--order .order__inner .block .block__title {
  font-size: 35px;
  font-weight: 500;
  line-height: 42px;
  margin-bottom: 25px;
}
@media screen and (max-width: 992px) {
  .section--order .order__inner .block .block__title {
    font-size: 30px;
    line-height: 35px;
  }
}
@media screen and (max-width: 768px) {
  .section--order .order__inner .block .block__title {
    font-size: 24px;
    line-height: 28px;
  }
}
@media screen and (max-width: 576px) {
  .section--order .order__inner .block .block__title {
    font-size: 20px;
    line-height: 24px;
  }
}
.section--order .order__inner .block .block__inner {
  display: flex;
  flex-direction: column;
}
.section--order .order__inner .block--delivery .deliveries {
  display: grid;
  grid-gap: 20px;
  grid-auto-rows: 100px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 25px;
}
@media screen and (max-width: 1200px) {
  .section--order .order__inner .block--delivery .deliveries {
    grid-auto-rows: auto;
  }
}
@media screen and (max-width: 992px) {
  .section--order .order__inner .block--delivery .deliveries {
    grid-template-columns: 1fr;
  }
}
.section--order .order__inner .block--delivery .deliveries .delivery {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 25px 30px;
  border-width: 1px;
  border-style: solid;
  border-color: #E3E3E3;
  color: #2B2A29;
}
@media screen and (max-width: 576px) {
  .section--order .order__inner .block--delivery .deliveries .delivery {
    padding: 25px 20px;
  }
}
.section--order .order__inner .block--delivery .deliveries .delivery.active {
  border-color: #EC692C;
}
.section--order .order__inner .block--delivery .deliveries .delivery__check {
  margin-right: 15px;
}
.section--order .order__inner .block--delivery .deliveries .delivery__info {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.section--order .order__inner .block--delivery .deliveries .delivery__info > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .section--order .order__inner .block--delivery .deliveries .delivery__info > div:nth-child(1) {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 992px) {
  .section--order .order__inner .block--delivery .deliveries .delivery__info > div:nth-child(1) {
    flex-direction: row;
    align-items: center;
  }
}
.section--order .order__inner .block--delivery .deliveries .delivery__info > div:not(:last-child) {
  margin-bottom: 8px;
}
.section--order .order__inner .block--delivery .deliveries .delivery__info .delivery__name {
  line-height: 1;
  font-size: 18px;
  font-weight: 600;
}
@media screen and (max-width: 1200px) {
  .section--order .order__inner .block--delivery .deliveries .delivery__info .delivery__name {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 992px) {
  .section--order .order__inner .block--delivery .deliveries .delivery__info .delivery__name {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 768px) {
  .section--order .order__inner .block--delivery .deliveries .delivery__info .delivery__name {
    font-size: 16px;
  }
}
@media screen and (max-width: 576px) {
  .section--order .order__inner .block--delivery .deliveries .delivery__info .delivery__name {
    font-size: 14px;
  }
}
.section--order .order__inner .block--delivery .deliveries .delivery__info .delivery__price {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #EC692C;
}
.section--order .order__inner .block--delivery .deliveries .delivery__info .delivery__date {
  opacity: 0.5;
  line-height: 1;
  font-size: 12px;
  font-weight: 500;
}
.section--order .order__inner .block--delivery .location {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 250px;
}
@media screen and (max-width: 992px) {
  .section--order .order__inner .block--delivery .location {
    height: auto;
  }
}
.section--order .order__inner .block--delivery .location__info {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  margin-right: 40px;
  min-width: max-content;
  background-color: #FFFFFF;
}
.section--order .order__inner .block--delivery .location__info .column {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  max-width: max-content;
  color: #000000;
}
.section--order .order__inner .block--delivery .location__info .column:not(:last-child) {
  margin-bottom: 40px;
}
.section--order .order__inner .block--delivery .location__info .column__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 15px;
  color: #EC692C;
}
.section--order .order__inner .block--delivery .location__info .column .bold {
  font-weight: 500;
}
.section--order .order__inner .block--delivery .location #map {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 992px) {
  .section--order .order__inner .block--delivery .location #map {
    display: none;
  }
}
.section--order .order__inner .block--receive .receives {
  display: grid;
  grid-gap: 40px;
  grid-auto-rows: 60px;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 768px) {
  .section--order .order__inner .block--receive .receives {
    grid-gap: 20px;
    grid-template-columns: 1fr;
  }
}
.section--order .order__inner .block--receive .receives .receive {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 30px;
  border-width: 1px;
  border-style: solid;
  border-color: #E3E3E3;
  color: #2B2A29;
}
.section--order .order__inner .block--receive .receives .receive.active {
  border-color: #EC692C;
}
.section--order .order__inner .block--receive .receives .receive__check {
  margin-right: 15px;
}
.section--order .order__inner .block--receive .receives .receive__name {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}
@media screen and (max-width: 1200px) {
  .section--order .order__inner .block--receive .receives .receive__name {
    font-size: 14px;
    line-height: 18px;
  }
}
.section--order .order__inner .block--details .details {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
}
@media screen and (max-width: 576px) {
  .section--order .order__inner .block--details .details {
    grid-gap: 25px;
  }
}
.section--order .order__inner .block--details .details .detail {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100px 1fr;
}
@media screen and (max-width: 576px) {
  .section--order .order__inner .block--details .details .detail {
    grid-gap: 10px;
    grid-template-columns: 1fr;
  }
}
.section--order .order__inner .block--details .details .detail__key {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
.section--order .order__inner .block--details .details .detail__value {
  display: flex;
  flex-direction: column;
}
.section--order .order__inner .block--details .details .detail__value .detail__hint {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-top: 15px;
  margin-left: 30px;
}
@media screen and (max-width: 576px) {
  .section--order .order__inner .block--details .details .detail__value .detail__hint {
    font-size: 12px;
    line-height: 16px;
    margin-left: 0;
  }
}
.section--order .order__inner .block--details .details .detail__value .detail__hint span {
  color: #EC692C;
}
.section--order .order__inner .block--details .details .detail__value .input {
  width: 100%;
  height: 60px;
  padding: 10px 30px;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(43, 42, 41, 0.2);
}
.section--order .order__inner .block--details .details .detail__value .input input {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  opacity: 1;
  height: 100%;
}
.section--order .order__cheque {
  display: flex;
  flex-direction: column;
  height: max-content;
  padding: 15px 20px 35px 20px;
  background-color: #FAFAFA;
  color: #2B2A29;
}
.section--order .order__cheque .cheque__list {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.section--order .order__cheque .cheque__list .item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 25px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #E5E5E5;
}
.section--order .order__cheque .cheque__list .item__remove {
  position: absolute;
  right: 0;
  top: 20px;
  cursor: pointer;
  color: #DE0303;
}
.section--order .order__cheque .cheque__list .item__remove .icon {
  width: 10px;
  height: 10px;
}
.section--order .order__cheque .cheque__list .item__image {
  width: 75px;
  height: 75px;
  margin-right: 25px;
}
@media screen and (max-width: 576px) {
  .section--order .order__cheque .cheque__list .item__image {
    display: none;
  }
}
.section--order .order__cheque .cheque__list .item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.section--order .order__cheque .cheque__list .item__info {
  display: flex;
  flex-direction: column;
  max-width: 215px;
}
.section--order .order__cheque .cheque__list .item__info .item__name {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 15px;
}
.section--order .order__cheque .cheque__list .item__info > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.section--order .order__cheque .cheque__list .item__info > div .item__price {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.section--order .order__cheque .cheque__list .item__info > div .item__count {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #EC692C;
}
.section--order .order__cheque .cheque__details {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.section--order .order__cheque .cheque__details .detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.section--order .order__cheque .cheque__details .detail:not(:last-child) {
  margin-bottom: 20px;
}
.section--order .order__cheque .cheque__details .detail__key {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.section--order .order__cheque .cheque__details .detail__value {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #EC692C;
}
.section--order .order__cheque .button {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.section--order .order__cheque .cheque__agreement {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
}
.section--order .order__cheque .cheque__agreement a {
  text-decoration: underline;
}

.modals {
  display: none;
}
.modals.show {
  display: block;
}
.modals.active .modals__inner {
  opacity: 1;
}
.modals__inner {
  position: fixed;
  z-index: 9988;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  padding: 0 15px;
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease-in;
  background-color: rgba(0, 0, 0, 0.6);
}
.modals .modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9990;
  transform: translate(-50%, -50%);
  opacity: 0;
  padding: 50px;
  border-radius: 4px;
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease-in;
  color: #2B2A29;
  background-color: #FFFFFF;
  box-shadow: 0px 25px 45px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 768px) {
  .modals .modal {
    padding: 40px;
  }
}
@media screen and (max-width: 576px) {
  .modals .modal {
    padding: 30px;
  }
}
.modals .modal.active {
  opacity: 1;
  z-index: 9999;
}
.modals .modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.5;
  cursor: pointer;
  color: #2B2A29;
  transition-duration: 0.2s;
  transition-property: opacity;
}
.modals .modal__close .icon {
  width: 12px;
  height: 12px;
}
.modals .modal__close:hover {
  opacity: 1;
}
.modals .modal__title {
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .modals .modal__title {
    font-size: 25px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 576px) {
  .modals .modal__title {
    margin-bottom: 20px;
  }
}

.modal--call form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
}
.modal--call form .input {
  width: 100%;
}
.modal--call form .input:not(:last-of-type) {
  margin-bottom: 15px;
}
.modal--call form .button {
  width: 100%;
  max-width: 100%;
  margin-top: 25px;
  margin-bottom: 20px;
}
.modal--call form .agreement {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  opacity: 0.75;
}
.modal--call form .agreement .checkbox {
  margin-right: 10px;
}
.modal--call form .agreement label {
  cursor: pointer;
  max-width: 195px;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #2B2A29;
}
.modal--call form .agreement label a {
  text-decoration: underline;
}

.modal--review form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
}
.modal--review form .rating {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px;
}
.modal--review form .rating span {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-right: 15px;
}
.modal--review form .rating__gradations {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.modal--review form .rating__gradations .star {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}
.modal--review form .rating__gradations .star:not(:last-child) {
  margin-right: 8px;
}
.modal--review form .rating__gradations .star.active {
  opacity: 1;
  color: #EC692C;
}
.modal--review form .rating__gradations .star .icon {
  width: 12px;
  height: 12px;
}
.modal--review form .input {
  width: 100%;
}
.modal--review form .input:not(:last-of-type) {
  margin-bottom: 15px;
}
.modal--review form .button {
  width: 100%;
  max-width: 100%;
  margin-top: 25px;
  margin-bottom: 20px;
}

.modal--signin {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 470px;
}
@media screen and (max-width: 576px) {
  .modal--signin {
    max-width: 300px;
  }
}
.modal--signin form {
  width: 100%;
}
.modal--signin .input {
  width: 100%;
}
.modal--signin .input:not(:last-of-type) {
  margin-bottom: 15px;
}
.modal--signin .button {
  width: 100%;
  max-width: 100%;
  margin-top: 25px;
  margin-bottom: 20px;
}
@media screen and (max-width: 576px) {
  .modal--signin .button {
    font-size: 8px;
  }
}
.modal--signin .hint {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}
@media screen and (max-width: 576px) {
  .modal--signin .hint {
    flex-direction: column;
    align-items: flex-start;
  }
}
.modal--signin .hint a {
  cursor: pointer;
}
@media screen and (max-width: 576px) {
  .modal--signin .hint > span {
    margin-bottom: 10px;
  }
}
.modal--signin .hint > span a {
  font-weight: 500;
  color: #EC692C;
}

.modal--signup {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 470px;
}
@media screen and (max-width: 576px) {
  .modal--signup {
    max-width: 300px;
  }
}
.modal--signup form {
  width: 100%;
}
.modal--signup .input {
  width: 100%;
}
.modal--signup .input:not(:last-of-type) {
  margin-bottom: 15px;
}
.modal--signup .button {
  width: 100%;
  max-width: 100%;
  margin-top: 25px;
  margin-bottom: 20px;
}
@media screen and (max-width: 576px) {
  .modal--signup .button {
    font-size: 8px;
  }
}
.modal--signup .hint {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}
.modal--signup .hint a {
  cursor: pointer;
  font-weight: 500;
  color: #EC692C;
}